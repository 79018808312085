@charset "UTF-8";

@media all {

	section.event.create, section.event.edit {
		text-align: left;
		// padding-top: 20px;
		position: relative;
	  ul.token-input-list {
			margin-top: 10px;
			border-radius: 0.3rem;
			font-size: 1.25rem;
			line-height: 1.5;
		}
	  form h1.box-header {
			font-family: "HelveticaNeue", "Helvetica", Arial, sans-serif;
			font-size: 18px;
			height: 54px;
			line-height: 54px;
		}
	  div.image-box {
			overflow: hidden;
			min-width: 230px;

			div.image {
				overflow: hidden;
				background: #d2d2d2 url(#{$domain}/img/web/evedo.jpg) no-repeat center center;
				height: 170px;

				img {
				  position: relative;
					top: 50%;
					transform: translateY(-50%);
				}
			}
	  	div.hover {
				background: #e62e83;
			}
		  a.delete-image {
				background: rgba(0, 0, 0, 0.2) url(#{$domain}/img/web/icon-remove-white.gif) no-repeat 8px 8px;
				display: none;
				height: 24px;
				position: absolute;
				right: 0px;
				top: 0px;
				width: 24px;
				z-index: 1;
			}
		  a.delete-image:hover {
				background-color: rgba(0, 0, 0, 0.5);
			}
		  div.image-box:hover a.delete-image {
				display: block;
			}
		  p {
				color: #888;
				line-height: 20px;
				padding: 15px 0px 5px 0px;
				text-align: center;
			}
		  p strong {
				color: #444;
			}
	    label {
        cursor: pointer;
      }

      .custom-browse {
        background: #10345d;
        color: #d9f1f8;
        font-size: 11px;
        line-height: 14px;
        padding: 6px;
        position: relative;
        text-align: center;
        width: 100%;

        strong {
            color: white;
            font-size: 16px;
        }

        input {
            cursor: pointer;
            font-size: 180px;
            height: 220px;
            left: 0px;
            opacity: 0;
            position: absolute;
            top: -185px;
            width: 100%;
        }
      }
		}

	  form div.message {
			background: #e62e83 url(#{$domain}/img/web/icon-warning-white.png) no-repeat left center;
			color: white;
			line-height: 18px;
			min-height: 23px;
			padding: 16px 20px 10px 60px;
		}
	  form div.message span {
			display: block;
		}
	  form div.message span:last-child {
			margin-bottom: 5px;
		}
	  form div.hosts {
			padding-bottom: 10px;
		}
	  form div.hosts p {
			color: #888;
			float: left;
			font-size: 11px;
			line-height: 15px;
			padding: 10px 0px 0px 0px;
		}
	  form div.hosts p.wide {
		}
	  form div.hosts p strong {
			color: #444;
			font-size: 12px;
		}
	  form div.hosts select {
		}
	  form div.hosts input.hosts-autocomplete {
		}
	  form div.hosts a.btn {

		}
	  form textarea {
			padding: 11px;
			border-radius: 0.3rem;
			font-size: 1.25rem;
			line-height: 1.5;
		}
	  form div.description {
			label {
				display: block;
				width: 100%;
			}
		}

	/* -------------------------- */
	  div.hashtag {
			background: #d2d2d2;
			color: white;
			font-size: 18px;
			font-weight: bold;
			height: 30px;
			line-height: 30px;
			margin-top: 10px;
			text-align: center;
			width: 30px;
		}
	  input.hashtag {
			border-left: 0px;
		}

	/* -------------------------- */
	  div.what label span.notice {
			display: none;
		}
	  div.what label.checked span.notice {
			color: #888;
			display: block;
			font-size: 11px;
			padding: 4px 0px 0px 18px;
		}
	  div.when fieldset {

		}
	  div.when fieldset input {
			float: left;
		}
	  div.when fieldset input:last-child {
			// border-left: none;
			border-top: 1px solid #d2d2d2 !important;
		}
	 //  form h1.where {
		// 	padding: 1px 0px 0px 20px;
		// }
	  div.where {
			padding: 0px;
			position: relative;
		}
	  div.where #map_canvas {
			height: 250px;
			// width: 730px;
		}
	  div.where #map_canvas a.delete-marker {
			color: white;
			text-decoration: none;
		}
	  div.where div.form {
			bottom: 20px;
			height: 30px;
			left: 0px;
			position: absolute;
			z-index: 2;
		}
	  div.where div.form input {
			margin: -100px 10px 0px 20px;
			// width: 650px;
		}
	  // form h1.how-much ,  form h1.no-border {
		// 	padding: 0px 0px 1px 20px;
		// }
	  form div.how-much div.types {
			label {
				display: block;
				width: 100%;
			}
		}
	  form div.how-much div.types input ~ span.label {
			color: #888;
			width: 100%;
		}
	  form div.how-much div.types input:checked ~ span.label {
			color: #444;
			font-weight: bold;
		}
	  form div.how-much div.types input.hidden {
			display: none;
		}
	  div.connect {
			padding: 14px 0px 13px 20px;
		}
	  div.connect p {
			margin: 0px;
		}
	  div.export label strong {
			color: #3b5998;
		}

		/* actions */
	  form div.actions .btn {
			margin: 5px 10px;
		  padding: 12px 22px;
		}
	  form div.actions .btn:first-child {
			// margin-left: 0px;
		}
	}
}
