$primary-pink: #F347BA;
$secondary-pink: #F879C9;
$light-pink: #FEE7F4;
$primary-violet: #863EE2;
$secondary-violet: #611ECE;
$dark-violet: #310789;
$light-violet: #F5E1FE;
$dark: #282835;
$link-pink: #ED3B91;
$text-grey: #595968;
$light-mode-text: #23232E;
$light-grey: #ABAAB6;
$dark-grey: #49494F;
$dark-blue: #151A40;
$grey: #F1EEF5;

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    src: url("#{$domain}/fonts/poppins/Poppins-Regular.woff2") format('woff2');
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    src: url("#{$domain}/fonts/poppins/Poppins-Medium.woff2") format('woff2');
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    src: url("#{$domain}/fonts/poppins/Poppins-SemiBold.woff2") format('woff2');
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    src: url("#{$domain}/fonts/poppins/Poppins-Bold.woff2") format('woff2');
}  
  
@font-face {
    font-family: 'Bilo';
    font-style: normal;
    font-weight: 500;
    src: url("#{$domain}/fonts/bilo/Bilo-Medium.woff") format('woff');
}
  
  
@font-face {
    font-family: 'Bilo';
    font-style: normal;
    font-weight: 700;
    src: url("#{$domain}/fonts/bilo/Bilo-Bold.woff") format('woff');
}

@font-face {
    font-family: 'Bilo';
    font-style: normal;
    font-weight: 800;
    src: url("#{$domain}/fonts/bilo/Bilo-ExtraBold.woff") format('woff');
}
 
body{
    background-color: #fff;
    font-family: 'Poppins','HelveticaNeue', 'Helvetica', Arial, sans-serif;
}
a{
    text-decoration: none;
}

.h1,.h2,.h3,.h4,.h5,.h6,.text-bilo{
    font-family: 'Bilo', 'Poppins', Helvetica, Arial, sans-serif;
}
.text-pink{
    color: $primary-pink;
    &:hover{
        color: $secondary-pink;
    }
}
.container{
    max-width: 1220px;
}
.text-white{
    color: #fff!important;
}
.text-grey{
    color: $text-grey;
}
.text-light-grey{
    color: $light-grey;
}
.text-dark-grey{
    color: $dark-grey;
}
.text-dark-blue{
    color: $dark-blue;
}
a.text-grey{
    &:hover{
        color: $dark;
    }
}
.bg-pink{
    background-color: $primary-pink;
    &:hover{
        background-color: $secondary-pink;
    }
}
.bg-pink-light{
    background-color: $light-pink;
}
.bg-violet-light{
    background-color: $light-violet;
}
.text-violet{
    color: $secondary-violet;
    &:hover{
        color: $primary-violet;
    }
}
.text-mode-dark{
    color: $light-mode-text!important;
}
.bg-violet{
    background-color: $secondary-violet;
    &:hover{
        background-color: $primary-violet;
    }
}
.bg-pink-pale{
    background-color: #F9EDFE;
}
.bg-dark-violet{
    background-color: $dark-violet;;
}
.h1{
    font-size: 6.6875rem;
    line-height: 75px;
}
.h2{
    font-size: 3.5rem;
    line-height: 56px;
}
.h3{
    font-size: 2.625rem;
    line-height: 44px;
}
.h4{
    font-size: 2rem;
    line-height: 36px;
}
.h5{
    font-size: 1.5rem;
    line-height: 30px;
}
.h6{
    font-size: 1.125rem;
    line-height: 24px;
}

.text-xl{
    font-family: 'Poppins','HelveticaNeue', 'Helvetica', Arial, sans-serif;
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 38px;
}
.text-l{
    font-family: 'Poppins','HelveticaNeue', 'Helvetica', Arial, sans-serif;
    font-weight: 400;
    font-size: 1.125rem;
    line-height: 28px;
}
.text-m{
    font-family: 'Poppins','HelveticaNeue', 'Helvetica', Arial, sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 26px;
}
.text-s{
    font-family: 'Poppins','HelveticaNeue', 'Helvetica', Arial, sans-serif;
    font-weight: 400;
    font-size: .875rem;
    line-height: 22px;
    letter-spacing: .01rem;
}
.text-xs{
    font-family: 'Poppins','HelveticaNeue', 'Helvetica', Arial, sans-serif;
    font-weight: 400;
    font-size: .75rem;
    line-height: 20px;
    letter-spacing: .01rem; 
}

.label-l{
    font-family: 'Poppins','HelveticaNeue', 'Helvetica', Arial, sans-serif;
    font-weight: 600;
    font-size: 1rem;
    line-height: 24px;
}
.label-m{
    font-family: 'Poppins','HelveticaNeue', 'Helvetica', Arial, sans-serif;
    font-weight: 600;
    font-size: .8125rem;
    line-height: 24px;
}
.label-s{
    font-family: 'Poppins','HelveticaNeue', 'Helvetica', Arial, sans-serif;
    font-weight: 600;
    font-size: .6875rem;
    line-height: 24px;
}

.link-bold{
    font-family: 'Poppins','HelveticaNeue', 'Helvetica', Arial, sans-serif;
    font-weight: 700;
    font-size: 1.125rem;
    line-height: 16px;
    color: $dark;
    position: relative;
    &::after{
        content: "";
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        height: 1px;
        background: $link-pink;
    }
    &:hover{
        color: $link-pink;
        ::after{
            width: 0;
        }
    }
}
.nav-link-normal{
    font-family: 'Poppins','HelveticaNeue', 'Helvetica', Arial, sans-serif;
    font-weight: 500;
    font-size: 1rem;
    line-height: 32px;
}
.nav-link-hover{
    &:hover{
        @extend .current;
    }
}
.dropdown-angle-pink{
    white-space: nowrap;
    position: relative;
    &:hover{
        @extend .current;
    }
    &::after{
        content: "";
        margin-left: 4px;
        width: 0; 
        height: 0; 
        border-left: 3px solid transparent;
        border-right: 3px solid transparent; 
        border-top: 4px solid $primary-pink; 
    }
    &-mobile{
        &::after{
            content: "";
            margin-left: 4px;
            width: 0; 
            height: 0; 
            border-top: 6px solid transparent;
            border-bottom: 6px solid transparent; 
            border-left: 9px solid $primary-pink; 
        }
    }
}
.show{
    .dropdown-angle-pink{
        @extend .current;
        &::after{
            border-bottom:5px solid $primary-pink; 
            border-top: unset;
        }
    }
}
.link-normal{
    font-family: 'Poppins','HelveticaNeue', 'Helvetica', Arial, sans-serif;
    font-weight: 600;
    font-size: 1rem;
    line-height: 16px;
    color: $dark;
    position: relative;
    &::after{
        content: "";
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        height: 1px;
        background: $dark;
    }
    &:hover{
        color: $link-pink;
        &::after{
            width: 0;
        }
    }
}
.link-small{
    font-family: 'Poppins','HelveticaNeue', 'Helvetica', Arial, sans-serif;
    font-weight: 500;
    font-size: .875rem;
    line-height: 16px;
    color: $dark;
    position: relative;
    &::after{
        content: "";
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        height: 1px;
        background: $link-pink;
    }
    &:hover{
        color: $link-pink;
        ::after{
            width: 0;
        }
    }
}
.link-xs{
    font-family: 'Poppins','HelveticaNeue', 'Helvetica', Arial, sans-serif;
    font-weight: 400;
    font-size: .75rem;
    line-height: 20px;
    color: $dark;
    position: relative;
    letter-spacing: 1px;
    &::after{
        content: "";
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        height: 1px;
        background: $dark;
    }
    &:hover{
        color: $link-pink;
        ::after{
            width: 0;
        }
    }
}
.link-pink{
    color: $link-pink;
}
.link-transition{
    transition: .3s ease;
    &::after{
        transition: .3s ease;
    }
    &:hover{
        transition: .3s ease;
        &::after{
            transition: .3s ease;
        }
    }
}
.transition-500{
    transition: .5s ease;
    &::after{
        transition: .5s ease;
    }
    &:hover{
        transition: .5s ease;
        &::after{
            transition: .5s ease;
        }
    }
}
.transition-200{
    transition: .2s ease;
    &::after{
        transition: .2s ease;
    }
    &:hover{
        transition: .2s ease;
        &::after{
            transition: .2s ease;
        }
    }
}
.fw-400{
    font-weight: 400;
}
.fw-500{
    font-weight: 500;
}
.fw-600{
    font-weight: 600;
}
.fw-700{
    font-weight: 700;
}
.button-main{
    color: #fff;
    border-radius: 80px;
    padding: 10px 50px;
    font-weight: 500;
    font-size: 1rem;
    border: 0;
    letter-spacing: .01rem;
    cursor: pointer;
}
.overlay-image, .overlay-count{
    width: 30px;
    height: 30px;
    transition: .3s ease-in;
}
.button-ghost{
    background: #FFFFFF;
    border: 1px solid #D6D3DF;
    box-sizing: border-box;
    box-shadow: 0px 4px 8px rgba(195, 116, 247, 0.2);
    border-radius: 32px;
    z-index: 1;
    font-weight: 600;
    font-size: .9375rem;
    line-height: 32px;
    text-align: center;
    padding: 10px 30px;
    &-square{
        border-radius: 4px;
        border: 2px solid #5232AE;
        background: rgba(255, 255, 255, 0.6);
        &:hover{
            background: #fff;
        }
    }
    &:hover{
        border-color: #6E6D76;
    }
}
.widget-dropdown{
    border: 0;
    box-shadow: 0px 2px 16px rgba(0,0,0,.12);
    border-radius: 16px;
    padding: 20px;
}
a.current{
    background: $grey;
    border-radius: 32px;    
    color: $dark;
}
.location-input{
    width: auto;
    font-family: 'Poppins','HelveticaNeue', 'Helvetica', Arial, sans-serif;
    background-color: rgba(241, 238, 245, 0.5);
    padding-top: 12px;
    padding-bottom: 12px;
}
.location-wrap{
    border: 0;
    box-sizing: border-box;
    border-radius: 32px;
}
.location-icon{
    left: 15px;
    z-index: 1;
    margin: 0;
}
.search-input{
    min-width: 280px;
    font-size: .8125rem;
    @extend .location-input;
    &::placeholder{
        font-size: .875rem!important;
        color: #8F8E99!important;
    }
}
.search-wrap{  
    background-color: rgba(241, 238, 245, .6);
    &:hover{
        background-color: rgba(241, 238, 245, 1);
    }
    border: 0;
    box-sizing: border-box;
    border-radius: 32px;
    color: #8F8E99;
}
.search-icon{
    @extend .location-icon;
}
.nav-button-primary{
    background: $primary-pink;
    border-radius: 55px;
    padding-top: 9px;
    padding-bottom: 9px;
    white-space: nowrap;
    &:hover{
        background-color: $secondary-pink;
        color: #fff;
    }
}
.nav-button-ghost{
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 32px;
    padding-top: 11px;
    padding-bottom: 11px;
    white-space: nowrap;
    &:hover{
        border-color: $light-mode-text;
    }
}
.opacity-25{
    opacity: .25;
}
.logged-menu{
    border-radius: 32px;
    min-width: 100px;
}
.custom-drop.dropleft .dropdown-menu{
    right: 0;
    top: 100%;
}
.dropdown-fw{
    height: 0;
    position: absolute;
    z-index: 9;
    &.h-200{
        padding: 32px 0;
        border-top: 1px solid $light-grey;
        border-bottom: 1px solid $light-grey;
    }
}
.h-200{
    height: 200px;
}
.dropdown-transition{
    transition: .3s ease;
}
// .absolute-nav{
//     position: absolute;
//     top: 0;
//     left: 0;
//     right: 0;
//     z-index: 10;
// }
.suggestions{
    top: calc( 100% + 20px);
    left: 0;
    right: 0;
    width: calc(100% - 10px);
    z-index: 9;
    padding: 25px;
    max-height: 80vh;
    overflow-y: scroll;
    .category-label{
        font-size: .8125rem;
        font-weight: 600;
    }
}
#clearSearch{
    cursor: pointer;
}
.advanced-search-wrap{
    right: 0;
    background-color: #fff;
    overflow: hidden;
    padding: 0 24px;
}
.active-search{
    box-shadow: 0px 16px 44px rgba(0, 0, 0, 0.08);
    max-width: 720px;
}
.category-label{
    white-space: nowrap;
    font-weight: 700;
    font-size: 1.125rem;
    color: #8F8E99;
}
.suggestion-card-place{
    color: #70757D;
    font-weight: 400;
}
.suggestion-title{
    @extend .category-label;
    color: $dark;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.suggestion-active{
    .suggestion-card{
        background-color: #F2F4F7;
    }
}
.suggestion-card{
    &:hover{
        background-color: #F2F4F7;
    }
}
.suggestion-card.card{
    border-radius: 0;
}
.suggestion-card-bg{
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 4px;
    width: 100px;
    height: 75px;
}
.suggestion-link{
    min-height: 75px;
}
.nav-wrap{
    max-width: 490px;
}
.desktop-nav{
    display: none;
}
.category-dropdown{
    right: -300px;
    padding: 22px 29px 36px;
    .dropdown-item{
        border-radius: 55px;
        padding: .5rem 1rem;
    }
    .label-m{
        color: #8F8E99;
        white-space: nowrap;
        line-height: 32px;
        border-bottom: 1px solid $grey;
    }
}
.dropdown-image{
    min-width: 200px;
    min-height: 200px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 16px;
}
.organizers-dropdown{
    @extend .category-dropdown;
    min-width: 396px;
    padding: 0;
    right: 0;
    padding-bottom: 22px;
    .dropdown-item{
        border-radius: 55px;
        padding: .5rem 1rem;
        width: auto;
    }
    .category-label{
        font-family: 'Bilo', 'Poppins', Helvetica, Arial, sans-serif;
        white-space: normal;
        color: #23232e;
    }
}
.dropdown-link{
    @extend .link-normal;
    font-size: .8125rem;
    line-height: 1;
    &::after{
        background: $dark;
    }
    .icon{
        top: 0;
        left: calc(100% + 5px);
    }
}
.dropdown-footer{
    border-top: 1px solid #F6F5FA;
}
.more-categories-dropdown,.organizers-container{
    max-height: 80vh;
    overflow-y: scroll;
}
.circle-gray{
    width: 32px;
    height: 32px;
    background-color: #F6F5FA;
    border-radius: 50%;
    &-sm{
        width: 22px;
        height: 22px;
        font-size: .75rem;
    }
}
.button-disabled{
    background-color: $grey;
    color: $light-grey;
    &:hover{
        background-color: #cccccc1f;
    }
}
.grey-separator{
    border-top: 1px solid $grey;
}
.rounded-4{
    border-radius: .5rem;
}
.rounded-5{
    border-radius: 1rem;
}




@media all and (min-width: 768px){
    .desktop-nav{
        display: flex;
    }
    .mobile-navigation{
        display: none;
    }
}
.menu-fw{
    height: 0;
}
.dropdown-transition{
    transition: .5s ease-in;
}
.header-section{
    background: linear-gradient(93.6deg, #FFDBDD 0%, #FFC5ED 48.44%, #E9E4FF 100%);
}
.header-overlay-color{
    top: 0;
    left: 0;
    bottom: 0;
}
.header-overlay-image{
    top: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom;
}
.header-title{
    font-family: 'Bilo', 'Poppins', Helvetica, Arial, sans-serif;
    font-weight: 700;
    font-size: 4.25rem;
    line-height: 68px;
}
.header-category{
    color: $dark-grey;
    font-weight: 600;
    font-size: .8125rem;
}
.header-text{
    @extend .text-l;
    color: $light-mode-text;
    font-size: 1.25rem;
    line-height: 32px;
    font-weight: 400;
    letter-spacing: .01em;
}
@media all and (max-width: 600px){
    .header-title{
        font-size: 2.5rem;
        line-height: 1.25;
    }
    .search-input{
        min-width: 200px;
        width: 100%;
    }
}
.title-bilo-m{
    font-family: 'Bilo', 'Poppins', Helvetica, Arial, sans-serif;
    font-weight: 700;
    font-size: 2rem;
    line-height: 36px;
    color: #23232E;
}
.card-type{
    color: #9DA0A7;
    line-height: 14px;
    font-weight: 600;
}
.card-text{
    font-family: 'Bilo', 'Poppins', Helvetica, Arial, sans-serif;
    line-height: 24px;
}
.overlay{
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    padding: 10px;
    background: rgba(0, 0, 0, 0.4);
    opacity: 0;
    transition: .3s ease-in;
}
.card{
    border-radius: 1rem;
}
.card-body{
    z-index: 1;
}
.overlay-image, .overlay-count{
    width: 30px;
    height: 30px;
    transition: .3s ease-in;
}
.overlay-count{
    background-color: #fff;
    min-width: 30px;
    width: auto;
}
.card-date{
    background-color: #23232E;
    z-index: 2;
    transition: .3s ease-in;    
    font-family: 'Poppins','HelveticaNeue', 'Helvetica', Arial, sans-serif;
    font-size: 13px;
    font-weight: 600;
    line-height: 1.25;
}
.card-bg{
    height: 220px;
    background-size: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    border-radius: 4px;
    transition: .3s ease-in;
    &.card-horizontal{
        border-radius: 0 0 4px 4px;
    }
}
.button-ghost{
    background: #FFFFFF;
    border: 1px solid #D6D3DF;
    box-sizing: border-box;
    box-shadow: 0px 4px 8px rgba(195, 116, 247, 0.2);
    border-radius: 32px;
    z-index: 1;font-weight: 600;
    font-size: 15px;
    line-height: 32px;
    text-align: center;
    padding: 10px 30px;
    white-space: nowrap;
    &:hover{
        border-color: #6E6D76;
    }
}
.skeleton{
    position: relative;
    &::after{
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: -2px;
        bottom: -2px;
        background: $grey;
    }
}
.location-wrap{
    border: 1px solid #D6D3DF;
    box-sizing: border-box;
    border-radius: 32px;
}
.location-icon{
    left: 15px;
    z-index: 1;
    margin: 0;
    color: #d6d3df;
}
.nearby-card{
    transition: .3s ease-in;
}
.nearby-card:hover{
    .card-date{
        background-color: #F347BA;
        transition: .3s ease-in;
    }
    .overlay{
        opacity: 1;
        transition: .3s ease-in;
    }
    .card-bg{
        background-size: 105%;
        transition: .3s ease-in;
    }
}
.promoters-title{
    font-family: 'Bilo', 'Poppins', Helvetica, Arial, sans-serif;
    color: #23232E;
    font-size: 2rem;
    line-height: 30px;
    font-weight: 700;
}
.promoter-title{
    font-size: 1rem;
    font-weight: 700;
    font-family: 'Bilo', 'Poppins', Helvetica, Arial, sans-serif;
}
.notable-promoters{
    border-radius: 32px 0 0 32px;
    .slick-list{
        padding: 0 10vw 0 0!important;
    }
    .slick-track{
        padding: 32px 0;
    }
    .slick-slide{
        padding-right: 0;
        padding-left: 21px;
    }
    .slick-slider{
        margin-left: -21px;
    }
}
.promoter-card{
    padding-top: 100%;
    border-radius: 22px;
    &:hover {
        transform: scale(1.1);
        .promoter-content{
            bottom: -5%;
            right: -5%;
            left: -5%;
            top: -5%;
        }
        .promoter-title{
            height: 100%;
        }
    }
}
.promoter-content{
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.promoter-title{
    background: linear-gradient(0deg, #000 0%, rgba(26, 32, 88, 0) 100%);
    padding: 0 20px 20px;
    z-index: 1;
    bottom: 0;
    height: 50%;
    display: flex;
    align-items: flex-end;
    font-size: 1vw;
}
.online-event{
    color: #6E6D76;
}
.custom-arrow{
    cursor: pointer;
}
@media all and (max-width: 700px){
    .promoter-title{
        font-size: .875rem;
    }
}

.trusted-logo{
    min-width: 0;
    margin: 0 16px;
    width: 100px;
}
.trusted{
    .text-s{
        font-family: 'Bilo', 'Poppins', Helvetica, Arial, sans-serif;
        white-space: nowrap;
        font-weight: 700;
    }
}
.cta-section{
    background: #FCFBFD;
    border-radius: 16px;
    .link-normal{
        &::after{
            display: none;
        }
    }
}
.cta-text{
    color: #8F8E99;
}
.cta-content{
    padding: 30px 15px;
}
.cta-tooltip{
    background-color: rgba(21,26,64, .66);
    padding: 24px 18px 24px 41px;
    width: 60%;
    transition: .3s ease;
    color: #fff;
}
.cta-bg{
    background: linear-gradient(3.6deg, #FFEDEE 0%, #FFD7F3 48.44%, #F2EFFF 100%);
}
.cta-overlay-image{
    @extend .header-overlay-image;
    background-position: top;
}
.about-title{
    font-size: 2rem;
}
.opacity-0{
    opacity: 0;
}
@media all and (min-width: 1200px){
    .cta-image{
        width: 55%;
    }
}
@media all and (min-width: 992px){
    .cta-image{
        width: 50%;

    }
}
@media all and (min-width: 768px){
    .latest-slider{
        top: 0;
        left: 0;
        max-width: calc((100vw - 1220px)/2 + 915px);
        &-wrap{
            height: calc(((100vw - 1220px)/2 + 915px)/5);
        }
    }
}
@media all and (min-width: 572px){
    .cta-content{
        padding: 60px 50px;
    }
    .more-categories-dropdown{
        min-width: 400px;
    }
}
@media all and (max-width: 991px){
    .cta-image{
        position: static;
        min-height: 400px;
        width: 100%;
    }
    .cta-tooltip{
        opacity: 1;
        bottom: 0;
    }
}
@media all and (max-width: 571px){
    .cta-tooltip{
        width: 100%;
        &::before{
            left: 45%;
        }
    }
}
.footer-title{
    font-size: .875rem;
}
.footer-menu-item{
    line-height: 24px;
    font-size: .8125rem;
    &:hover{
        @extend .current;
    }
}
.footer-title{
    font-weight: 500;
    font-family: 'Poppins','HelveticaNeue', 'Helvetica', Arial, sans-serif;
}
.footer-icon{
    width: 18px;
}
.footer-card{
    padding:  16px 16px 50px;
    background-color: #F6F5FA;
}
.footer-card-image{
    height: 20px;
}
.footer-user-image{
    width: 50px;
    height: 50px;
}
.ml-negative{
    margin-left: -20px;
}
.footer-end{
    border-top: 1px solid #ACACB4;
}
.button-ghost-square{
    @extend .button-ghost;
    border-radius: 6px;
    background: rgba(255, 255, 255, 0.6);
    &:hover{
        background: #fff;
    }
}
.footer-card-action{
    max-width: 200px;
}
.footer-action-link{
    font-family: 'Poppins','HelveticaNeue', 'Helvetica', Arial, sans-serif;
    font-weight: 500;
    .icon{
        top: 5px;
        right: -10px;
    }
}
.footer{
    .text-s{
        line-height: 20px;
        letter-spacing: -0.02em;
    }
}
.footer-wrap{
    border-top: 1px solid $grey;
}
.footer-profile-link{
    font-size: .875rem;
    line-height: 32px;
}
.footer-profile-menu{
    border: 1px solid $grey;
    .dropdow-divider{
        border-top-color: $grey;
    }
}
.footer-ribbon-text{
    font-size: .875rem;
    color: #000;
    line-height: 22px;
}
.link-white{
    color: #fff;
    line-height: 1.5;
    font-size: 1.125rem;
    font-weight: 600;
    &:hover{
        color: $link-pink;
    }
    &::after{
        background-color: $link-pink;
    }
}
.header-search-wrap{
    @extend .widget-dropdown;
    color: #d6d3df;
}
.header-button{
    @extend .nav-button-primary;
    background: $primary-violet;
    &:hover{
        background: $secondary-violet;
    }
}
.location-borders{
    border: 1px solid $grey;
}
.featured-card{
    @extend .nearby-card;
    border: 1px solid $grey;    
    display: flex;
    flex-direction: column;
    height: 100%;
}
.featured-title{
    color: #151A40!important;
    letter-spacing: -0.02em;
}
.featured-text{
    color: $dark-grey;
    letter-spacing: 0.01em;
}
.featured-link{
    @extend .nav-link-normal;
    font-size: .65625rem;
    display: flex;
    align-items: center;
    line-height: 1;
    color: $dark;
    &:hover{
        color: $link-pink;
    }
}
.featured-slider{
    .slick-track{
        display: flex !important;
    }
    .slick-slide{
        height: inherit !important;
        padding-right: 20px;
        >div{
            height: 100%;
        }
    }
    .slick-dots{
        display: flex;
        align-items: center;
    }
    .slick-dots li{
        width: auto;
        margin: 0 16px 0 0;
        display: flex;
        align-items: center;
    }
    .slick-dots li div{
        height: 9px;
        width: 9px;
        background-color: $grey;
    }
    .slick-dots li.slick-active div{
        height: 12px;
        width: 12px;
        background-color: $primary-pink;
    }
}
.pink-hr{
    height: 4px;
    background-color: $primary-pink;
}
.section-blocks{
    .text-s{
        max-width: 230px;
    }
}
.when-dropdown{
    @extend .category-dropdown;
    min-width: 250px;
    .nav-link-normal{
        color: #9DA0A7;
        &.current{
            color: $dark;
        }
    }
}
.notifications-dropdown{
    max-width: 170px;
}
.letter-spacing-5{
    letter-spacing: .05rem;
}
.filter-events{
    .nav-link-normal{
        border-radius: 67px;
        padding: 4px 13px;
        margin: 0px 2px;
        &:hover,&.current{
            color: $dark;
            background-color: $grey;
        }
    }
    .category-dropdown{
        top: 100%;
    }
}
.online-events-image{
    border-radius: 16px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 200px;
}
.online-slider{
    .card{
        max-width: 320px;
    }
    .slick-track{
        display: flex !important;
    }
    .slick-slide{
        height: inherit !important;
        padding-right: 16px;
    }
    .card-bg{
        height: 130px;
        border-radius: 8px;
    }
    .custom-prev-arrow{
        left: 0;
        top: 50px;
        z-index: 2;
    }
    .custom-next-arrow{
        right: 0;
        top: 50px;
        z-index: 2;
    }
    .slick-slider{
        margin-right: -16px;
        padding-left: 22px;
        padding-right: 20px;
    }
    .card-date{
        font-size: 0.67875rem;
        padding: 11px 9px;
    }
}
.latest-evedo{
    .slick-list{
        padding: 0 50px 0 0!important;
    }
    .slick-slide{
        padding: 16px 0 16px 16px;
    }
}
.latest-button{
    padding: 12px 24px;
    border-radius: 67px;
    &:hover,&.selected{
        color: $dark;
        background-color: #fff;
    }
}
.slide-item-title{
    @extend .text-xs;
    font-weight: 500;
    color: $dark-grey;
    letter-spacing: -0.01em;
    bottom: 0;
    left: 0;
    right: 0;    padding: 16px;
}
.username-letter{
    width: 32px;
    height: 32px;
    background: #F5E1FE;
    border-radius: 50%;
    font-size: .8125rem;
    color: $secondary-violet;
    overflow: hidden;
    border: 1px solid #F5E1FE;
}
.dropdown-header{
    border-bottom: 1px solid #F6F5FA;
}
.dropdown-menu{
    opacity: 0;
}
.dropdown-menu.show{
    opacity: 1;
}
.profile{
    .dropdown-menu{
        min-width: 280px;
    }
    .dropdown-item{
        padding: .5rem 1.5rem;
        white-space: nowrap;
    }
}
.line-clamp {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;  
    overflow: hidden;
}
.h-0{
    height: 0;
}
.menu-left{
    position: fixed;
    right: 100%;
    top: 0;
    bottom: 0;
    z-index: 2;
    overflow: hidden;
    &-header{
        border-bottom: 1px solid $grey;
    }
}
.mobile-category{
    &::after{
        content: "";
        border-left: 6px solid transparent;
        border-right: 6px solid transparent; 
        border-top: 9px solid $light-grey;
    }
}
.has-sub{
    max-height: 50px;
    min-height: 48px;
    overflow: hidden;
}
.submenuOpened{
    &.has-sub{
        max-height: 100vh;
    }
    .menu-left{
        right: 0;
    }
    >.mobile-category{
        &::after{
            content: "";
            border-left: 6px solid transparent;
            border-right: 6px solid transparent; 
            border-bottom: 9px solid $light-grey;
            border-top: 0;
        }
    }
}
.mobile-footer-content{
    border-top: 1px solid $grey;
}
.mobile-nav-header{
    max-height: 64px;
    border-bottom: 1px solid $grey;
}
.mobile-nav-title{
    font-family: 'Bilo', 'Poppins', Helvetica, Arial, sans-serif;
    color: #23232E;
    line-height: 24px;
    font-weight: 700;
}
.menu-transition-to-bottom{
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    top: 75%;
    display: block;
    overflow: hidden;
    opacity: 0;
    z-index: -1;
}
.show.menu-transition-to-bottom{
    z-index: 99;
    top: 100%;
    opacity: 1;
}
.header-language{
    min-width: 40px;
}
.language-link{
    font-size: 0.8125rem;
    &:hover{
        @extend .current;
    }
}
.page-404{
    min-height: 100vh;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
}
.page-404_title{
    font-size: 5rem;
    letter-spacing: .03rem;
    line-height: 72px;
    color: $light-mode-text;
}
.page-404_desc{
    color: $dark-grey;
}
.page-404_link{
    color: #450AB8;
    font-weight: 600;
    font-family: 'Poppins','HelveticaNeue', 'Helvetica', Arial, sans-serif;
}
.page-maintenance{
    @extend .page-404;
}
.page-maintenance_title{
    @extend .page-404_title;
    font-size: 3.875rem;
}
.nav-item .rounded-circle{
    width: 40px;
    height: 40px;
    &:hover{
        background: #F6F5FA;
    }
}
//bs-5
@media all and (min-width: 1024px){
    .categories-list label{
        &.c1  { background-position: 30px -70px; }
        &.c2  { background-position: -60px -70px; }
        &.c3  { background-position: -150px -70px; }
        &.c4  { background-position: -240px -70px; }
        &.c5  { background-position: -330px -70px; }
        &.c6  { background-position: -420px -70px; }
        &.c7  { background-position: -510px -70px; }
        &.c8  { background-position: -600px -70px; }
        &.c9  { background-position: -690px -70px; }
        &.c10 { background-position: -780px -70px; }
        &.c11 { background-position: -870px -70px; }
        &.c12 { background-position: -990px -70px; }
        &.c13 { background-position: -1050px -70px; }
        &.c14 { background-position: -1140px -70px; }
        &.c15 { background-position: -1230px -70px; }

        &.unchecked {
            color: $lightGray1;
            &.c1  { background-position: 30px 0; }
            &.c2  { background-position: -60px 0; }
            &.c3  { background-position: -150px 0; }
            &.c4  { background-position: -240px 0; }
            &.c5  { background-position: -330px 0; }
            &.c6  { background-position: -420px 0; }
            &.c7  { background-position: -510px 0; }
            &.c8  { background-position: -600px 0; }
            &.c9  { background-position: -690px 0; }
            &.c10 { background-position: -780px 0; }
            &.c11 { background-position: -870px 0; }
            &.c12 { background-position: -990px 0; }
            &.c13 { background-position: -1050px 0; }
            &.c14 { background-position: -1140px 0; }
            &.c15 { background-position: -1230px 0; }
        }
    }
}
@media all and (max-width: 992px){
    .active-search{
        max-width: 100%;
        width: 100%;
    }
    .categories-list{
        max-width: 700px;
        label{
            max-width: 100px;
        }
    }
}
.custom-browse input{
    height: 100%;
}
.close{
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #ffffff;
    opacity: .5;
}
button.close {
    padding: 0;
    background-color: transparent;
    border: 0;
}
.modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto;
}
.search-result-tag{
    font-family: 'Bilo', 'Poppins', Helvetica, Arial, sans-serif;
    font-size: .6875rem;
    color: $dark-grey;
    letter-spacing: .06rem;
}
.dropdown-item{
    white-space: normal;
}

//imlementations across old pages

.social-login-link{
    opacity: 1;
    transition: .3s ease-in;
    &:hover{
        transition: .3s ease-in;
        opacity: .5;
    }
}

.border-table{
    thead, tbody, tfoot, tr, td, th{
        border: 1px solid #a9a9a9;
        padding: 6px;
    }
}