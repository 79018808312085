@charset "UTF-8";

@media all {

/* PAGE: PEOPLE LIST ------------------------------ */

	section.people-list {
	}

	section.people-list ul {
		list-style-type: none;
		padding: 0;
	}

	section.people-list ul li {
		display: inline-block;
	}

	section.people-list ul li>div.row{
		background: white;
		-webkit-box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12);
		   -moz-box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12);
				box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12);
	}

	section.people-list ul li.compact {
		height: 50px;
	}

	section.people-list ul li.compact a {
		color: #888;
		display: block;
		font-size: 11px;
		height: 41px;
		line-height: 16px;
		padding: 9px 0px 0px 65px;
		text-decoration: none;
	}

	section.people-list ul li.compact a img {
		height: 50px;
		width: 50px;
	}

	section.people-list ul li.compact a span {
		display: inline;
		max-width: auto;
	}

	section.people-list ul li.compact a strong {
		color: #444;
		display: block;
		font-size: 12px;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	section.people-list ul li.compact.invited a strong {
		width: 120px;
	}

	section.people-list ul li.invited {
		background: #f8f8f8 url('#{$domain}/img/web/tick-circle.png') no-repeat 195px 15px;
	}

	section.people-list ul li.pending {
		background: #f8f8f8;
	}

	section.people-list ul li a.image,
	section.people-list ul li a.user,
	section.people-list ul li span,
	section.people-list ul li div.round-buttons {
	}

	section.people-list ul li a.image {
		display: block;
		height: 105px;
		width: 105px;
	}

	section.people-list ul li.pending a.image {
		opacity: 0.5;
	}

	section.people-list ul li a.image img {
		height: 105px;
		width: 105px;
	}

	section.people-list ul li a.user {
		color: #444;
		display: block;
		font-family: Georgia, Times New Roman, serif;
		font-size: 16px;
		max-width: 220px;
		text-overflow: ellipsis;
		white-space: nowrap;
		top: 34px;
	}

	section.people-list ul li span {
		color: #888;
		display: block;
		left: 125px;
		max-width: 220px;
		overflow: hidden;
		text-overflow: ellipsis;
		top: 55px;
		white-space: nowrap;
	}

	section.people-list ul li div.friend-marker {
		background: #d2d2d2 url('#{$domain}/img/web/icon-accept-white.gif') no-repeat 5px 6px;
		height: 28px;
		z-index: 1;

		-webkit-border-radius: 14px;
		   -moz-border-radius: 14px;
		        border-radius: 14px;

		-webkit-transition: opacity 0.1s linear;
		   -moz-transition: opacity 0.1s linear;
			 -o-transition: opacity 0.1s linear;
				transition: opacity 0.1s linear;
	}

	section.people-list ul li:hover div.friend-marker {
		opacity: 0;
	}

	section.people-list ul li div.round-buttons {
		-webkit-transition: opacity 0.1s linear, right 0.2s ease-out;
		   -moz-transition: opacity 0.1s linear, right 0.2s ease-out;
			 -o-transition: opacity 0.1s linear, right 0.2s ease-out;
				transition: opacity 0.1s linear, right 0.2s ease-out;
	}

	section.people-list ul li:hover div.round-buttons,
	section.people-list ul li.pending div.round-buttons {
		opacity: 1.0;
		right: 20px;
	}

	section.people-list ul li.pending div.round-buttons a.friend {
		background-position: 0px 0px;
	}

	section.people-list ul li.pending div.round-buttons a.unfriend {
		background-position: 0px -44px;
	}

	section.people-list div.message {
		display: none;
	}

	section.people-list div.message div.inner {
		padding: 26px 0px 28px 0px;
		text-align: center;
	}
}

@charset "UTF-8";

@media all {

/* PAGE: FRIENDS LIST ----------------------------- */

	// section.friends-list {
	// 	padding-top: 80px;
	// }

	section.friends-list div.menu a.facebook {
		color: #888;
		display: inline-block;
		height: 90px;
		line-height: 18px;
		position: relative;
		text-align: center;
		text-decoration: none;
	}

	section.friends-list div.menu a.facebook strong {
		color: #3b5998;
		display: block;
		font-size: 16px;
		line-height: 12px;
		margin: 12px 0px 3px 0px;
		padding: 3px 0px;
	}

	section.friends-list div.menu a.facebook span {
		display: block;
		line-height: 9px;
		padding: 3px 0px;
	}

	section.friends-list div.menu a.facebook.active {
		background: #3b5998;
	}

	section.friends-list div.menu a.facebook.active:before {
		border-color: #3b5998 transparent transparent transparent;
	}

	section.friends-list div.menu a.disabled {
		cursor: default;
		opacity: 0.25;
	}

	section.friends-list form {
		float: left;
		height: 50px;
		margin-bottom: 0px;
	}

	section.friends-list form input {
		margin: 0px;
	}

	section.friends-list form.hidden {
		height: 20px !important;
	}

	section.friends-list form.hidden input {
		display: none;
	}

	section.friends-list ul {
		padding-bottom: 10px;
	}

	section.friends-list ul li.message {
		background: none;
		height: 200px;
		position: relative;
		text-align: center;

		-webkit-box-shadow: none;
		   -moz-box-shadow: none;
		        box-shadow: none;
	}

	section.friends-list ul li.message div.dashed-border div {
		padding: 26px 0px 28px 0px;
		text-align: center;
	}

	section.friends-list ul li.message div.dashed-border div a.btn-primary {
		margin-top: 10px;
		width: 210px;
	}
}
