    $domain: 'https://plentix.co';
    
    $breadcrumb-divider: quote(">");
    
/* VARIABLES ------------------------------------------- */

    $borderRadius: 2px;
    $imagesPath: '../img/';

/* COLORS ---------------------------------------------- */

    $black: #000;
    $charcoalGray: #111;
    $darkGray: #222;
    $gray: #444;
    $mediumGray: #888;
    $lightGray0: #b2b2b2;
    $lightGray1: #d2d2d2;
    $lightGray2: #e2e2e2;
    $lightGray3: #e8e8e8;
    $extraLightGray1: #f0f0f0;
    $extraLightGray2: #f8f8f8;
    $white: #ffffff;
    $blue: #10345d;
    $pink: #e62e83;
    /* old blue: #36c3e4; */
    /* old pink: #f216a1; */

    $rsvp1: #12982b;
    $rsvp2: #F6A131;
    $rsvp3: #e62e83;
    $rsvp4: #10345d;

    $success: #12982b;
    $error: #ae102e;
    $warning: #F6A131;
    $highlighted: #ffffca;
    $darkBlue: #10345d;

    $facebook: #3b5998;
    $google: #dd4b39;
    $mixcloud: #000000;
    $soundcloud: #fa3219;
    $twitter: #00aced;
    $instagram: #517fa4;

    $debug: rgba(255, 0, 0, 0.25);

.pink { color: $pink; }

/* FONTS ----------------------------------------------- */

    $monospace: "Lucida Console", Monaco, monospace;
    $sansSerifLight: "Open Sans", Arial, sans-serif;
    $sansSerif: "Maven Pro", Arial, sans-serif;
    $serif: "Maven Pro", Arial, sans-serif;

/* EASINGS --------------------------------------------- */

    $bounceOut: cubic-bezier(0.34, 1.61, 0.7, 1);

/* ANIMATIONS ------------------------------------------ */

    @mixin animation($name, $duration, $easing, $iterations:infinite) {
        -webkit-animation: $name $duration $easing $iterations;
           -moz-animation: $name $duration $easing $iterations;
                animation: $name $duration $easing $iterations;
    }

/* ARROWS ---------------------------------------------- */

    @mixin arrowDown($width, $height, $color) {
        border-color: $color transparent transparent transparent;
        border-style: solid;
        border-width: $height floor($width * 0.5) 0px floor($width * 0.5);
        height: 0px;
        width: 0px;

        -webkit-transform: rotate(360deg);
           -moz-transform: rotate(360deg);
                transform: rotate(360deg);
    }

    @mixin arrowLeft($width, $height, $color) {
        border-color: transparent $color transparent transparent;
        border-style: solid;
        border-width: floor($height * 0.5) $width floor($height * 0.5) 0px;
        height: 0px;
        width: 0px;

        -webkit-transform: rotate(360deg);
           -moz-transform: rotate(360deg);
                transform: rotate(360deg);
    }

    @mixin arrowRight($width, $height, $color) {
        border-color: transparent transparent transparent $color;
        border-style: solid;
        border-width: floor($height * 0.5) 0px floor($height * 0.5) $width;
        height: 0px;
        width: 0px;

        -webkit-transform: rotate(360deg);
           -moz-transform: rotate(360deg);
                transform: rotate(360deg);
    }

    @mixin arrowUp($width, $height, $color) {
        border-color: transparent transparent $color transparent;
        border-style: solid;
        border-width: 0px floor($width * 0.5) $height floor($width * 0.5);
        height: 0px;
        width: 0px;

        -webkit-transform: rotate(360deg);
           -moz-transform: rotate(360deg);
                transform: rotate(360deg);
    }

/* BACKGROUND SIZE ------------------------------------- */

    @mixin backgroundSize($value) {
        -webkit-background-size: $value !important;
           -moz-background-size: $value !important;
                background-size: $value !important;
    }

/* BORDER BOX ------------------------------------------ */

    @mixin borderBox() {
        -webkit-box-sizing: border-box;
           -moz-box-sizing: border-box;
                box-sizing: border-box;
    }

/* BORDER RADIUS --------------------------------------- */

    @mixin borderRadius($radius) {
        -webkit-border-radius: $radius;
           -moz-border-radius: $radius;
                border-radius: $radius;
    }

    @mixin maskedRoundedBox() {
        -webkit-mask-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC');
    }

/* BOX SHADOWS ----------------------------------------- */

    @mixin boxShadow($offsetX, $offsetY, $blur, $color, $spread: 0px) {
        -webkit-box-shadow: $offsetX $offsetY $blur $spread $color;
           -moz-box-shadow: $offsetX $offsetY $blur $spread $color;
                box-shadow: $offsetX $offsetY $blur $spread $color;
    }

    @mixin boxShadows($value) {
        -webkit-box-shadow: $value;
           -moz-box-shadow: $value;
                box-shadow: $value;
    }

    @mixin insetBoxShadow($offsetX, $offsetY, $blur, $color, $spread: 0px) {
        -webkit-box-shadow: inset $offsetX $offsetY $blur $spread $color;
           -moz-box-shadow: inset $offsetX $offsetY $blur $spread $color;
                box-shadow: inset $offsetX $offsetY $blur $spread $color;
    }

    @mixin boxShadowNone() {
        -webkit-box-shadow: none;
           -moz-box-shadow: none;
                box-shadow: none;
    }

/* FONT SMOOTHING -------------------------------------- */

    @mixin antialiased() {
        -webkit-font-smoothing: antialiased;
           -moz-font-smoothing: antialiased;
                font-smoothing: antialiased;
    }

    @mixin subpixelAntialiased() {
        -webkit-font-smoothing: subpixel-antialiased;
           -moz-font-smoothing: subpixel-antialiased;
                font-smoothing: subpixel-antialiased;
    }

/* STICKY ---------------------------------------------- */

    @mixin sticky($top:0px) {
        position: -webkit-sticky;
        position: -moz-sticky;
        position: -ms-sticky;
        position: -o-sticky;
        position: sticky;
        top: $top;
    }

/* TEXT ------------------------------------------------ */

    @mixin textOverflow() {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    @mixin textOverflowNone() {
        overflow: visible;
        text-overflow: none;
        white-space: normal;
    }

    @mixin nonSelectable() {
        -webkit-user-select: none;
           -moz-user-select: none;
            -ms-user-select: none;
    }

/* TRANSFORMATIONS ------------------------------------- */

    @mixin transform($property) {
        -webkit-transform: $property;
           -moz-transform: $property;
                transform: $property;
    }


    @mixin transformImportant($property) {
        -webkit-transform: $property !important;
           -moz-transform: $property !important;
                transform: $property !important;
    }

    @mixin transformNone() {
        -webkit-transform: none !important;
           -moz-transform: none !important;
                transform: none !important;
    }

    @mixin transformOrigin($value) {
        -webkit-transform-origin: $value;
           -moz-transform-origin: $value;
                transform-origin: $value;
    }

/* TRANSITIONS ----------------------------------------- */

    @mixin transformTransition($duration, $easing, $delay:0s) {
        -webkit-transition: -webkit-transform $duration $easing $delay;
           -moz-transition: -moz-transform $duration $easing $delay;
                transition: transform $duration $easing $delay;
    }

    @mixin transformAndOtherTransition($duration, $easing, $step, $delay:0s) {
        -webkit-transition: -webkit-transform $duration $easing $delay, $step;
           -moz-transition: -moz-transform $duration $easing $delay, $step;
                transition: transform $duration $easing $delay, $step;
    }

    @mixin transition($property, $duration, $easing, $delay:0s) {
        -webkit-transition: $property $duration $easing $delay;
           -moz-transition: $property $duration $easing $delay;
                transition: $property $duration $easing $delay;
    }

    @mixin transitions($value) {
        -webkit-transition: $value;
           -moz-transition: $value;
                transition: $value;
    }

    @mixin transitionsNone() {
        -webkit-transition: none;
           -moz-transition: none;
                transition: none;
    }
