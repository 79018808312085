@charset "UTF-8";

@media all {

/* PAGE: SETTINGS --------------------------------- */

    div.settings {
    }

    div.settings div.column {
    }

    div.settings div.column section.box {
    }

    div.settings form {
    }

    div.settings form div.block {
    }

    div.settings form div.no-border {
    }

    div.settings form div.block div {
        color: #888;
        font-size: 11px;
    }

    div.settings form div.block div strong {
        color: #444;
        display: block;
        font-size: 12px;
    }

/* general */

    div.settings section.general form {

    }

    div.settings section.general form:last-child {
    }

    div.settings section.general form h2 {
        font-size: 16px;
        line-height: 16px;
        padding-bottom: 8px;
    }

    div.settings section.general form input {
    }

    div.settings section.general form fieldset {
    }

    div.settings section.general form fieldset input {
    }

    div.settings section.general form fieldset input:first-child {
    }

    div.settings section.general form select {
    }

    div.settings section.general form button {
    }

    div.settings section.general form div.message {
        background: url('#{$domain}/img/web-v2/icons/warning-gray.jpg') no-repeat -10px 7px;
        background-size: 26px 16px;
        color: #e62e83;
        height: 30px;
        line-height: 32px;
        margin-top: 10px;
        padding: 0px 10px 0px 25px;
    }

    div.settings section.general form div.success {
        background-image: url('#{$domain}/img/web-v2/icons/success.jpg');
        background-size: 26px 16px;
        color: #4fb260;
    }

/* notifications */

    div.settings section.notifications form {
        overflow: hidden;
    }

    div.settings section.notifications form div.section {
        border-top: 1px solid #f0f0f0;
        line-height: 18px;
    }

    div.settings section.notifications form div.section:first-child {
        border-top: none;
    }

    div.settings section.notifications form div.section strong {
        font-size: 14px;
    }

    div.settings section.notifications form div.group {
    }

    div.settings section.notifications form div.block {
    }

    div.settings section.notifications form div.block:nth-child(even) {
    }

    div.settings section.notifications form div.block div {
    }

    div.settings section.notifications form label.switch {
    }

/* privacy */

    div.settings section.privacy form label {
        background: #10345d;
        color: white;
        cursor: pointer;
        height: 30px;
        line-height: 30px;
        padding: 0px;
        text-align: center;
        width: 111px;
    }

    div.settings section.privacy form div + label {
        -webkit-border-radius: 2px 0px 0px 2px;
           -moz-border-radius: 2px 0px 0px 2px;
                border-radius: 2px 0px 0px 2px;
    }

    div.settings section.privacy form label:last-child {
        -webkit-border-radius: 0px 2px 2px 0px;
           -moz-border-radius: 0px 2px 2px 0px;
                border-radius: 0px 2px 2px 0px;
    }

    div.settings section.privacy form label.unchecked {
        background: #f0f0f0;
        color: #888;
    }

    div.settings section.privacy form label input {
        display: none;
    }

    div.settings section.privacy div.buttons {
        background-size: 3px 1px;
        color: #888;
        font-size: 11px;
        line-height: 16px;
        padding: 13px 20px 12px 20px;
    }

    div.settings section.privacy div.buttons a {
        color: #888;
    }

    div.settings section.privacy div.buttons a:hover {
        color: #10345d;
    }

/* social */

    div.settings section.social {
        float: left;
        width: 230px;
    }

    div.settings section.social h1.box-header {
        padding-left: 0px;
        text-align: center;
    }

    div.settings section.social a {
        border-top: 1px solid #e2e2e2;
        color: #444;
        display: block;
        height: 40px;
        line-height: 40px;
        position: relative;
        text-align: center;
        text-decoration: none;
        width: 230px;
    }

    div.settings section.social a:hover {
        color: #10345d;
    }

    div.settings section.social a.open {
        background: url('#{$domain}/img/web-v2/icons/arrow-bottom-gray.gif') no-repeat 204px 14px;
        background-size: 11px 11px;
    }

    div.settings section.social a.opened {
        background: url('#{$domain}/img/web-v2/icons/arrow-top-gray.gif') no-repeat 204px 14px;
        background-size: 11px 11px;
        color: #888;
    }

    div.settings section.social h1 + a {
        border-top: none;
    }

    div.settings section.social a div {
        background-size: 20px 20px;
        height: 20px;
        left: 10px;
        position: absolute;
        top: 10px;
        width: 20px;

        -webkit-border-radius: 2px;
           -moz-border-radius: 2px;
                border-radius: 2px;
    }

    div.settings section.social a div.facebook {
        background-image: url('#{$domain}/img/web-v2/profile/settings/facebook.jpg');
    }

    div.settings section.social a div.google-plus {
        background-image: url('#{$domain}/img/web-v2/profile/settings/google-plus.jpg');
    }

    div.settings section.social a div.twitter {
        background-image: url('#{$domain}/img/web-v2/profile/settings/twitter.jpg');
    }

    div.settings section.social form {
        /*background: #f8f8f8 url('#{$domain}/img/web-v2/common/inner-box-shadow-horizontal.jpg') repeat-x;*/
        background-size: 1px 4px;
        height: 0px;
        overflow: hidden;

        -webkit-transition: height 0.3s ease-in-out;
           -moz-transition: height 0.3s ease-in-out;
             -o-transition: height 0.3s ease-in-out;
                transition: height 0.3s ease-in-out;
    }

    div.settings section.social form p {
        border-bottom: 1px solid #e2e2e2;
        color: #888;
        line-height: 16px;
        margin: 0px;
        padding: 8px 10px 6px 10px;
        text-align: center;
    }

    div.settings section.social form div.block {
        float: left;
        padding: 0px 0px 0px 20px;
        width: 210px;
    }

    div.settings section.social form div.block div {
        width: 138px;
    }

    div.settings section.social form label.switch {
        margin-top: 12px;
    }
}

@media (-webkit-min-device-pixel-ratio: 2), (min--moz-device-pixel-ratio: 2), (-o-min-device-pixel-ratio: 2), (min-device-pixel-ratio: 2) {
    div.settings section.general form div.message { background-image: url('#{$domain}/img/web-v2/icons/warning-gray@2x.jpg'); }
    div.settings section.general form div.success { background-image: url('#{$domain}/img/web-v2/icons/success@2x.jpg'); }
    /*div.settings section.privacy div.buttons { background-image: url('#{$domain}/img/web-v2/common/horizontal-border@2x.gif'); }*/
    div.settings section.social a.open { background-image: url('#{$domain}/img/web-v2/icons/arrow-bottom-gray@2x.gif'); }
    div.settings section.social a.opened { background-image: url('#{$domain}/img/web-v2/icons/arrow-top-gray@2x.gif'); }
    div.settings section.social a div.facebook { background-image: url('#{$domain}/img/web-v2/profile/settings/facebook@2x.jpg'); }
    div.settings section.social a div.google-plus { background-image: url('#{$domain}/img/web-v2/profile/settings/google-plus@2x.jpg'); }
    div.settings section.social a div.twitter { background-image: url('#{$domain}/img/web-v2/profile/settings/twitter@2x.jpg'); }
    /*div.settings section.social form { background-image: url('#{$domain}/img/web-v2/common/inner-box-shadow-horizontal.jpg'); }*/
    /*div.settings section.notifications form div.group { background-image: url('#{$domain}/img/web-v2/common/inner-box-shadow-horizontal@2x.jpg'); }*/
}
