
@charset "UTF-8";



@media (max-width: 500px) {
    .list a.title {
        font-size: 2em;
        line-height: 2.2em;
        margin-top: 20px;
    }
    .events-list .list a.category {
        top: 252px;
    }
}

@media (min-width: 500px) and (max-width: 576px){
    .list a.title {
        font-size: 2em;
        line-height: 2.2em;
        margin-top: 20px;
    }

    .events-list .list a.category {
        top: 333px;
    }
}

@media (min-width: 576px) and (max-width: 768px){
    .events-list .list a.category {
        top: 164px;
    }
}

@media (min-width: 768px) and (max-width: 1200px){
    .events-list .list a.category {
        top: 138px;
    }
}
@media (min-width: 1200px){
    .events-list .list a.category {
        top: 169px;
    }
}


@media all {
/* LIST ------------------------------------------- */

    .list.fixed-header {
        padding-top: 93px;
    }

    .list header {
        margin-bottom: 25px;
        overflow: hidden;
        position: relative;
        text-align: center;
    }

    .list.fixed-header header {
        background: rgba(240, 240, 240, 0.98);
        left: 50%;
        margin-left: -50%;
        padding: 26px 0px 25px 0px;
        position: fixed;
        top: 70px;
        width: 100%;
        z-index: 10;
    }

    .list.fixed-header header.absolute {
        background: transparent;
        position: absolute;
        top: 0;
        z-index: 0;
    }

    .list header a.back {
        background: #d2d2d2 url(#{$domain}/img/web/back-button-arrow.jpg) no-repeat top left;
        color: white;
        display: block;
        height: 27px;
        left: 0px;
        line-height: 28px;
        margin-top: -14px;
        max-width: 200px;
        overflow: hidden;
        padding: 0px 10px 0px 20px;
        position: absolute;
        text-decoration: none;
        text-overflow: ellipsis;
        top: 50%;
        white-space: nowrap;
        width: auto;
    }

    .list header a.back:hover {
        background-color: #10345d;
        background-position: bottom left;
    }

    .list header h2 {
        float: left;
        line-height: 30px;
        margin: 4px 0px 0px 250px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 480px;
    }

    .list header h3 {
        line-height: 15px;
        padding-top: 3px;
    }

    .list header p {
        color: #888;
        line-height: 18px;
        margin: 0px;
        padding-top: 6px;
    }

    .list header nav.links {
        line-height: 18px;
        padding-top: 8px;
    }

    .list header nav.links span {
        color: #d2d2d2;
        padding: 0px 2px;
    }

    .list header form {
        float: right;
        margin: 0px;
        padding: 4px 0px 5px 0px;
    }

    .list header form input {
        margin: 0px;
        width: 218px;
    }

    .list div.loader {
        display: none;
        height: 44px;
        margin-bottom: 10px;
        position: relative;
    }

/* PAGE: EVENTS LIST ------------------------------ */

  section.events-list {
    padding: 26px 0px 10px 0px;
    text-align: center;
  }

  section.events-list header form {
    position: absolute;
    right: 0px;
    text-align: left;
    top: 2px;
    width: 230px;
  }

  section.events-list header form * {
    float: left;
  }

  section.events-list header form input {
    border-right: none;
    height: 19px;
    line-height: 18px;
    padding: 4px 5px;
    width: 151px;
  }

  section.events-list header form input.end-date {
    border-left: none;
    display: none;
  }

  section.events-list header form.period input {
    text-align: center;
    width: 67px;
  }

  section.events-list header form.period input.end-date {
    display: block;
  }

  section.events-list header form div.divider {
    background: white;
    border-bottom: 1px solid #d2d2d2;
    border-top: 1px solid #d2d2d2;
    color: #888;
    display: none;
    font-size: 13px;
    height: 27px;
    line-height: 26px;
    text-align: center;
    width: 7px;
  }

  section.events-list header form.period div.divider {
    display: block;
  }

  section.events-list header form button {
    background: #d2d2d2 no-repeat center center;
    height: 29px;
    width: 34px;

    -webkit-box-shadow: none !important;
       -moz-box-shadow: none !important;
         -o-box-shadow: none !important;
            box-shadow: none !important;
  }

  section.events-list header form button.toggle {
    background-image: url(#{$domain}/img/web/icon-date-period-white.gif);
  }

  section.events-list header form.period button.toggle {
    background-image: url(#{$domain}/img/web/icon-date-single-white.gif);
  }

  section.events-list header form button.submit {
    background-color: #10345d;
    background-image: url(#{$domain}/img/web-v2/icons/arrow-right-white.gif);
    background-position: 10px 9px;
    background-size: 11px 11px;
    border-left: 1px solid #3faed0;
  }

  section.events-list a.more {
    display: none;
    margin-bottom: 10px;
    width: 960px;
  }

  section.events-list a.more:hover {
    background: #10345d;
    color: white;

    -webkit-box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.15), inset 0px 100px 0px rgba(0, 0, 0, 0.08);
       -moz-box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.15), inset 0px 100px 0px rgba(0, 0, 0, 0.08);
        box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.15), inset 0px 100px 0px rgba(0, 0, 0, 0.08);
  }

  section.events-list div.loader.reset {
    height: 250px;
  }

  section.events-list div.message {
    display: none;
  }

  section.events-list div.message div.inner {
    padding: 26px 0px 28px 0px;
    text-align: center;
  }

/* EVENTS LIST ------------------------------------ */

    .events-list .list div.card {
        -webkit-box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12);
           -moz-box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12);
                box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12);

        width: 100%;
        margin-bottom: 20px;
        display: inline-block;
    }

    .events-list .list {
        position: relative;

    }

    .events-list .list a.mask {
        display: block;
    }

    .events-list .list a.mask img {
        width: 100%;
    }

    .events-list .list a.not-interested {
        background: rgba(0, 0, 0, 0.2) url(#{$domain}/img/web/icon-remove-white.gif) no-repeat 8px 8px;
        display: none;
        height: 24px;
        position: absolute;
        right: 0px;
        top: 0px;
        width: 24px;
        z-index: 1;
    }

    .events-list .list a.not-interested:hover {
        background-color: rgba(0, 0, 0, 0.5);
    }

    .events-list .list:hover a.not-interested {
        display: block;
    }

    .events-list .list a.category {
        background: #10345d;
        color: white;
        padding: 1px 12px 0px 12px;
        position: absolute;
        text-decoration: none;
        text-transform: uppercase;
        z-index: 1;
        left: 0;
    }



    .events-list .list a.category:hover {
        background: #e62e83;
    }

    .events-list .list a.title {
        color: #444;
        display: block;
        line-height: 18px;
        margin-bottom: 6px;
        overflow: hidden;
        padding: 8px 12px 0px 12px;
        text-decoration: none;
        text-align: left;
        font-weight: 800;
    }

    .events-list .list a.title:hover {
        color: #10345d;
    }

    .events-list .list span {
        color: #888;
        display: block;
        float: left;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding-left: 10px;
    }

    .events-list .list time {
        color: #888;
        float: right;
        padding: 3px 12px 4px 0px;
    }

    .events-list .list.message {
        height: auto;
        width: 728px;

        -webkit-box-shadow: none;
           -moz-box-shadow: none;
                box-shadow: none;
    }

    .events-list .list.message div {
        padding: 26px 0px 30px 0px;
        text-align: center;
    }

    .events-list .list.message div a.btn-primary {
        margin-top: 10px;
        width: 210px;
    }

    .events-list .list.message div a.btn-primary + a.btn-primary {
        margin-left: 10px;
    }


/* EVENTS LIST HORIZONTAL ------------------------- */

    .events-list-horizontal {
        list-style-type: none;
        margin: 0px;
    }

    .events-list-horizontal {
        padding-bottom: 1px;
    }

    .events-list-horizontal:last-child {
        padding-bottom: 0px;
    }

    .events-list-horizontal time {
        color: #888;
        display: block;
        text-align: center;
    }

    .events-list-horizontal time strong {
        background: #10345d;
        color: white;
        display: block;
        font-size: 14px;
        height: 30px;
        line-height: 30px;
        padding-left: 5px;
    }

    .events-list-horizontal div.info {
        color: #888;
        height: auto;
        padding-bottom: 20px;
    }

    .events-list-horizontal div.info a.title {
        color: #444;
        display: block;
        font-family: Georgia, Times New Roman, serif;
        font-size: 16px;
    }

    .events-list-horizontal div.info a.title:hover {
        color: #10345d;
        text-decoration: none;
    }

    .events-list-horizontal div.info span {
        color: #d2d2d2;
        padding: 0px 4px;
    }

    .events-list-horizontal div.info a.marker-green,
    .events-list-horizontal div.info a.marker-yellow,
    .events-list-horizontal div.info a.marker-pink,
    .events-list-horizontal div.info a.marker-blue {
        display: inline-block;
        height: 21px;
        line-height: 22px;
        margin: 8px 2px 0px 0px;
        padding: 0px 10px;
        text-decoration: none;
    }

    .events-list-horizontal div.info a.disabled {
        background: #f8f8f8;
        color: #d2d2d2;
    }

    .events-list-horizontal div.info a.disabled:hover {
        color: #888;
    }

    .events-list-horizontal a.mask {

    }

    .events-list-horizontal a.mask img {
        width:100%;
    }


}

@media (-webkit-min-device-pixel-ratio: 2), (min--moz-device-pixel-ratio: 2), (-o-min-device-pixel-ratio: 2), (min-device-pixel-ratio: 2) {
    section.events-list header form button.submit { background-image: url(#{$domain}/img/web-v2/icons/arrow-right-white@2x.gif); }
}
