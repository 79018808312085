@import './common';
@import './definitions';
@import './nav/nav';
@import './footer/footer';

@import '~bootstrap/scss/bootstrap';
@import '~jquery-timepicker/jquery.timepicker';
@import '~jquery-ui/themes/base/datepicker';
@import '~jquery-ui/themes/base/tooltip';

@import './achievement/achievements';
@import './messages/messages';
@import './event/event';
@import './host/host';
@import './event/list';
@import './event/yard';
@import './event/stats';
@import './calendar/calendar';

@import './event/create';
@import './host/create';

@import './widgets/widgets';

@import './modals/modals';
@import './ticket/tickets-editor';
@import './ticket/tickets';

@import './profile/settings';
@import './profile/profile';
@import './social/friends';

@import './map/map';
@import './search/advanced-search';
@import './exchange/exchange';
@import './discover/discover';
@import './search/search';


@import "./fontawesome/all";

@charset "UTF-8";

body {
  overflow-x: hidden;
  padding-right: 0px !important;
  // margin: 0 0 100px 0;
  background-color: #F6F5FA;
  font-family: 'Poppins','HelveticaNeue', 'Helvetica', Arial, sans-serif;
  // font-family: "HelveticaNeue", "Helvetica", Arial, sans-serif;
  font-size: 16px;
  line-height: 22px;
  color: #444;
}

 h1, h2, h3, h4, h5, h6 {
   font-family: 'Bilo', 'Poppins', Helvetica, Arial, sans-serif;
   //  font-family: 'Maven Pro', sans-serif;
   color: #151A40;//#10345D !important
   font-weight: 500;
   margin: 0;
   color: inherit;
   letter-spacing: -0.5px;
   text-rendering: optimizelegibility;
 }

 h1 {
   font-size: 20px;
   line-height: 56px;
 }

 h3 {
  font-size: 20px;
  line-height: 24px;
 }

 img {
   max-width: 100%;
 }
 /*
  * LINKS
  */
 a:hover {
   text-decoration: none;
   transition: 0.3s all;
 }

.ui-datepicker {
  background-color: white;
}

.when input {
  display: inline-block;
}

.mask {
  overflow: hidden;
}

.text-dark{
  color: #23232E!important;
}

.btn-primary{
  background-color: $pink;
  border: 0;
  border-radius: 2px;

}
.btn-secondary {
  background-color: $blue;
  border: 0;
  border-radius: 2px;
  padding: 0.25rem 1rem;
}
.btn-secondary:hover {
  background-color: #204875;
}
.btn-outline-secondary {
  border: 1px solid rgba(120, 125, 130, 0.1);
  border-radius:2px;
  font-weight: 600;
  color: rgba(69,69,68,0.8);
  padding: 0.25rem 1rem;
}
.btn-outline-secondary:hover {
  border-color: rgba(120, 125, 130, 0.2);
  background-color: #f2f2f5;
}

.btn {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: 0.735rem 1rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 2px;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.box {
  background-color: #ffffff;
  -webkit-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.marker-green {
  color: $rsvp1;
  .selected, &:hover {
    background-color: $rsvp1;
    i {
      color: white !important;
    }
  }
}
.marker-yellow {
  color: $rsvp2;
  .selected, &:hover {
    background-color: $rsvp2;
    i {
      color: white !important;
    }
  }
}
.marker-pink {
  color: $rsvp3;
  .selected, &:hover {
    background-color: $rsvp3;
    i {
      color: white !important;
    }
  }
}
.marker-blue {
  color: $rsvp4;
  .selected, &:hover {
    background-color: $rsvp4;
    i {
      color: white !important;
    }
  }
}

.pac-container {
    z-index: 10000 !important;
}

/* Bootstrap update */

.h1,.h2,.h3,.h4,.h5,.h6,.text-bilo{
 font-family: 'Bilo', 'Poppins', Helvetica, Arial, sans-serif;
}
.h1{
  font-size: 6.6875rem;
  line-height: 75px;
}
.h2{
  font-size: 3.5rem;
  line-height: 56px;
}
.h3{
  font-size: 2.625rem;
  line-height: 44px;
}
.h4{
  font-size: 2rem;
  line-height: 36px;
}
.h5{
  font-size: 1.5rem;
  line-height: 30px;
}
.h6{
  font-size: 1.125rem;
  line-height: 24px;
}