/* ACHIEVEMENTS OVERLAY --------------------------- */

    div.achievements-overlay {
        background: rgba(0, 0, 0, 0.9);
        background: -moz-radial-gradient(center, ellipse cover,  rgba(0,0,0,0.85) 0%, rgba(0,0,0,0.98) 95%);
        background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%,rgba(0,0,0,0.85)), color-stop(95%,rgba(0,0,0,0.98)));
        background: -webkit-radial-gradient(center, ellipse cover,  rgba(0,0,0,0.85) 0%,rgba(0,0,0,0.98) 95%);
        background: -o-radial-gradient(center, ellipse cover,  rgba(0,0,0,0.85) 0%,rgba(0,0,0,0.98) 95%);
        background: -ms-radial-gradient(center, ellipse cover,  rgba(0,0,0,0.85) 0%,rgba(0,0,0,0.98) 95%);
        background: radial-gradient(ellipse at center,  rgba(0,0,0,0.85) 0%,rgba(0,0,0,0.98) 95%);
        color: white;
        height: 100%;
        left: 0px;
        opacity: 0;
        position: fixed;
        text-align: center;
        top: 0px;
        width: 100%;
        z-index: 1060;

        -webkit-transition: opacity 0.3s linear;
           -moz-transition: opacity 0.3s linear;
             -o-transition: opacity 0.3s linear;
                transition: opacity 0.3s linear;
    }

    div.achievements-overlay a.close {
        background: url(#{$domain}/img/web/icon-close-white.gif) no-repeat center center;
        display: block;
        height: 40px;
        position: absolute;
        right: 20px;
        top: 20px;
        width: 40px;
    }

    div.achievements-overlay div.wrapper {
        font-size: 16px;
        height: 280px;
        left: 50%;
        margin: -180px 0px 0px -250px;
        position: absolute;
        top: 50%;
        width: 500px;
    }

    div.achievements-overlay div.multiple {
        height: 320px;
        margin-top: -190px;
    }

    div.achievements-overlay h1,
    div.achievements-overlay span,
    div.achievements-overlay nav {
        display: block;
        left: 0px;
        opacity: 0;
        position: absolute;
        top: 0px;
        width: 100%;
    }

    div.achievements-overlay .transition {
        -webkit-transition: opacity 0.2s linear, top 0.2s ease-out;
           -moz-transition: opacity 0.2s linear, top 0.2s ease-out;
             -o-transition: opacity 0.2s linear, top 0.2s ease-out;
                transition: opacity 0.2s linear, top 0.2s ease-out;
    }

    div.achievements-overlay span.greeting {
        font-size: 20px;
        font-weight: bold;
        line-height: 18px;
        top: 20px;
    }

    div.achievements-overlay span.intro {
        color: #d2d2d2;
        line-height: 12px;
        top: 45px;
    }

    div.achievements-overlay div.image {
        position: absolute;
        height: 1px;
        left: 250px;
        position: absolute;
        top: 132px;
        width: 1px;

        -webkit-transition: left 0.2s ease-out;
           -moz-transition: left 0.2s ease-out;
             -o-transition: left 0.2s ease-out;
                transition: left 0.2s ease-out;
    }

    div.achievements-overlay div.image a {
        background: #444 no-repeat center center;
        display: block;
        height: 0px;
        left: 0px;
        margin: 0px;
        position: absolute;
        top: 0px;
        width: 0px;

        -webkit-background-size: cover;
           -moz-background-size: cover;
             -o-background-size: cover;
                background-size: cover;

        -webkit-border-radius: 80px;
           -moz-border-radius: 80px;
                border-radius: 80px;

        -webkit-transition: all 0.2s ease-out;
           -moz-transition: all 0.2s ease-out;
             -o-transition: all 0.2s ease-out;
                transition: all 0.2s ease-out;
    }

    div.achievements-overlay div.image.active a {
        background-color: #e62e83;
        cursor: default;
        height: 130px;
        margin: -65px 0px 0px -65px;
        width: 130px;
    }

    div.achievements-overlay div.image.inactive a {
        background-color: #444;
        height: 90px;
        margin: -45px 0px 0px -45px;
        width: 90px;
    }

    div.achievements-overlay div.image.inactive a:hover {
        height: 100px;
        margin: -50px 0px 0px -50px;
        width: 100px;
    }

    div.achievements-overlay h1 {
        font-family: "HelveticaNeue", "Helvetica", Arial, sans-serif;
        font-size: 32px;
        font-weight: bold;
        line-height: 24px;
        top: 248px;
    }

    div.achievements-overlay span.description {
        line-height: 12px;
        top: 286px;
    }

    div.achievements-overlay nav {
        display: none;
        height: 12px;
        line-height: 12px;
        top: 318px;
    }

    div.achievements-overlay div.multiple nav {
        display: block;
    }

    div.achievements-overlay nav a {
        background: white;
        display: inline-block;
        height: 12px;
        margin-right: 10px;
        opacity: 0.25;
        width: 12px;

        -webkit-border-radius: 6px;
           -moz-border-radius: 6px;
                border-radius: 6px;

        -webkit-transition: opacity 0.1s linear;
           -moz-transition: opacity 0.1s linear;
             -o-transition: opacity 0.1s linear;
                transition: opacity 0.1s linear;
    }

    div.achievements-overlay nav a:last-child {
        margin-right: 0px;
    }

    div.achievements-overlay nav a:hover {
        opacity: 0.4;
    }

    div.achievements-overlay nav a.active {
        opacity: 1.0;
    }

    div.achievements-overlay div.actions {
        border-top: 1px solid #222;
        bottom: -95px;
        height: 64px;
        left: 0px;
        line-height: 20px;
        padding-top: 26px;
        position: absolute;
        width: 100%;

        -webkit-transition: bottom 0.3s ease-out;
           -moz-transition: bottom 0.3s ease-out;
             -o-transition: bottom 0.3s ease-out;
                transition: bottom 0.3s ease-out;
    }

    div.achievements-overlay div.actions strong {
        font-size: 16px;
    }
