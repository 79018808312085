@media (min-width: 767px) {
    footer .navbar .navbar-collapse{
        padding: 0px 100px !important;
    }
}

.navbar {
    background-color: #10345d !important;
    padding: 0rem 1rem !important;

    form input {
        height: 30px;
        border-width: 2px;
        border-color: hsla(212.25, 0.00%, 100.00%, 1.00);
        border-radius: 2px;
        background-color: transparent;
        -webkit-transition: all 300ms ease;
        transition: all 300ms ease;
        color: #0e335e;
        font-size: 15px;
        padding: 5px 12px;

        &:hover {
          background-color: hsla(0, 0%, 100%, .88);
        }

        &:focus {
          background-color: #fff;
          box-shadow: 1px 1px 50px 5px rgba(10, 28, 83, .61);
          color: #414543;
        }

        &::-webkit-input-placeholder {
          font-family: 'Open Sans', sans-serif;
          color: hsla(215.9999999999998, 0.00%, 100.00%, 1.00);
          font-size: 13px;
          font-weight: 400;
        }

        &:-ms-input-placeholder {
          font-family: 'Open Sans', sans-serif;
          color: hsla(215.9999999999998, 0.00%, 100.00%, 1.00);
          font-size: 13px;
          font-weight: 400;
        }

        &::-ms-input-placeholder {
          font-family: 'Open Sans', sans-serif;
          color: hsla(215.9999999999998, 0.00%, 100.00%, 1.00);
          font-size: 13px;
          font-weight: 400;
        }

        &::placeholder {
          font-family: 'Open Sans', sans-serif;
          color: hsla(215.9999999999998, 0.00%, 100.00%, 1.00);
          font-size: 13px;
          font-weight: 400;
        }
    }
    nav {
        height: 76px;

        .navbar-toggler-icon {
            color: #8d9aae !important;
        }

        .navbar-collapse {
            background-color: #10345d;
            z-index: 100;
            margin-top: -10px;
        }
    }


    span.count {
        background: #e62e83;
        color: white;

        font-weight: normal;
        font-size: 10px;
        height: 15px;
        line-height: 15px;
        text-align: center;
        position: relative;
        z-index: 1000;
        padding: 0px 3px;
        top: -10px;
        right: 5px;

        -webkit-border-radius: 2px;
           -moz-border-radius: 2px;
                border-radius: 2px;
    }

    a.button {
        color: #8d9aae !important;
        height: 76px;
        line-height: 76px;
        padding: 0px 18px !important;
        text-decoration: none;
        font-size: 15px;
        font-weight: 300;
        transition: 0.3s all;
        &:hover {
          background: #e62e83;
          color: #fff !important;
        }
        .notifications:hover {
            background-color: #1b426f;
            width: 56px;
            height: 76px;
        }
        .messages:hover {
          background-color: #1b426f;
          width: 56px;
          height: 76px;
        }
        .profilebtn:hover {
          background-color: #1b426f;
        }

    }

    .active {
        color: #fff !important;
        -webkit-box-shadow: inset 0px -3px 0px #e62e83;
           -moz-box-shadow: inset 0px -3px 0px #e62e83;
                box-shadow: inset 0px -3px 0px #e62e83;
    }

    a.notifications {
      width: 56px;
      height: 76px;
    }

    a.messages {
        width: 56px;
        height: 76px;
        margin: 0 6px;
    }

    .dropdown-menu {
        left: auto !important;
    }

    .notifications-dropdown .dropdown-menu div.head {
        font-size: 16px;
        font-weight: 300;
        height: 54px;
        line-height: 54px;
        text-align: left;
        padding:0 22px;



        -webkit-transition: box-shadow 0.2s linear;
           -moz-transition: box-shadow 0.2s linear;
             -o-transition: box-shadow 0.2s linear;
                transition: box-shadow 0.2s linear;
    }

    .notifications-dropdown .dropdown-menu div.head a {
        color: #888;
        display: inline;
        float: right;
        font-size: 11px;
        padding: 0px;
        position: absolute;
        right: 15px;
        text-decoration: none;
        top: 11px;
    }

    .notifications-dropdown .dropdown-menu div.head a:hover {
        color: #10345d;
    }

    .notifications-dropdown .dropdown-menu ul.notifications {
        max-height: 400px;
        overflow-x: hidden;
        overflow-y: auto;
        min-width: 20rem;
    }


    a.notifications, a.notifications:hover,
    a.messages, a.messages:hover {
        background-position: center center;
        background-repeat: no-repeat;
        padding: 0px;
        position: relative;
        width: 56px;
    }

    a.notifications span.count,
    a.messages span.count {
        position: absolute;
        right: 7px;
        top: 12px;
    }

    a.dropdown-item span.count {
        top: 0px;
        left: 0px;
    }
}
/* NOTIFICATIONS ---------------------------------- */

ul.notifications {
    list-style-type: none;
    margin: 0px;
    padding: 0px;
    font-family: "HelveticaNeue", "Helvetica", Arial, sans-serif;
    font-size: 12px;
    line-height: 22px;
    color: #444;
    background-color: inherit;

    li {
        background: #f8f8f8;
        border-top: 1px solid #e2e2e2;
        line-height: 17px;
        min-height: 52px;
        padding: 10px 15px 8px 70px;
        position: relative;
    }

    li.empty {
        background: white;
        color: #888;
        height: 40px;
        line-height: 40px;
        min-height: 0px;
        padding: 0px;
        text-align: center;
    }

    li.new {
        background: white;
    }

    li.new:before {
        content: url(#{$domain}/img/web/new-notification-indicator.gif);
        left: 10px;
        position: absolute;
        top: 10px;
        z-index: 10;
    }

    li .image {
        height: 50px;
        left: 10px;
        padding: 0px;
        position: absolute;
        top: 10px;
        width: 50px;
    }

    li p {
        margin: 0px;
    }

    li p.result {
        color: #888;
    }

    li p a {
        color: #444;
    }

    li time {
        color: #888;
        float: right;
        font-size: 11px;
        margin-bottom: 5px;
        text-align: right;
        width: 90px;
    }

    li a {
        color: #10345d;
        display: inline;
        padding: 0px;
    }

    li a:hover {
        background: none;
        color: #10345d;
        text-decoration: underline;
    }

    li span {
        color: #888;
        padding: 0px 7px;
    }
}


nav.tabs a {
    color: white;
    display: block;
    font-family: Georgia, Times New Roman, serif;
    font-size: 20px;
    float: left;
    height: 44px;
    line-height: 44px;
    position: relative;
    text-align: center;
    width: auto;
}

nav.tabs a span {
    background: rgba(0, 0, 0, 0.1);
    color: white;
    display: inline-block;
    font-family: "HelveticaNeue", "Helvetica", Arial, sans-serif;
    font-weight: normal;
    font-size: 11px;
    height: 16px;
    line-height: 16px;
    padding: 0px 5px;
    position: absolute;
    right: 15px;
    text-align: center;
    top: 14px;

    -webkit-border-radius: 2px;
       -moz-border-radius: 2px;
            border-radius: 2px;
}

nav.tabs a.active {
    background: white;
    color: #444;
}

nav.tabs a.active span {
    background: #e8e8e8;
    color: #888;
}

nav.tabs a:hover {
    text-decoration: none;
}

nav.button-tabs {
    width: 100%;
}

nav.button-tabs a {
    color: #888;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    line-height: 18px;
    position: relative;
    text-align: center;
    text-decoration: none;
}

nav.button-tabs a:last-child {
    margin-right: 0px;
}

nav.button-tabs a strong,
nav.button-tabs a span {
    display: block;
    padding: 3px 0px;
}

nav.button-tabs a strong {
    color: #444;
    font-size: 16px;
    line-height: 1;
}

nav.button-tabs a strong:first-child {
    margin: 12px 0px 3px 0px;
}

nav.button-tabs a span:first-child {
    margin: 12px 0px 1px 0px;
}

nav.button-tabs a.active {
    background: #10345d;
    color: white;
}

nav.button-tabs a.active strong,
nav.button-tabs a.active span {
    color: white !important;
}

nav.button-tabs a.active:before {
    border-color: #10345d transparent transparent transparent;
    border-style: solid;
    border-width: 10px 10px 0px 10px;
    bottom: -10px;
    content: '';
    display: block;
    height: 0px;
    left: 50%;
    margin-left: -10px;
    position: absolute;
    width: 0px;
    z-index: 1;

    -webkit-transform: rotate(360deg);
       -moz-transform: rotate(360deg);
            transform: rotate(360deg);
}

nav.links a {
    color: #888;
    text-decoration: none;
}

nav.links a:hover {
    color: #444;
    text-decoration: none;
}

nav.links a.active {
    color: #10345d;
    font-weight: bold;
    text-decoration: none;
}

nav.links span {
    color: #d2d2d2;
    padding: 0px 4px;
}

.navbar a.title {
    background: #f0f0f0 !important;
    border-bottom: 1px solid #d2d2d2;
    border-top: 1px solid white;
    color: #444 !important;
    line-height: 12px;
    margin: -10px 0 10px 0;
    overflow: hidden;
}

.navbar a.title img {
    height: 25px;
}

.navbar a.title span {
    color: #888 !important;
    font-size: 10px;
}

.navbar a.title:hover span {
    color: #444 !important;
}

.navbar a.language {
    padding-top:26px !important;
}