@media all {

/* PAGE: PROFILE TICKETS -------------------------- */

    section.profile-tickets {
        margin: 40px auto 80px auto;
        text-align: center;

        h1 {
            font-family: $sansSerifLight;
            font-size: 24px;
            line-height: 20px;
            padding-bottom: 4px;
        }

        h1 + p {
            color: $mediumGray;
            font-size: 14px;
            margin-bottom: 0px;
        }

        h2 {
            background-size: 3px 1px;
            color: $lightGray0;
            font-family: $sansSerif;
            font-size: 14px;
            font-weight: bold;
            height: 13px;
            line-height: 12px;
            margin: 10px 0px 35px 0px;
            text-align: center;
            text-transform: uppercase;
            text-shadow: 0px 1px 0px $extraLightGray2;

            span {
                background: $extraLightGray1;
                padding: 0px 20px;
            }
        }

        div.message {
            background: $lightGray3;
            font-size: 14px;
            padding: 28px 0px 26px 0px;
            @include borderRadius(4px);

            &.no-tickets {
                padding: 58px 0px 56px 0px;
            }

            &.no-upcoming-tickets {
                margin-top: 35px;
            }
        }

        div.message + h2 {
            margin-top: 35px;
        }

        .tickets-list {
            
            &.upcoming {
                margin-top: 35px;
            }

            div.col-12 {
                width: 250px;
                height: 400px;

                text-align: left;
                position: relative;
                div.ticket {
                    border: 1 solid #000000 !important;
                    background-color: #ffffff;
                    position: absolute;
                    float: right;
                    &:nth-child(1) {
                        z-index: 10;
                    }

                    &:nth-child(2) {
                        left: 20px;
                        top: 2px;
                        z-index: 9;
                        @include transform(rotate(2.5deg));
                    }

                    &:nth-child(3) {
                        left: 24px;
                        top: 4px;
                        z-index: 8;
                        @include transform(rotate(5.0deg));
                    }

                    &:nth-child(n+4) {
                        display: none;
                    }
                }

                div.actions {
                    height: 59px;
                    line-height: 18px;
                    padding: 21px 0px 0px 10px;
                    position: relative;
                    text-align: left;
                    width: 220px;
                    margin-top: 300px;

                    strong {
                        font-size: 16px;
                    }

                    span {
                        color: $mediumGray;
                    }

                    .buttons {
                        position: absolute;
                        right: 10px;
                        top: 28px;

                        & > a {
                            background: $lightGray1;
                            color: $white;
                            display: block;
                            float: left;
                            font-size: 12px;
                            height: 24px;
                            line-height: 24px;
                            padding: 0px 8px;
                            text-decoration: none;
                            @include borderRadius($borderRadius 0px 0px $borderRadius);

                            &:hover {
                                background: $pink;
                                color: $white;
                            }
                        }

                        .more {
                            background: $lightGray1 url('#{$domain}/img/web-v2/icons/arrow-bottom-white.gif') no-repeat 6px 7px;
                            background-size: 11px 11px;
                            float: left;
                            height: 24px;
                            margin-left: 1px;
                            position: relative;
                            width: 23px;
                            @include borderRadius(0px $borderRadius $borderRadius 0px);

                            .dropdown {
                                background: $charcoalGray;
                                display: none;
                                position: absolute;
                                padding: 4px 0px;
                                right: 0px;
                                top: 25px;
                                width: 210px;
                                z-index: 20;
                                @include borderRadius($borderRadius 0px $borderRadius $borderRadius);
                                @include boxShadow(0px, 2px, 10px, rgba(0, 0, 0, 0.25));

                                a {
                                    color: $white;
                                    display: block;
                                    height: 26px;
                                    line-height: 26px;
                                    text-align: center;
                                    text-decoration: none;

                                    &:hover {
                                        background: $pink;
                                    }
                                }

                                div {
                                    background: rgba(255, 255, 255, 0.08);
                                    height: 1px;
                                    width: 100%;
                                }
                            }

                            &:hover {
                                background-color: $charcoalGray;
                                height: 25px;
                                @include borderRadius(0px $borderRadius 0px 0px);

                                .dropdown {
                                    display: block;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (-webkit-min-device-pixel-ratio: 2), (min--moz-device-pixel-ratio: 2), (-o-min-device-pixel-ratio: 2), (min-device-pixel-ratio: 2) {
    section.profile-tickets {
        h2 {
        }

        .tickets-list {
            li {
                div.actions {
                    .buttons {
                        .more {
                            background-image: url('#{$domain}/img/web-v2/icons/arrow-bottom-white$2x.gif');
                        }
                    }
                }
            }
        }
    }
}
