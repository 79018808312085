@charset "UTF-8";


@media (min-width: 500px) and (max-width: 576px){
	div.profile section.events ul.events-list li a.category {
		bottom: 100px;
	}
}

@media (max-width: 576px) and (max-width: 768px){
	div.profile section.events ul.events-list li a.category {
		bottom: 100px;
	}
}

@media (min-width: 768px) and (max-width: 1200px){
	div.profile section.events ul.events-list li a.category {
		bottom: 130px;
	}
}

@media (min-width: 1200px){
	div.profile section.events ul.events-list li a.category {
		bottom: 100px;
	}
}

@media all {

/* PAGE: PROFILE ---------------------------------- */

	div.profile {
		position: relative;
	}

	div.profile .box:last-child {
		margin-bottom: 0px;
	}

	/* panel */

	div.profile section.panel {
	}

	div.profile section.panel form {
		margin: 0px;
		overflow: hidden;
		position: relative;
	}

	div.profile section.panel div.name {
		background: #10345d;
		color: white;
		font-size: 16px;
		font-weight: bold;
		height: 40px;
		line-height: 40px;
		overflow: hidden;
		padding: 0px 8px;
		position: relative;
		text-align: center;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	div.profile section.panel ul.information {
		color: #888;
		list-style-type: none;
		margin: 0px;
		padding: 0px;
		text-align: center;
	}

	div.profile section.panel ul.information li {
		padding: 1px 0px;
	}

	div.profile section.panel ul.information li:first-child {
		padding-top: 16px;
	}

	div.profile section.panel ul.information li:last-child {
		padding-bottom: 16px;
	}

	div.profile section.panel ul.information li span {
		color: #10345d;
	}

	div.profile section.panel section.people-widget h1 {
		color: #444;
		display: block;
		font-family: "HelveticaNeue", "Helvetica", Arial, sans-serif;
		font-size: 14px;
		font-weight: bold;
		height: 31px;
		line-height: 30px;
		text-align: center;
		text-transform: uppercase;
	}

	div.profile section.panel section.people-widget div.body {
		padding: 10px 0px 8px 11px;
	}

	/* events */

	div.profile section.events header {
		border: none;
	}

	div.profile section.events header h1 {
		background: #f0f0f0;
		float: left;
		font-size: 20px;
		line-height: 18px;
		padding: 0px 20px 0px 0px;
	}

	div.profile section.events header nav {
		background: #f0f0f0;
		cursor: default;
		line-height: 16px;
		padding-left: 20px;
	}

	div.profile section.events header nav a:hover strong {
		text-decoration: underline;
	}

	div.profile section.events ul.events-list {

	}

	div.profile section.events ul.events-list li {
		background-color: white;
	}

	div.profile section.events ul.events-list li div {
		border-top: 1px solid white;
		clear: both;
		color: white;
		font-weight: bold;
		height: 25px;
		line-height: 26px;
		text-align: center;
		text-transform: uppercase;
	}

	/* interests */

	div.profile section.interests h1.box-header {
		background: none;
		height: 54px;
    font-size: 20px;
    height: 55px;
    line-height: 56px;
	}

	div.profile section.interests h1.box-header a {
		float: right;
		font-family: Arial, serif;
		font-size: 12px;
		margin-right: 20px;
	}

	div.profile section.interests ul.items-list {
		padding: 0px 20px 1px 0px;
	}

	div.profile section.interests ul.items-list.no-border {
		background: none;
		padding-bottom: 0px;
	}

	div.profile section.interests ul.items-list li a.mask {
		height: 122px;
		width: 122px;
	}

	div.profile section.interests ul.items-list li a.title,
	div.profile section.interests ul.items-list li span {
		width: 122px;
	}

	div.profile section.interests ul.items-list li span {
		height: 16px;
	}

	div.profile section.interests form {
		margin-bottom: 0px;
	}

	div.profile section.interests div#tags-wrapper {
		line-height: 20px;
		padding-bottom: 18px;
	}

	div.profile section.interests ul.tags {
		list-style-type: none;
		margin: 0px;
		overflow: hidden;
		padding: 0px;
	}

	div.profile section.interests ul.tags.transition {
		-webkit-transition: height 0.1s linear;
		   -moz-transition: height 0.1s linear;
		     -o-transition: height 0.1s linear;
		   		transition: height 0.1s linear;
	}

	div.profile section.interests ul.tags li {
		border: 1px solid #e2e2e2;
		color: #888;
		float: left;
		line-height: 18px;
		margin: 0px 2px 2px 0px;
		padding: 10px 15px;
	}

	div.profile section.interests ul.tags li a {
		background: url('#{$domain}/img/web/icon-remove-gray.gif') no-repeat center right;
		display: inline-block;
		height: 18px;
		vertical-align: top;
		width: 13px;
	}

	div.profile section.interests a#all-tags {
		background: #10345d;
		color: white;
		display: none;
		font-weight: bold;
		line-height: 20px;
		height: 20px;
		margin: 0px 20px 2px 20px;
		padding: 0px 22px 0px 20px;
		text-decoration: none;
	}

	div.profile section.interests a#all-tags span {
		background: url('#{$domain}/img/web/expand-tags-arrows.gif') no-repeat top right;
		padding: 0px 12px 0px 6px;
	}

	div.profile section.interests a#all-tags.collapse span {
		background-position: bottom right;
	}

	div.profile section.interests a#all-tags span.show { display: inline-block; }
	div.profile section.interests a#all-tags span.hide { display: none; }
	div.profile section.interests a#all-tags.collapse span.show { display: none; }
	div.profile section.interests a#all-tags.collapse span.hide { display: inline-block; }

	div.profile section.interests form.tags {
	}

	div.profile section.interests form.tags.no-padding {
		padding-top: 0px;
	}

	div.profile section.interests form.tags input {
		margin: 0px;
	}

	/* achievements */

	div.profile section.gained-achievements {
		position: relative;
	}

	div.profile section.gained-achievements h1.box-header a {
		float: right;
		font-family: Arial, serif;
		font-size: 12px;
		margin-right: 20px;
	}

	div.profile section.gained-achievements ul {
		padding-left: 0px;
		overflow: hidden;
		list-style-type: none;
	}

	div.profile section.gained-achievements ul li {
		background: no-repeat 16px 20px;
		color: #888;
		float: left;
		line-height: 16px;
		margin-left: 20px;
		padding-top: 122px;
		position: relative;
		text-align: center;
		width: 122px;
	}

	div.profile section.gained-achievements ul li strong {
		color: #444;
		display: block;
		overflow: hidden;
		padding: 0px 5px;
		text-overflow: ellipsis;
		white-space: nowrap;
		width: 112px;
	}

	div.profile section.gained-achievements ul li span.target {
		background: #e62e83;
		color: white;
		font-size: 14px;
		font-weight: bold;
		height: 28px;
		left: 79px;
		line-height: 28px;
		min-width: 18px;
		padding: 0px 5px;
		position: absolute;
		top: 19px;

		-webkit-border-radius: 14px;
		   -moz-border-radius: 14px;
		        border-radius: 14px;
	}

	div.profile section.gained-achievements ul li span.target.small {
		font-size: 14px;
	}

	div.profile section.gained-achievements ul li span.target.x-small {
		font-size: 12px;
	}

	/* activities */

	div.profile section.activities {
    position: relative;
	}

	div.profile section.activities h1.box-header {

	}

	div.profile section.activities div.feed {

	}

	div.profile section.activities div.feed div.message {
		color: #d2d2d2;
		font-family: Georgia, Times New Roman, serif;
		font-size: 20px;
		padding: 72px 0px 71px 0px;
		text-align: center;
	}

	div.profile section.activities div.feed div.item {
		border-bottom: 1px solid #e2e2e2;
		min-height: 125px;
		padding: 0px 20px 0px 145px;
		position: relative;
	}

	div.profile section.activities div.feed div.item:last-child {
		border-bottom: none;
	}

	div.profile section.activities div.feed div.item a.mask {
		height: 85px;
		left: 20px;
		position: absolute;
		top: 20px;
		width: 105px;
	}

	div.profile section.activities div.feed div.item a.mask img {
		min-height: 85px;
		width: 105px;
	}

	div.profile section.activities div.feed div.item span {
		color: #888;
		display: block;
		padding-top: 20px;
	}

	div.profile section.activities div.feed div.item a.title {
		color: #444;
		display: block;
		font-family: Georgia, Times New Roman, serif;
		font-size: 16px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	div.profile section.activities div.feed div.item-comment div.overview,
	div.profile section.activities div.feed div.item-review div.overview {
		border-top: 1px solid #e8e8e8;
		line-height: 18px;
		margin-top: 8px;
		padding-top: 10px;
	}

	div.profile section.activities div.feed div.item-review div.overview strong {
		display: block;
	}

	div.profile section.activities div.feed div.item-review div.overview div.rating {
		float: left;
	}

	div.profile section.activities div.feed div.item-comment div.overview p,
	div.profile section.activities div.feed div.item-review div.overview p {
		color: #888;
		font-size: 11px;
		margin: 0px;
		padding-bottom: 15px;
	}

	div.profile section.activities div.feed div.item-rating span {
		padding-top: 28px;
	}

	div.profile section.activities div.feed div.item-rating div.rating {
		margin-top: 6px;
	}

	div.profile section.activities div.feed div.item-friend span {
		padding-top: 40px;
	}

	div.profile section.activities div.feed div.item-rsvp span {
		padding-top: 24px;
	}

	div.profile section.activities div.feed div.item-rsvp div {
		display: inline-block;
		font-weight: bold;
		height: 21px;
		margin-top: 5px;
		padding: 0px 10px;
		text-transform: uppercase;
	}

	div.profile section.activities {
		text-align: center;
	}

	div.profile section.activities span.year {
		background: #e2e2e2;
		border-top: 1px solid #e2e2e2;
		color: white;
		display: block;
		font-size: 14px;
		font-weight: bold;
		height: 20px;
		line-height: 20px;
		text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
	}

	div.profile section.activities a {
		border-top: 1px solid #e2e2e2;
		color: #888;
		display: block;
		font-weight: bold;
		height: 40px;
		line-height: 40px;
		position: relative;
		text-decoration: none;
		text-align: left;
	}

	#activity-calendar a {
		padding-left: 30px;
	}

	div.profile section.activities a:hover {
		background: rgba(0, 0, 0, 0.02);
	}

	div.profile section.activities a:last-child {
		border-bottom: 1px solid #e2e2e2;
	}

	div.profile section.activities a.active:last-child {
		border-bottom: none;
	}

	div.profile section.activities a.active {
		background: #10345d url('#{$domain}/img/web/profile-activities-active-month.jpg') repeat-y top left;
		border-top: none;
		color: white;
		height: 51px;
		line-height: 52px;
	}

	div.profile section.activities a.active:before {
		content: url('#{$domain}/img/web/profile-activities-active-arrow.jpg');
		left: 0px;
		position: absolute;
		top: 5px;
	}

	div.profile section.activities a.active + a {
		border-top: none;
	}

/* MODAL: EDIT PROFILE ---------------------------- */

	div.profile .modal-dialog {
		min-width: auto;
	}

	#edit-profile-modal {
		width: 520px;
	}

	#edit-profile-modal div.block {
		padding-top: 0px;
	}

	#edit-profile-modal fieldset {
		width: 250px;
	}

	#edit-profile-modal fieldset label {
		color: #888;
		line-height: 10px;
		padding: 0px;
		margin-top: 9px;
	}

	#edit-profile-modal fieldset select + select {
		border-left: none;
	}

	#edit-profile-modal input {
		width: 235px;
		height: 28px;
	}

	#edit-profile-modal select {
		margin-top: 9px;
		width: 235px;
	}

	#edit-profile-modal div.loader {
		background: rgba(255, 255, 255, 0.7);
		display: none;
		height: 100%;
		left: 0px;
		position: absolute;
		top: 0px;
		width: 520px;
	}

    ul.events-list {
        list-style-type: none;
        padding: 0px;
        overflow: hidden;
    }

    ul.events-list li {
        display: inline-block;
        position: relative;

        -webkit-box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12);
           -moz-box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12);
                box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12);
    }

    ul.events-list li a.mask {
        display: block;

    }

    ul.events-list li a.mask img {

    }

    ul.events-list li a.not-interested {
        background: rgba(0, 0, 0, 0.2) url('#{$domain}/img/web/icon-remove-white.gif') no-repeat 8px 8px;
        display: none;
        height: 24px;
        position: absolute;
        right: 0px;
        top: 0px;
        width: 24px;
        z-index: 1;
    }

    ul.events-list li a.not-interested:hover {
        background-color: rgba(0, 0, 0, 0.5);
    }

    ul.events-list li:hover a.not-interested {
        display: block;
    }

    ul.events-list li a.category {
        background: #10345d;
        color: white;
        padding: 1px 12px 0px 12px;
        left: 0px;
        position: absolute;
        text-decoration: none;
        text-transform: uppercase;
        z-index: 1;
    }

    ul.events-list li a.category:hover {
        background: #e62e83;
    }

    ul.events-list li a.title {
        color: #444;
        display: block;
        height: 40px;
        line-height: 18px;
        margin-bottom: 6px;
        overflow: hidden;
        padding: 8px 12px 0px 12px;
        text-decoration: none;
        width: 206px;
    }

    ul.events-list li a.title:hover {
        color: #10345d;
    }

    ul.events-list li span {
        color: #888;
        display: block;
        float: left;
        overflow: hidden;
        padding: 3px 0px 4px 12px;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 135px;
    }

    ul.events-list li time {
        color: #888;
        float: right;
        padding: 3px 12px 4px 0px;
    }

    ul.events-list li.message {
        height: auto;
        width: 728px;

        -webkit-box-shadow: none;
           -moz-box-shadow: none;
                box-shadow: none;
    }

    ul.events-list li.message div {
        padding: 26px 0px 30px 0px;
        text-align: center;
    }

    ul.events-list li.message div a.btn-primary {
        margin-top: 10px;
        width: 210px;
    }

    ul.events-list li.message div a.btn-primary + a.btn-primary {
        margin-left: 10px;
    }

    form.PictureForm .custom-browse {
	    opacity: 0;
	}

	form.PictureForm:hover .custom-browse {
	    opacity: 1;
	}


    form.PictureForm .custom-browse strong{
	    display: block;
      	margin-top: 98px;
	}

	form.jeditable.profile-name input{
		color:white!important;
		height: auto!important;
		top:0px;
		position: relative;
		background-color: #10345d;
	}

}
