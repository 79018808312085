  /* map */

.map {

  }

.map #map-text {
    display: none;
    line-height: 18px;
    min-height: 18px;
    overflow: hidden;
    padding: 17px 60px 15px 20px;
    position: relative;
    white-space: nowrap;
  }

.map #collapse-map {
    background: url('#{$domain}/img/web/icon-close-gray.gif') no-repeat center center;
    height: 50px;
    margin-top: -25px;
    position: absolute;
    right: 20px;
    top: 50%;
    width: 42px;
  }

.map #event-map-canvas {
    height: 100px;

    -webkit-transition: height 0.2s linear;
       -moz-transition: height 0.2s linear;
         -o-transition: height 0.2s linear;
            transition: height 0.2s linear;
  }

.map #expand-map {
    background: rgba(0, 0, 0, 0) url('#{$domain}/img/web/expand-map-arrow-blue.png') no-repeat center 100px;
    bottom: 0;
    display: block;
    height: 100px;
    overflow: hidden;
    position: absolute;
    width: 100%;

    -webkit-transition: background 0.2s ease-out;
       -moz-transition: background 0.2s ease-out;
         -o-transition: background 0.2s ease-out;
            transition: background 0.2s ease-out;
  }

.map #expand-map:hover {
    background: rgba(0, 0, 0, 0.2) url('#{$domain}/img/web/expand-map-arrow-blue.png') no-repeat center bottom;
  }
