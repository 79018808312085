 .searchbox {
    height: 4.5rem;
    position: absolute;
    bottom: -50%;
    left: 0;
    right: 0;
    width: 100%;
    border-radius: 48px;
    box-shadow: 0px 16px 44px rgba(0, 0, 0, 0.08);
    div,button{
        height: 4.5rem;
    }
}
.searchbox .btn {
    font-family: 'Poppins','HelveticaNeue', 'Helvetica', Arial, sans-serif;
    border-radius: 48px;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    height: 100%;
}   
.bg-dark-blue {
    background-color: #151a40;
}
  
.header-title-search {
    font-family: 'Bilo', 'Poppins', Helvetica, Arial, sans-serif;
    font-weight: bold;
    font-size: 3rem;
}

/* Tabs Styles */
.text-tabs {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 22px;
    color: #8f8e99;
}
.text-tabs.active {
    color: #23232e !important;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link.text-tabs {
    background-color: transparent;
    border-color: transparent;
}

.search-page-content .nav-tabs {
    border-bottom: none;
}
.search-page-content .nav-link {
    padding: 0.5rem 0rem;
    &::after{
        content: "";
        position: absolute;
        transition: .3s ease;
        left: 0;
        width: 0;
        top: 100%;
        height: 2px;
        background-color: #f347ba;
    }
    &.active{
        &::after{
            width: 100%;
        }
    }
}
.search-page-content .nav-link:hover {
    border-color: transparent;
    &::after{
        transition: .3s ease;
        width: 100%;
    }
}
.search-page-content .nav-tabs .nav-item {
    margin-right: 1.875rem;
}

.e-card h6.card-subtitle {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 14px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
}

.e-card p.card-text {
    font-family: 'Bilo', 'Poppins', Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    color: #323235;
    margin-bottom: 0.8rem;
}

.date,
.location {
    font-family: 'Poppins','HelveticaNeue', 'Helvetica', Arial, sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    font-style: normal;
    color: #49494f;
    letter-spacing: 0.01em;
    line-height: 22px;
}
.location {
    font-weight: normal !important;
}

.status {
    font-family: 'Bilo', 'Poppins', Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 0.688rem;
    line-height: 22px;
    letter-spacing: 0.06em;
    text-transform: uppercase;
}

.list-group-item {
    border-color: transparent;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    padding: 1rem 0rem;
}

.list-group-item .featured-text {
    font-size: 14px;
}
.card.border-radius-64{
    border-radius: 64px;
}
/* Filter Card Styles */
.filter-card {
    border-radius: 3px;
    border: 1px solid #f1eef5;
    padding: 1rem;
    background-color: #F6F5FA;
}
.filter-title {
    font-family: 'Poppins','HelveticaNeue', 'Helvetica', Arial, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 16px;
    color: #49494f;
}
.filter-category-list .list-group-item {
    border-bottom: none;
}
.dropdown-toggle {
    font-size: 0.875rem;
    font-weight: 500;
    font-family: 'Poppins','HelveticaNeue', 'Helvetica', Arial, sans-serif;
}
.bl-grey{
    border-left: 1px solid #F1EEF5;
}
.search-icon-action{
    width: 30px;
}
.search-result-image{
    width: 180px;
    height: 120px;
    background-size: cover;
    background-position: center;
}
.result-list-item{
    border-bottom: 1px solid #F1EEF5;
}
.pink-dot-before{
    position: relative;
    &::before{
        content: "";
        position: absolute;
        right: calc(100% + 5px );
        top: 50%;
        transform: translateY(-50%);
        bottom: 0;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: #f347ba;
    }
}
.filters-title{
    font-family: 'Bilo', 'Poppins', Helvetica, Arial, sans-serif;
    font-weight: 700;
}
.input-tag {
    border: 1px solid #d6d6d6;
    border-radius: 2px;
    padding: 5px 5px 0;
}

.input-tag__tags li {
    background: #E2E3F1;
    color: #49494F;
    list-style: none;
}
.input-tag__tags li.input-tag__tags__input {
    flex-grow: 1;
}
.sb-item{
    background: #fcfbfd;
    border-radius: 4px;
}

.sb-bold-title {
    color: #616366;
    font-weight: bold;
    line-height: 32px;
    font-family: 'Bilo', 'Poppins', Helvetica, Arial, sans-serif;
}
.filters-drop-width{
    width: calc( 100% - 2rem );
}
#tag-suggestions-trigger{
    height: 0;
    opacity: 0;
    pointer-events: none;
}
.filter-category-list{
    .list-group-item{
        cursor: pointer;
    }
}
.offcanvas{
    overflow-y: scroll;
}
.category-filter-dropdown{
    max-height: 60vh;
    overflow-y: auto;
}
@media all and (max-width: 768px){
    .searchbox{
        max-width: 400px;
        position: static;
        height: auto;
        .bl-grey{
            border: 0;
            border-top: 1px solid #d4d4d4;
            border-bottom: 1px solid #d4d4d4;
            margin-bottom: 1rem;
        }
        .btn{
            border-radius: 48px!important;
        }
    }
}
@media all and (min-width: 768px) and (max-width: 992px){
    .searchbox{
        .search-input{
            min-width: 200px;
            max-width: 80%;
        }
    }
}
//Pagination styles

.pagination-button{
    @extend .text-s;
    &--prev,&--next{
        opacity: .7;
        font-size: 1.5rem;
    }
    &--current{
        @extend .label-m;
        background-color: #450AB8;
        color: #fff;
    }
    &--disabled{
        opacity: .3;
    }
    &:hover{
        background-color: #F6F5FA;
        opacity: 1;
    }
}