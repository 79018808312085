@media all {

/* PAGE: CALENDAR --------------------------------- */
	#calendar-event > div.info > span {
		display: inline;
	}

	// div.calendar {
	// 	padding-top: 20px;
	// }

	section.calendar {

		section.box {
			position: relative;
		}

		div.compact {
			background: url(#{$domain}/img/web/calendar-shadow.jpg) repeat-y top right;
		}

		div.cal {
			-webkit-transition: width 0.2s ease-in-out;
			   -moz-transition: width 0.2s ease-in-out;
				 -o-transition: width 0.2s ease-in-out;
					transition: width 0.2s ease-in-out;

			h1 {
				background: white;
				padding: 0px;
			}

			a.change-month {
				background: #10345d url(#{$domain}/img/web-v2/icons/arrow-left-white.gif) no-repeat center center;
				background-size: 11px 11px;
				display: block;
				height: 25px;
				position: absolute;
				right: 46px;
				top: 15px;
				width: 25px;
				span {
					display: none;
				}
			}

			a.next {
				background-image: url(#{$domain}/img/web-v2/icons/arrow-right-white.gif);
				background-size: 11px 11px;
				right: 20px;
			}

			table {
				border: 1px solid #d2d2d2;
				width: 100%;
				table-layout: fixed;

				td {
					border-bottom: 1px solid #d2d2d2;
					border-right: 1px solid #d2d2d2;
					vertical-align: top;
				    width: 14.285714285714285714285714285714%;
				    max-width: 14.285714285714285714285714285714%;
				}

				td div.cell {
			  	min-height: 68px;
					height: 100%;
					position: relative;
				}

				thead td {
					background: #f8f8f8;
					border-right: none;
					color: #888;
					font-size: 10px;
					height: 24px;
					padding: 0px;
					text-align: center;
					text-transform: uppercase;
					vertical-align: middle;
				}

				td.inactive {
					color: #d2d2d2;
				}

				td.today {
					background: #f0f0f0;
				}

				td span {
					display: block;
					padding: 0px 6px 0px 0px;
					text-align: right;
				}

				td.today span {
					color: #10345d;
					font-weight: bold;
				}

				td a.event {
					display: block;
					font-size: 11px;
					line-height: 14px;
					margin-bottom: 2px;
					overflow: hidden;
					text-decoration: none;
					padding: 2px;
					white-space: nowrap;

					-webkit-border-radius: 2px;
					   -moz-border-radius: 2px;
							border-radius: 2px;
				}

				td a.event span {
					display: block;
					font-weight: normal !important;
					overflow: hidden;
					padding: 0px;
					text-align: left;
					text-overflow: ellipsis;
					// white-space: nowrap;

					-webkit-transition: width 0.2s ease-in-out;
					   -moz-transition: width 0.2s ease-in-out;
						 -o-transition: width 0.2s ease-in-out;
							transition: width 0.2s ease-in-out;
				}

				td a.active {
					-webkit-box-shadow: inset 0px 20px 0px rgba(0, 0, 0, 0.25);
					   -moz-box-shadow: inset 0px 20px 0px rgba(0, 0, 0, 0.25);
							box-shadow: inset 0px 20px 0px rgba(0, 0, 0, 0.25);
				}

				td a.marker-yellow {
					background-color: #F6A131;
					color:white;
				}

				td a.marker-green {
					background-color: #12982b;
					color:white;
				}

				td a.marker-pink {
					background-color: #ce5aa4;
					color:white;
				}

				td a.marker-blue {
					background-color: #556db9;
					color:white;
				}

			}
		}



		div.mask {
			display: none;
			-webkit-transition: left 0.2s ease-in-out, width 0.2s ease-in-out;
			   -moz-transition: left 0.2s ease-in-out, width 0.2s ease-in-out;
				 -o-transition: left 0.2s ease-in-out, width 0.2s ease-in-out;
					transition: left 0.2s ease-in-out, width 0.2s ease-in-out;

			div.event-info {
				background: white;

				a.close {	
					background: rgba(0, 0, 0, 0.25) url('#{$domain}/img/web/icon-remove-white.gif') no-repeat center center;
					display: block;
					height: 26px;
					position: absolute;
					right: 0px;
					top: 0px;
					width: 26px;
				}

				a.close:hover {
					background-color: rgba(0, 0, 0, 0.5);
				}

				a.mask {
					background: #d2d2d2 url(#{$domain}/img/web/evedo.jpg) no-repeat center center;
					display: block;
					height: 170px;
					text-align: center;
				}

				a.mask img {

				}

				time {
					background: #10345d;
					display: block;
					font-size: 14px;
					font-weight: bold;
					height: 30px;
					line-height: 30px;
					text-align: center;
					text-transform: uppercase;
					color:white;
				}

				a.title {
					color: #444;
					display: block;
					font-family: Georgia, Times New Roman, serif;
					font-size: 16px;
					line-height: 20px;
					padding: 17px 20px 16px 20px;
					text-transform: uppercase;
				}

				a.title:hover {
					color: #10345d;
					text-decoration: none;
				}

				table.details {
					margin: 15px 20px;
					width: 190px;
				}

				div.marker-green,
				div.marker-yellow,
				div.marker-pink,
				div.marker-blue {
					font-weight: bold;
					height: 24px;
					line-height: 24px;
					text-align: center;
					text-transform: uppercase;
					margin-left:20px;
					margin-right:20px;
				}

				div.marker-green:hover,
				div.marker-yellow:hover,
				div.marker-pink:hover,
				div.marker-blue:hover {
					color:white;
				}
			}
		}
	/* TOOLTIP ---------------------------------------- */

		table td div.featured {
			background: #e62e83 url(#{$domain}/img/web-v2/icons/search-white.png);
			background-size: 30px 30px;
			display: none;
			height: 30px;
			left: 50%;
			margin: -15px 0px 0px -15px;
			position: absolute;
			top: 50%;
			width: 30px;

			-webkit-border-radius: 15px;
			   -moz-border-radius: 15px;
			        border-radius: 15px;
		}

		table td .empty:hover div.featured,
		table td .selected div.featured {
			display: block;
		}

		table td .empty:hover div.featured {
			cursor: pointer;
		}

		table td .selected div.featured {
			cursor: default !important;
		}
	}
	div.calendar-events-tooltip {
		background: white;
		height: auto;
		left: 50%;
		margin: 15px 0px 0px -115px;
		position: absolute;
		top: 50%;
		width: 230px;
		z-index: 10;

		-webkit-border-radius: 2px;
		   -moz-border-radius: 2px;
		        border-radius: 2px;

		-webkit-box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.25);
		   -moz-box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.25);
		        box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.25);
	}

	div.calendar-events-tooltip:before {
		border-color: transparent transparent white transparent;
        border-style: solid;
        border-width: 0px 12px 12px 12px;
		content: '';
		height: 0px;
		left: 50%;
		margin-left: -12px;
		position: absolute;
		top: -12px;
		width: 0px;
	}

	div.calendar-events-tooltip h2 {
		border-bottom: 1px solid #e2e2e2;
		height: 40px;
		font-family: "HelveticaNeue", "Helvetica", Arial, sans-serif;
		font-size: 15px;
		font-weight: bold;
		line-height: 40px;
		text-align: center;
		width: 230px;
	}

	div.calendar-events-tooltip ul {
		list-style-type: none;
		margin: 5px 0px;
		padding: 0px;
		max-height: 300px;
		overflow: auto;
		width: 230px;
	}

	div.calendar-events-tooltip ul li.loading {
		background: url(#{$domain}/img/web/loading-gray.gif) no-repeat center center;
		height: 50px;
	}

	div.calendar-events-tooltip ul li.message {
		color: #888;
		height: 50px;
		text-align: center;
	}

	div.calendar-events-tooltip ul li a {
		display: block;
		height: 40px;
		padding: 5px 10px;
		text-decoration: none;
	}

	div.calendar-events-tooltip ul li a img {
		height: 40px;
		float: left;
		margin-right: 10px;
		width: 40px;
	}

	div.calendar-events-tooltip ul li a strong {
		color: #444;
		display: block;
		height: 17px;
		margin-top: 3px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	div.calendar-events-tooltip ul li a span {
		color: #888 !important;
		display: block;
		font-weight: normal !important;
		overflow: hidden;
		text-align: left !important;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	div.calendar-events-tooltip ul li a:hover strong {
		color: #10345d;
	}

/* WIDGET: CALENDAR FILTERS ----------------------- */

	section.calendar-filters-widget label {
		background-image: url(#{$domain}/img/web/icon-tick-white.png);
		background-position: 6px 20px;
		background-repeat: no-repeat;
		display: block;
		text-decoration: none;
	}

	section.calendar-filters-widget label:hover {
		color: white;

		-webkit-box-shadow: inset 0px 100px 0px rgba(0, 0, 0, 0.08);
		   -moz-box-shadow: inset 0px 100px 0px rgba(0, 0, 0, 0.08);
				box-shadow: inset 0px 100px 0px rgba(0, 0, 0, 0.08);
	}

	section.calendar-filters-widget label input {
		display: none;
	}

	section.calendar-filters-widget label.unchecked {
		background: #f0f0f0;
		color: #888;
		cursor: pointer;
	}

	section.calendar-filters-widget label.unchecked:hover {
		-webkit-box-shadow: none;
		   -moz-box-shadow: none;
				box-shadow: none;
	}

	section.calendar-filters-widget {
		form.calendar-rsvp-buttons {
			.marker-green {
				background-color:#12982b;
				color:white;
			}
			.marker-yellow {
				background-color:#F6A131;
				color:white;
			}
			.marker-pink {
				background-color:#e62e83;
				color:white;
			}
		}
		form.calendar-rsvp-buttons {
			label.unchecked{
				background-color:#f0f0f0;
				color:#888;
			}
		}
	}
}

@media (-webkit-min-device-pixel-ratio: 2), (min--moz-device-pixel-ratio: 2), (-o-min-device-pixel-ratio: 2), (min-device-pixel-ratio: 2) {
	section.calendar div.cal a.change-month { background-image: url(#{$domain}/img/web-v2/icons/arrow-left-white@2x.gif); }
	section.calendar div.cal a.next { background-image: url(#{$domain}/img/web-v2/icons/arrow-right-white@2x.gif); }
	section.calendar table td div.featured { background-image: url(#{$domain}/img/web-v2/icons/search-white@2x.png); }
}
