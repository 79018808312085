@media (min-width: 576px) {
  #new-event-modal .modal-dialog {
    max-width: 500px;
  }
}


@media all {

    .ui-timepicker-container{
         z-index:1151 !important;
    }

    #login-modal .modal-dialog {
        min-width: 350px !important;
        max-width: 350px !important;
    }

    #login-modal form button {
        min-width: 110px;
    }
    #login-modal form label + label {
        border-top: 1px solid #e2e2e2;
    }
    #login-modal form label {
        display: inline-block;
        margin: 0px;
        padding: 15px 0px;
        width: 230px;
    }

    #new-event-modal {
        div.modal-dialog {
            max-width: 610px;
        }

        div.modal-header {
            background: $black;
            color: $white;

            button.close {
                background-color: transparent;
                background-position: center center;
                background-repeat: no-repeat;
                border: none;
                height: 60px;
                padding: 0px;
                position: absolute;
                top: 0px;
                width: 60px;
                z-index: 2;
                background-image: url('#{$domain}/img/web-v2/icons/close-white.gif');
                background-size: 12px 12px;
                right: 0px;
            }
        }
    }

/* MODAL: BILLING INFORMATION --------------------- */

    // .modal-dialog {
    //     min-width: 800px;
    // }

    .modal#billing-information-modal {
        height: 550px;
        overflow: visible;

        div.modal-header {
            background: $black;
            color: $white;
            padding: 0px;
            text-align: center;
            display: block !important;

            h2 {
                font-family: $sansSerifLight;
                font-size: 24px;
                line-height: 30px;
                padding: 24px 0px 3px 0px;
            }

            h2 + p {
                color: $mediumGray;
                font-size: 16px;
                line-height: 25px;
                padding: 0px 0px 14px 0px;
            }

            button.close {
                background-color: transparent;
                background-position: center center;
                background-repeat: no-repeat;
                border: none;
                height: 60px;
                padding: 0px;
                position: absolute;
                top: 0px;
                width: 60px;
                z-index: 2;
                background-image: url('#{$domain}/img/web-v2/icons/close-white.gif');
                background-size: 12px 12px;
                right: 0px;
            }
        }

        form.modal-body {
            background: $extraLightGray2;
            margin: 0px;
            overflow: visible;
            padding-left: 50px;
            @include insetBoxShadow(0px, 1px, 2px, $lightGray2);

            fieldset {
                float: left;
                margin-right: 40px;
                width: 245px;

                legend {
                    display: block;
                    font-size: 11px;
                    font-weight: bold;
                    height: 18px;
                    line-height: 6px;
                    margin: 0px;
                    padding: 8px 0px 0px 0px;
                    text-align: center;
                    text-transform: uppercase;
                    width: 100%;
                }

                & > div {
                    background: $white;
                    border-bottom: 1px solid $lightGray3;
                    border-left: 1px solid $lightGray1;
                    border-right: 1px solid $lightGray1;
                    height: 35px;

                    input,
                    label,
                    select {
                        background-color: transparent;
                        border: none;
                        font-size: 14px;
                        height: 35px;
                        margin: 0px;
                        padding: 0px 10px;
                        width: 100%;
                        @include borderBox();
                    }

                    select {
                        padding-right: 0px;
                    }

                    a.hint {
                        margin: 11px 8px 0px 0px;
                        float: right;
                    }

                    a.hint + input,
                    a.hint + label,
                    a.hint + select {
                        padding: 0px 5px 0px 10px;
                        width: 220px;
                    }
                }

                & > legend + div {
                    border-top: 1px solid $lightGray1;
                    @include borderRadius(2px 2px 0px 0px);
                    @include insetBoxShadow(0px, 1px, 0px, rgba(0, 0, 0, 0.02));
                }

                & > div:last-child {
                    border-bottom-color: $lightGray1;
                    @include borderRadius(0px 0px 2px 2px);
                }
            }

            fieldset.company-details {
                label {
                    span {
                        font-size: 12px;
                        font-weight: bold;
                        margin-top: 11px;
                    }

                    &.checked {
                        float: left;
                        width: 33px;

                        span:last-child {
                            display: none;
                        }
                    }

                    & + input {
                        display: none;
                        float: left;
                        padding-left: 0px !important;
                        width: 210px !important;
                    }

                    &.checked + input {
                        display: block;
                    }
                }
            }

            button {
                display: block;
                float: left;
                font-size: 16px;
                font-weight: bold;
                height: 42px;
                line-height: 42px;
                margin-top: 30px;
                padding: 0px;
                position: relative;
                text-align: center;
                text-transform: uppercase;
                width: 245px;

                &.success {
                    background: $success;
                }
            }

            div.notice {
                color: $mediumGray;
                font-size: 11px;
                float: left;
                height: 50px;
                line-height: 50px;
                margin: 0px;
                text-align: center;

                span.star {
                    color: $error;
                }
            }
        }
    }

/* MODAL: BUY TICKETS ----------------------------- */

    .modal#buy-tickets-modal {
        .modal-content {
            background: none;
            border: none;
        }

        h2 {
            font-family: $sansSerifLight;
            font-size: 24px;
            line-height: 30px;
            padding: 23px 0px 3px 0px;
            text-align: center;

            span {
                font-family: $sansSerif;
            }
        }

        h2 + p {
            color: $mediumGray;
            font-size: 16px;
            line-height: 25px;
            padding: 0px 0px 14px 0px;
            text-align: center;
        }

        form.bottom {
            border-top: 1px solid $lightGray3;
            bottom: 0px;
            height: 90px;
            left: 0px;
            margin: 0px;
            position: absolute;
            width: 800px;

            fieldset {
                float: left;
                margin-top: 0px !important;
                padding: 0px 0px 0px 30px;

                legend {
                    display: block;
                    font-family: $sansSerif;
                    font-size: 11px;
                    font-weight: bold;
                    height: 18px;
                    line-height: 6px;
                    margin: 0px;
                    padding: 20px 0px 0px 0px;
                    text-align: left;
                    text-transform: uppercase;
                    width: 100%;
                }

                legend + div {
                    color: $mediumGray;
                    height: 32px;
                    line-height: 16px;
                    text-align: left;
                    width: 300px;

                    a {
                        display: inline-block;
                        max-width: 140px;
                        vertical-align: top;
                        @include textOverflow();
                    }
                }
            }

            a.btn-primary {
                background: rgba(71, 211, 95, 0.5);
                cursor: default;
                float: right;
                height: 50px;
                padding: 0px;
                position: relative;
                width: 180px;
                @include boxShadowNone();
                @include transition(background, 0.25s, ease);

                strong,
                span {
                    display: block;
                    left: 0px;
                    position: absolute;
                    text-align: center;
                    width: 100%;
                    @include nonSelectable();
                    @include transformAndOtherTransition(0.25s, ease, 0s, color 0.25s ease);
                }

                strong {
                    font-size: 16px;
                    text-transform: uppercase;
                    top: 15px;
                }

                span {
                    color: rgba(255, 255, 255, 0);
                    top: 34px;
                    @include textOverflow();
                }

                &.active {
                    background: $success;
                    cursor: pointer;

                    &:hover {
                        background: darken($success, 10%);
                        @include insetBoxShadow(0px, -1px, 0px, rgba(0, 0, 0, 0.08));
                    }
                }

                &.details {
                    strong {
                        @include transform(translateY(-8px));
                    }

                    span {
                        color: rgba(255, 255, 255, 0.9);
                        @include transform(translateY(-8px));
                    }
                }
            }
        }

        a.btn-primary.big {
            font-size: 16px;
            font-weight: bold;
            height: 40px;
            line-height: 38px;
            padding: 0px;
            position: relative;
            width: 255px;
        }

        div.modal-header {
            height: 60px;
            background: $black;
            color: $white;

            a.logotype {
                display: none;
                left: 50%;
                margin-left: -85px;
                margin-top: -25px;
                position: absolute;
                top: -40px;

                &.shown {
                    display: block;
                }
            }

            strong.title,
            span.details {
                display: block;
                font-size: 16px;
                left: 80px;
                position: absolute;
                text-align: center;
                top: 10px;
                width: 590px;
                @include nonSelectable();
                @include textOverflow();
            }

            strong.title {
                a:hover {
                    text-decoration: none;
                }
            }

            span.details {
                color: rgba(255, 255, 255, 0.75);
                font-size: 12px;
                top: 29px;
                @include subpixelAntialiased();

                span {
                    padding: 0px 3px;
                }
            }

            button {
                background-color: transparent;
                background-position: center center;
                background-repeat: no-repeat;
                border: none;
                height: 60px;
                padding: 0px;
                position: absolute;
                top: 0px;
                width: 60px;
                z-index: 2;

                &.back {
                    background-image: url('#{$domain}/img/web-v2/icons/arrow-left-white.gif');
                    background-size: 11px 11px;
                    cursor: default;
                    left: 0px;
                    opacity: 0;
                    @include transition(opacity, 0.25s, ease);

                    &.shown {
                        cursor: pointer;
                        opacity: 1.0;
                    }
                }

                &.close {
                    background-image: url('#{$domain}/img/web-v2/icons/close-white.gif');
                    background-size: 12px 12px;
                    right: 0px;
                }
            }
        }

        div.modal-body {
            background-color: white;
            padding: inherit !important;
            .wrapper {
                width: 2400px;
                @include transformTransition(0.4s, ease-in-out);

                &.last-step {
                    @include transform(translateX(-1600px));
                }

                .slide {
                    background: $white;
                    float: left;
                    overflow: auto;
                    position: relative;
                    text-align: center;
                    width: 800px;
                }
            }
        }

        div.modal-footer {
            display: block;
            text-align: center;
            opacity: 0.7;
            @include transition(opacity, 0.25s, ease);

            &:hover {
                opacity: 1.0;
            }

            p {
                background: $darkGray;
                border: 1px solid rgba(255, 255, 255, 0.15);
                color: $lightGray0;
                display: inline-block;
                height: 26px;
                line-height: 26px;
                margin: 10px 0px 0px 0px;
                padding: 0px 15px;
                @include borderRadius(14px);
                @include transformTransition(0.25s, ease);
                @include subpixelAntialiased();

                &.hidden {
                    @include transform(translateY(50px));
                }

                a {
                    color: $pink !important;
                }
            }

        }
    }

    .modal#buy-tickets-modal .slide.select {

        table {

            border-collapse: separate;
            border-spacing: 0px;
            width: 100%;

            &.head {
                background: $white;
                left: 0px;
                position: absolute;
                top: 0px;
                width: 800px;
                z-index: 1;
                @include boxShadow(0px, 1px, 2px, rgba(0, 0, 0, 0.1));

                thead {
                    th {
                        font-size: 12px;
                        font-weight: bold;
                        height: 45px;
                        padding: 0px;
                        line-height: 14px;

                        &.name {
                            text-align: left;
                        }

                        &.quantity {
                            padding-left: 10px;
                        }
                    }
                }
            }

            &.list {
                tbody {
                    td {
                        border-bottom: 1px solid $lightGray3;
                        min-height: 60px;
                        padding: 15px 0px;

                        &:first-child,
                        &:last-child {
                            border-bottom-color: $extraLightGray2;
                        }

                        &.name {
                            text-align: left;

                            strong {
                                display: block;
                                font-size: 14px;
                                line-height: 24px;
                                width: 230px;
                                @include textOverflow();
                            }

                            p {
                                color: $mediumGray;
                                line-height: 14px;
                                margin: 0px;
                                padding-bottom: 4px;
                                padding-right: 20px;
                            }
                        }

                        &.price {
                            strong {
                                font-size: 14px;
                            }
                        }

                        &.sales {
                            font-size: 11px;
                            line-height: 16px;

                            &.have-not-started {
                                color: $success;
                            }

                            &.have-ended {
                                color: $error;
                            }

                            strong {
                                font-size: 12px;
                            }
                        }

                        div {
                            display: inline-block;

                            &.color {
                                float: left;
                                height: 16px;
                                width: 16px;
                                @include borderRadius(2px);
                            }

                            &.age {
                                background: $error;
                                color: $white;
                                font-weight: bold;
                                height: 30px;
                                line-height: 30px;
                                text-indent: 1px;
                                width: 30px;
                                @include borderRadius(16px);
                            }
                        }

                        select {
                            float: right;
                            margin: 0px;
                            width: 80px;
                        }
                    }

                    tr {
                        &.disabled {
                            color: $lightGray0;

                            td {
                                &.name {
                                    p {
                                        color: $lightGray0;
                                    }
                                }

                                div.color {
                                    opacity: 0.5;
                                }

                                div.age {
                                    background: rgba(227, 53, 53, 0.25);
                                }
                            }
                        }

                        &:last-child {
                            td {
                                border-bottom: none;
                            }
                        }
                    }
                }
            }
        }

        div.mask {
            background: $extraLightGray2;
            border-top: 45px solid $extraLightGray2;
            height: 326px;
            overflow: scroll;
            position: relative;
        }

        div.terms {
            border-top: 1px solid $lightGray3;
            bottom: 0px;
            bottom: 91px;
            color: #666;
            height: 28px;
            left: 0px;
            line-height: 28px;
            position: absolute;
            width: 800px;
        }

        form.bottom {
            fieldset.name,
            fieldset.email {
                padding-left: 30px;
                width: 180px;

                input {
                    padding: 6px 5px;
                    width: 168px;
                }
            }

            fieldset.email {
                padding-left: 20px;
            }
        }
    }

    .modal#buy-tickets-modal .slide.complete {
        overflow: hidden !important;

        p {
            span {
                background: $success;
                color: $white;
                margin: 0px 3px 0px 2px;
                padding: 2px 5px 3px 5px;
                @include borderRadius(2px);

                &.attention {
                    background: $error;
                }
            }
        }

        div.mask {
            background: $extraLightGray2;
            overflow: auto;
        }

        div.wrapper {
            width: 1600px;

            .slide {
            }

            .slide.required-information {
                text-align: left;

                div.shadow {
                    height: 20px;
                    left: 0px;
                    position: absolute;
                    top: -20px;
                    width: 800px;
                    z-index: 1;
                    @include boxShadow(0px, 1px, 2px, rgba(0, 0, 0, 0.1));
                }

                div.mask {

                    form {
                        margin: 0px;
                        height: 400px;
                        .category {
                            border-bottom: 1px solid $lightGray3;
                            padding: 30px 0px;
                            margin-left: 30px;
                            position: relative;
                            width: 690px;

                            &:last-child {
                                border-bottom: none;
                            }

                            .name {
                                height: 32px;
                                left: 0px;
                                position: absolute;
                                top: 30px;
                                width: 180px;

                                .color {
                                    background: $mediumGray;
                                    float: left;
                                    height: 16px;
                                    margin: 8px 14px 8px 0px;
                                    width: 16px;
                                    @include borderRadius($borderRadius);
                                }

                                strong {
                                    display: block;
                                    float: left;
                                    font-size: 14px;
                                    height: 32px;
                                    line-height: 32px;
                                    width: 150px;
                                    @include textOverflow();
                                }
                            }

                            div.empty {
                                color: $mediumGray;
                                height: 32px;
                                line-height: 32px;
                                margin: 0px 200px;
                                text-align: center;
                                text-shadow: 0px 1px 0px rgba(255, 255, 255, 0.5);
                                width: 288px;
                            }

                            fieldset {
                                background: $white;
                                border: 1px solid $lightGray1;
                                margin: 0px 200px 10px 200px;
                                width: 288px;
                                @include borderRadius($borderRadius);
                                @include insetBoxShadow(0px, 1px, 0px, rgba(0, 0, 0, 0.02));

                                &:last-child {
                                    margin-bottom: 0px;
                                }

                                input,
                                select {
                                    background-color: transparent;
                                    border: none;
                                    font-size: 14px;
                                    float: left;
                                    height: 30px;
                                    margin: 0px;
                                    width: 288px;
                                    @include borderBox();
                                    @include transition(background-color, 0.1s, ease);
                                }

                                select.not-selected {
                                    color: $lightGray0;
                                }

                                a.autofill {
                                    background: url('#{$domain}/img/web-v2/icons/autofill-blue.png') no-repeat 7px 9px;
                                    background-size: 18px 13px;
                                    border-left: 1px solid $lightGray3;
                                    display: block;
                                    float: right;
                                    height: 30px;
                                    width: 30px;
                                    @include transition(background-color, 0.1s, ease);
                                }

                                a.autofill + input,
                                a.autofill + select {
                                    width: 256px;
                                }

                                input.highlighted,
                                select.highlighted,
                                a.highlighted {
                                    background-color: $highlighted;
                                }

                                div.divider {
                                    background: $lightGray1;
                                    float: left;
                                    height: 1px;
                                    width: 288px;
                                }
                            }
                        }
                    }
                }

                form.bottom {
                    fieldset {
                        legend {
                            padding-top: 10px;
                            width: 480px;
                            @include textOverflow();
                        }

                        div {
                            width: 480px;
                        }
                    }
                }
            }

            .slide.payment {
                height: 400px;

                div.mask {
                    max-height: 150px;
                    @include insetBoxShadow(0px, 1px, 2px, $lightGray2);

                    table {
                        border-collapse: separate;
                        border-spacing: 0px;
                        margin: 0px auto;
                        width: 690px;

                        tr {
                            height: 36px;

                            td {
                                border-top: 1px solid $lightGray3;

                                &:nth-child(2) {
                                    text-align: left;
                                }

                                &:nth-child(3) {
                                    color: $mediumGray;
                                    text-align: right;

                                    strong {
                                        color: $gray;
                                    }
                                }

                                div.color {
                                    display: inline-block;
                                    float: left;
                                    height: 15px;
                                    width: 15px;
                                    @include borderRadius(2px);
                                }
                            }

                            &:first-child {
                                td {
                                    border-top: none;
                                }
                            }

                            &:last-child {
                                td:nth-child(1) {
                                    color: $mediumGray;
                                    text-align: left;

                                    strong {
                                        color: $blue;
                                    }
                                }

                                td:nth-child(2) {
                                    text-align: right;
                                }

                                td:nth-child(3) {
                                    text-align: center;
                                }
                            }
                        }
                    }
                }

                div.payment-methods {
                    font-size: 0px;
                    text-align: center;

                    form {
                        display: inline-block;
                        font-size: 12px;
                        height: 170px;
                        margin: 0px;
                        vertical-align: top;
                        width: 255px;

                        h2 {
                            padding: 0px 0px 14px 0px;

                            span {
                                font-weight: bold;
                            }
                        }

                        &.credit-card {
                            fieldset {
                                margin-bottom: 20px;
                            }
                        }

                        &.other {
                            .paypal-button {
                                margin: 18px 0px 32px 0px;
                            }

                            span.note {
                                color: $mediumGray;
                                display: block;
                                line-height: 14px;
                                padding: 6px 0px;
                            }
                        }

                        &.paypal-button-form {
                            display: none;
                        }
                    }

                    div.divider {
                        background: $lightGray3;
                        display: inline-block;
                        height: 170px;
                        margin: 0px 59px 0px 60px;
                        vertical-align: top;
                        width: 1px;
                    }
                }
            }
        }
    }

    .modal#buy-tickets-modal .slide.finish {
        div.tickets {
            background: url('#{$domain}/img/web-v2/common/tickets-finish-gradient.jpg') no-repeat bottom left;
            background-size: 800px 115px;
            height: 231px;
            overflow: hidden;
            position: relative;
            width: 800px;

            div.ticket-preview {
                bottom: -11px;
                left: 375px;
                margin-left: -75px;
                position: absolute;
                @include transform(translateY(205px));
                @include transformOrigin(bottom center);
                @include transformTransition(0.5s, ease, 0.4s);
            }

            &.spread {
                div.ticket-preview {
                    @include boxShadow(0px, 0px, 20px, rgba(0, 0, 0, 0.1));

                    &:nth-child(1) {
                        @include transform(translateY(0px) rotate(0deg));
                        z-index: 6;
                    }

                    &:nth-child(2) {
                        @include transform(translateY(0px) rotate(8deg));
                        z-index: 5;
                    }

                    &:nth-child(3) {
                        @include transform(translateY(0px) rotate(16deg));
                        z-index: 4;
                    }

                    &:nth-child(4) {
                        @include transform(translateY(0px) rotate(24deg));
                        z-index: 3;
                    }

                    &:nth-child(5) {
                        @include transform(translateY(0px) rotate(32deg));
                        z-index: 2;
                    }

                    &:nth-child(6) {
                        @include transform(translateY(0px) rotate(40deg));
                        z-index: 1;
                    }
                }
            }

            &.fast-transitions {
                div.ticket-preview {
                    @include transformTransition(0.2s, linear);
                }
            }

            &.spread:hover {
                div.ticket-preview {
                    &:nth-child(1) {
                        @include transform(translateY(0px) rotate(0deg));
                    }

                    &:nth-child(2) {
                        @include transform(translateY(0px) rotate(11deg));
                    }

                    &:nth-child(3) {
                        @include transform(translateY(0px) rotate(22deg));
                    }

                    &:nth-child(4) {
                        @include transform(translateY(0px) rotate(33deg));
                    }

                    &:nth-child(5) {
                        @include transform(translateY(0px) rotate(44deg));
                    }

                    &:nth-child(6) {
                        @include transform(translateY(0px) rotate(55deg));
                    }
                }
            }
        }

        div.text {
            height: 260px;
            position: relative;
            width: 800px;

            div.success {
                background: $success url('#{$domain}/img/web-v2/common/success.png');
                background-size: 44px 44px;
                border: 4px solid $white;
                height: 44px;
                left: 50%;
                margin: -26px 0px 0px -26px;
                position: absolute;
                top: 0px;
                width: 44px;
                z-index: 10;
                @include borderRadius(30px);
            }

            h2 {
                padding-top: 43px;
            }

            h2 + p {
                padding-bottom: 0px;
            }

            p.benefits {
                color: $mediumGray;
                font-size: 14px;
                line-height: 20px;
                padding: 16px 0px 14px 0px;

                strong {
                    display: block;
                    padding-bottom: 8px;

                    a.register {
                        background: $blue;
                        color: $white;
                        display: block;
                        height: 30px;
                        line-height: 30px;
                        margin: 0px auto;
                        text-decoration: none;
                        text-transform: uppercase;
                        width: 160px;
                        @include borderRadius(2px);

                        &:hover {
                            background: darken($blue, 10%);
                        }
                    }
                }

                span {
                    display: block;
                }
            }

            .btn.half-width {
                margin-top: 24px;
                width: 200px;

                & + .btn {
                    margin-left: 20px;
                }
            }
        }

        div.redirect {
            background: url('#{$domain}/img/web-v2/icons/redirect.png') no-repeat 310px 120px;
            background-size: 130px 100px;
            padding-top: 230px;
        }
    }

/* MODAL: CHANGE LANGUAGE ------------------------- */

    .modal#change-language-modal {
        @include borderRadius($borderRadius);

        div.modal-header {
            background: white;
            border-bottom: 1px solid $lightGray3;
            height: 52px;
            text-align: center;

            h2 {
                font-family: $sansSerifLight;
                font-size: 18px;
                line-height: 52px;
            }

            button.close {
                top: 0px;
            }
        }

        div.modal-body {
            max-height: 300px;

            a {
                color: $gray;
                border-bottom: 1px solid $extraLightGray1;
                display: block;
                font-size: 14px;
                height: 40px;
                line-height: 40px;
                margin: 0px 15px;
                padding-right: 30px;
                position: relative;
                text-decoration: none;
                @include textOverflow();

                div.language-flag {
                    float: left;
                    margin: 10px 10px 0px 0px;
                }

                span {
                    color: $mediumGray;
                    display: inline;
                    font-size: 13px;
                }

                .activity-indicator {
                    left: auto;
                    right: 1px;
                }

                &:last-child {
                    border-bottom: none;
                }

                &:after {
                    content: '';
                    display: block;
                    position: absolute;
                }

                &:hover {
                    strong,
                    span {
                        color: $blue;
                    }

                    &:after {
                        background: url('#{$domain}/img/web-v2/icons/arrow-right-gray.gif') no-repeat center right;
                        background-size: 11px 11px;
                        height: 11px;
                        right: 6px;
                        top: 15px;
                        width: 11px;
                    }
                }

                &.loading {
                    &:hover:after {
                        display: none;
                    }
                }

                &.selected {
                    cursor: default;

                    strong {
                        color: $gray;
                    }

                    span {
                        color: $mediumGray;
                    }

                    &:after {
                        background: url('#{$domain}/img/web-v2/common/tick-pink.jpg') no-repeat center center;
                        background-size: 20px 20px;
                        height: 20px;
                        right: 1px;
                        top: 10px;
                        width: 20px;
                        @include borderRadius(10px);
                    }
                }
            }
        }
    }

/* MODAL: EMBED TICKETS --------------------------- */

    .modal#embed-tickets-modal {
        text-align: center;

        div.modal-header {
            background: $black;
            color: $white;
            height: 130px;
            padding: 0px;
            display: block !important;

            h2 {
                font-family: $sansSerifLight;
                font-size: 24px;
                line-height: 30px;
                padding: 24px 0px 3px 0px;
            }

            h2 + p {
                color: $mediumGray;
                font-size: 16px;
                line-height: 25px;
                padding: 0px 0px 14px 0px;
            }

            button.close {
                background-color: transparent;
                background-position: center center;
                background-repeat: no-repeat;
                border: none;
                height: 60px;
                padding: 0px;
                position: absolute;
                top: 0px;
                width: 60px;
                z-index: 2;
                background-image: url('#{$domain}/img/web-v2/icons/close-white.gif');
                background-size: 12px 12px;
                right: 0px;
            }
        }

        div.modal-body {
            background: $extraLightGray2;
            margin: 0px;
            overflow: visible;
            position: relative;
            @include insetBoxShadow(0px, 1px, 2px, $lightGray2);


            h2 {
                font-family: $sansSerif;
                font-size: 18px;
                font-weight: bold;
                height: 53px;
                line-height: 52px;
            }

            div.layout {
                background: url('#{$domain}/img/web-v2/event/embed-tickets/layouts.png');
                background-size: 210px 644px;
                height: 148px;
                width: 210px;
                display: inline-block;

                &.button-small {
                    background-position: 0px 0px;
                }

                &.button-normal {
                    background-position: 0px -148px;
                }

                &.button-big {
                    background-position: 0px -296px;
                }

                &.inline {
                    background-position: 0px -444px;
                    height: 200px;
                }
            }

            select,
            input,
            textarea {
                margin: 0px;
                display: inline-block;
            }

            select {
                border-bottom: 0px;
                margin-top: 20px;
            }

            input {
                margin-bottom: 20px;
                padding: 6px 5px;
            }

            div.size {
                color: $mediumGray;
                font-size: 14px;
                height: 51px;
                line-height: 50px;

                strong {
                    color: $gray;
                }
            }

            textarea {
                cursor: default;
                font-family: $monospace;
                font-size: 10px;
                height: 29px;
                line-height: 14px;
                overflow: hidden;
                padding-top: 6px;
                resize: none;
                white-space: nowrap;
                @include transition(background, 0.1s, ease);

                &.highlighted {
                    background: $highlighted;
                }
            }

            div.divider {
                background: $lightGray3;
                bottom: 0px;
                left: 50%;
                margin-left: -1px;
                position: absolute;
                top: 0px;
                width: 1px;
            }
        }
    }

/* MODAL: FEATURE --------------------------------- */

    .modal#feature-modal {
        background: transparent;
        @include boxShadowNone();

        h1,
        h2 {
            font-family: $sansSerifLight;
            font-size: 32px;
            line-height: 40px;
            padding: 0px 0px 6px 0px;
            text-align: center;
        }

        h2 {
            font-size: 24px;
            line-height: 30px;
            padding-bottom: 8px;
        }

        p {
            color: $mediumGray;
            font-size: 16px;
            line-height: 25px;
            padding: 0px 0px 14px 0px;
            text-align: center;
        }

        div.campaign-panel {
            background: $white;
            overflow: hidden;
            position: absolute;
            top: 0px;
            left: -150px;
            width: 800px;
            z-index: 2;
            @include boxShadow(0px, 5px, 10px, rgba(0, 0, 0, 0.2));
            @include transformTransition(0.25s, ease-in-out);

            button.close {
                background-position: center center;
                background-repeat: no-repeat;
                background-size: 12px 12px;
                height: 60px;
                position: absolute;
                right: 0px;
                top: 0px;
                width: 60px;
                z-index: 2;
            }

            div.modal-header {
                background: white no-repeat top center;
                background-size: cover;
                height: 180px;
                padding: 0px;
                position: relative;
                width: 800px;
                @include transformTransition(0.4s, ease-in-out);

                &.centered-background {
                    background-position: center center;
                }

                div {
                    background: rgba(0, 0, 0, 0.75);
                    background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
                    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(0, 0, 0, 0)), color-stop(100%,rgba(0, 0, 0, 1)));
                    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%,rgba(0, 0, 0, 1) 100%);
                    background: -o-linear-gradient(top, rgba(0, 0, 0, 0) 0%,rgba(0, 0, 0, 1) 100%);
                    background: -ms-linear-gradient(top, rgba(0, 0, 0, 0) 0%,rgba(0, 0, 0, 1) 100%);
                    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%,rgba(0, 0, 0, 1) 100%);
                    color: $white;
                    height: 180px;
                    width: 800px;
                    @include transition(background, 0.25s, linear);

                    strong.title,
                    span.details {
                        bottom: 35px;
                        color: $white;
                        display: block;
                        font-size: 16px;
                        left: 80px;
                        position: absolute;
                        text-align: center;
                        width: 590px;
                        @include textOverflow();
                    }

                    strong.title {
                        z-index: 2;
                        @include transformTransition(0.4s, ease-in-out);
                    }

                    span.details {
                        bottom: 13px;
                        color: rgba(255, 255, 255, 0.75);
                        font-size: 12px;
                        @include subpixelAntialiased();
                        @include transformTransition(0.4s, ease-in-out);

                        span {
                            padding: 0px 3px;
                        }
                    }
                }
            }

            div.modal-body {
                width: 800px;
                @include transformTransition(0.4s, ease-in-out);

                .wrapper {
                    width: 3000px;
                    @include transformTransition(0.4s, ease-in-out);

                    .slide {
                        float: left;
                        overflow: auto;
                        position: relative;
                        text-align: center;
                        width: 800px;
                    }
                }
            }

            div.modal-footer {
                background: $white;
                border-top: 1px solid $lightGray3;
                bottom: 0px;
                height: 60px;
                left: 0px;
                position: absolute;
                width: 800px;
                @include transformTransition(0.4s, ease-in-out);

                .steps-bar-control {
                    margin: 15px auto 0px auto;
                    width: 480px;
                }

                a {
                    display: block;
                    font-size: 12px;
                    height: 60px;
                    line-height: 60px;
                    position: absolute;
                    text-decoration: none;
                    text-transform: uppercase;
                    top: 0px;
                    @include transition(opacity, 0.25s, linear);

                    &:after {
                        background-size: 11px 11px;
                        content: '';
                        display: block;
                        height: 11px;
                        position: absolute;
                        top: 25px;
                        width: 11px;
                    }

                    &.hidden {
                        cursor: default;
                        opacity: 0;
                    }
                }

                a:first-child {
                    color: $mediumGray;
                    left: 0px;
                    padding: 0px 20px 0px 42px;

                    &:after {
                        background-image: url('#{$domain}/img/web-v2/icons/arrow-left-gray.gif');
                        left: 19px;
                    }
                }

                a:last-child {
                    font-size: 16px;
                    font-weight: bold;
                    padding: 0px 42px 0px 20px;
                    right: 0px;

                    &:after {
                        background-image: url('#{$domain}/img/web-v2/icons/arrow-right-gray.gif');
                        right: 19px;
                    }
                }
            }

            &.collapsed-preview {
                div.modal-header {
                    @include transform(translateY(-120px));

                    div {
                        background: rgba(0, 0, 0, 1.0);
                        background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
                        background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(0, 0, 0, 1)), color-stop(100%,rgba(0, 0, 0, 1)));
                        background: -webkit-linear-gradient(top, rgba(0, 0, 0, 1) 0%,rgba(0, 0, 0, 1) 100%);
                        background: -o-linear-gradient(top, rgba(0, 0, 0, 1) 0%,rgba(0, 0, 0, 1) 100%);
                        background: -ms-linear-gradient(top, rgba(0, 0, 0, 1) 0%,rgba(0, 0, 0, 1) 100%);
                        background: linear-gradient(to bottom, rgba(0, 0, 0, 1) 0%,rgba(0, 0, 0, 1) 100%);

                        strong.title {
                            @include transform(translateY(7px));
                        }

                        span.details {
                            @include transform(translateY(4px));
                        }
                    }
                }

                div.modal-body {
                    @include transform(translateY(-120px));
                }
            }
        }

        div.summary-panel {
            background: $lightGray3;
            height: 551px;
            position: absolute;
            right: -105px;
            top: 0px;
            width: 210px;
            z-index: 1;
            @include transformTransition(0.25s, ease-in-out);

            div.heading {
                border-bottom: 1px solid #d8d8d8;
                font-size: 16px;
                font-weight: bold;
                height: 59px;
                line-height: 60px;
                text-align: center;
                width: 210px;
            }

            ul {
                background-color: $lightGray2;
                list-style-type: none;
                height: 430px;
                margin: 0px;
                overflow: auto;
                width: 210px;

                li {
                    border-bottom: 1px solid #d8d8d8;
                    color: $mediumGray;
                    line-height: 16px;
                    margin: 0px 10px;
                    padding: 12px 10px 11px 10px;

                    &:last-child {
                        border-bottom: none;
                    }

                    & > strong {
                        color: $gray;
                    }

                    & > strong,
                    & > span {
                        span {
                            display: inline-block;
                            @include borderBox();
                            @include textOverflow();

                            &:first-child {
                                width: 65%;
                            }

                            &:last-child {
                                text-align: right;
                                width: 35%;
                            }
                        }
                    }
                }
            }

            div.total {
                border-top: 1px solid #d8d8d8;
                color: $mediumGray;
                font-size: 16px;
                height: 60px;
                line-height: 60px;
                padding: 0px 20px;
                width: 170px;

                strong {
                    color: $gray;
                    float: right;
                    font-size: 20px;
                }

                &.limit-exceeded {
                    strong {
                        color: $error;
                    }
                }
            }
        }

        &.show-summary-panel {
            div.campaign-panel {
                @include transform(translateX(-105px));
            }

            div.summary-panel {
                @include transform(translateX(105px));
            }
        }
    }

    .modal#feature-modal .slide.start {
        a {
            display: block;
            float: left;
            height: 150px;
            margin: 60px 0px 0px 60px;
            padding: 28px 0px 12px 0px;
            text-decoration: none;
            width: 300px;
            @include borderRadius(4px);

            strong {
                color: $gray;
                display: block;
                font-size: 20px;
                padding-bottom: 12px;
            }

            p {
                padding-bottom: 0px;
            }

            span {
                display: block;
                font-size: 16px;
                font-weight: bold;
            }

            &:last-child {
                margin-left: 0px;
            }

            &:hover {
                background: $extraLightGray1;
            }

            &.disabled {
                cursor: default;

                &:hover {
                    background: $white;
                }

                strong,
                p,
                span {
                    color: $lightGray2;
                }
            }
        }

        div.divider {
            background: $lightGray3;
            float: left;
            height: 190px;
            margin: 60px 15px 0px 14px;
            position: relative;
            width: 1px;

            span {
                background: $blue;
                color: $white;
                display: block;
                font-size: 16px;
                height: 40px;
                left: -50%;
                line-height: 36px;
                margin: -20px 0px 0px -19px;
                position: absolute;
                top: 50%;
                width: 40px;
                @include borderRadius(20px);
            }
        }
    }

    .modal#feature-modal .slide.setup {
        form {
            margin-bottom: 0px;

            h2 {
                padding-bottom: 3px;
                padding-top: 23px;
            }

            h2 + p {
                padding-bottom: 4px;
            }

            h4 {
                font-family: $sansSerif;
                font-size: 11px;
                font-weight: bold;
                line-height: 6px;
                text-transform: uppercase;
            }

            div.center {
                width: 630px;
            }

            div.campaign-options {
                border-bottom: 1px solid $lightGray2;
                font-size: 0px;
                padding-bottom: 30px;

                h4 {
                    padding: 22px 0px 12px 0px;
                }

                fieldset {
                    display: inline-block;
                    font-size: 12px;
                    margin-left: 30px;
                    width: 230px;

                    &:first-child {
                        margin-left: 0px;
                    }

                    &.date {
                        input {
                            float: left;
                            font-size: 14px;
                            height: 28px;
                            text-align: center;
                            width: 137px;
                            @include borderRadius(2px 0px 0px 2px);

                            & + input {
                                border-left: none;
                                width: 70px;
                                @include borderRadius(0px 2px 2px 0px);
                            }
                        }
                    }

                    &.weight {
                        width: 110px;

                        select {
                            float: left;
                            font-size: 14px;
                            height: 40px;
                            margin-top: 0px;
                            padding-left: 8px;
                            width: 110px;
                        }
                    }

                    &.measurement {
                        div {
                            background: $extraLightGray1;
                            height: 40px;
                            @include borderRadius(2px);
                        }

                        label {
                            float: left;
                            font-size: 14px;
                            height: 40px;
                            line-height: 40px;
                            padding-right: 15px;
                            text-align: left;

                            span {
                                margin: 11px 9px 0px 12px;
                            }

                            &:last-child {
                                border-left: 1px solid white;
                            }
                        }
                    }
                }
            }

            div.campaign-positions {
                .positions-list {
                    font-size: 0px;
                    height: 102px;
                    list-style-type: none;
                    margin: 0px auto;
                    padding: 10px 10px 30px 10px;
                    width: 630px;

                    li {
                        background: $white;
                        border: 1px solid $lightGray2;
                        display: inline-block;
                        font-size: 12px;
                        height: 102px;
                        margin-left: 10px;
                        position: relative;
                        vertical-align: top;
                        width: 118px;
                        @include borderBox();
                        @include borderRadius(4px);

                        &:first-child {
                            margin-left: 0px;
                        }

                        div {
                            margin: 13px auto 0px auto;
                        }

                        strong {
                            display: block;
                            height: 26px;
                            line-height: 26px;
                            padding: 0px 8px;
                            @include textOverflow();
                        }

                        input {
                            display: none;
                        }

                        a {
                            background: $black url('#{$domain}/img/web-v2/icons/trash-small-white.gif') no-repeat center center;
                            background-size: 9px 13px;
                            border: 3px solid white;
                            cursor: pointer;
                            display: block;
                            height: 23px;
                            position: absolute;
                            right: -6px;
                            top: -6px;
                            width: 23px;
                            @include borderRadius(20px);
                            @include boxShadow(0px, 0px, 8px, rgba(0, 0, 0, 0.25));
                            @include transform(scale(0, 0));
                            @include transformTransition(0.1s, ease-out);
                        }

                        input:checked + a {
                            @include transform(scale(1.0, 1.0));
                        }

                        &.active {
                            border: 2px solid $success;

                            div {
                                margin-top: 12px;
                            }

                            a {
                                right: -7px;
                                top: -7px;
                            }

                            &:after {
                                background: url('#{$domain}/img/web-v2/common/feature-modal-arrow.png');
                                background-size: 22px 14px;
                                bottom: -35px;
                                content: '';
                                height: 14px;
                                left: 50%;
                                margin-left: -11px;
                                position: absolute;
                                width: 22px;
                                z-index: 1;
                            }
                        }
                    }
                }

                .positions-options {
                    background: $extraLightGray2;
                    @include insetBoxShadow(0px, 1px, 2px, $lightGray2);

                    div.center {
                        display: none;
                        padding: 30px 0px;
                        text-align: left;

                        &.active {
                            display: block;
                        }

                        h4 {
                            border-bottom: 1px solid $lightGray2;
                            height: 15px;
                            margin-bottom: 10px;
                            text-indent: 5px;

                            &.hidden {
                                display: none;
                            }
                        }

                        .preview {
                            float: left;
                            margin-right: 30px;
                            top: 30px;
                            width: 226px;

                            h4 {
                                text-align: center;
                                text-indent: 0px;
                            }
                        }

                        .cities {
                            float: left;
                            margin-right: 10px;
                            width: 118px;

                            ul {
                                padding-bottom: 5px;

                                &:empty {
                                    padding-bottom: 0px;
                                }

                                li {
                                    margin: 0px 0px 5px 0px;

                                    span {
                                        max-width: 62px;
                                    }
                                }
                            }

                            div.empty {
                                display: none;
                                text-align: center;
                                width: 374px;

                                a {
                                    display: block;
                                    font-size: 14px;
                                    font-weight: bold;
                                    height: 40px;
                                    line-height: 40px;
                                    margin-bottom: 10px;
                                    text-decoration: none;
                                    @include borderRadius(2px);

                                    &:hover {
                                        background: $extraLightGray1;
                                    }
                                }
                            }

                            ul:empty + input + div.empty {
                                display: block;
                            }

                            input {
                                width: 362px;
                            }
                        }

                        .count {
                            float: left;
                            width: 246px;

                            .row {
                                height: 21px;
                                margin-bottom: 5px;
                                padding: 3px 0px 0px 5px;
                                @include borderRadius(2px);

                                label {
                                    float: left;
                                    margin-right: 10px;
                                }

                                &:hover {
                                    background: $lightGray3;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .modal#feature-modal .slide.payment {
        form {
            h2 {
                padding-bottom: 3px;
                padding-top: 123px;
            }

            h2 + p {
                padding-bottom: 14px;
            }

            input {
                font-size: 14px;
                height: 28px;
                text-align: center;
                width: 288px;
            }

            a {
                display: block;
                font-size: 16px;
                font-weight: bold;
                height: 40px;
                line-height: 40px;
                margin: 10px auto;
                padding: 0px;
                position: relative;
                width: 300px;
            }
        }
    }

    .modal#feature-modal .slide.finish {
        h1 {
            background: url('#{$domain}/img/web-v2/common/success-big.png') no-repeat top center;
            background-size: 100px 100px;
            margin-top: 120px;
            padding-top: 126px;
        }

        a {
            display: block;
            font-size: 16px;
            font-weight: bold;
            height: 40px;
            line-height: 38px;
            margin: 26px auto 0px auto;
            padding: 0px;
            position: relative;
            width: 230px;
        }
    }

/* MODAL: FEATURED REPORT ------------------------- */

    .modal#featured-report-modal {

        .modal-content {
            background-color: white;
        }
        .modal-header {
            display: block;
            background: none;
            height: 110px;
            left: 0px;
            padding: 0px;
            text-align: center;
            top: 0px;
            width: 800px;
            z-index: 2;
            @include boxShadow(0px, 1px, 2px, rgba(0, 0, 0, 0.1));

            h2 {
                font-family: $sansSerifLight;
                height: 60px;
                line-height: 60px;
            }

            button {
                height: 60px;
                width: 60px;
            }

            form {
                margin: 0px;
                padding: 0px 0px 20px 230px;
                position: relative;

                input {
                    display: float;
                    float: left;
                    text-align: center;
                    width: 118px;

                    &.selected {
                        padding-right: 20px;
                        width: 104px;
                    }
                }

                div {
                    float: left;
                    height: 30px;
                    line-height: 30px;
                    width: 30px;
                }

                a {
                    background: url('#{$domain}/img/web-v2/icons/remove-gray.gif') no-repeat center center;
                    background-size: 8px 8px;
                    display: none;
                    height: 30px;
                    position: absolute;
                    top: 0px;
                    width: 30px;

                    &.start-date {
                        left: 330px;
                    }

                    &.end-date {
                        left: 490px;
                    }
                }
            }
        }

        .modal-body {
            height: auto;
            max-height: 440px;
            min-height: 70px;
            overflow: auto;
            position: relative;
            width: 800px;
            z-index: 1;

            div.no-campaigns {
                font-size: 16px;
                font-weight: bold;
                height: 140px;
                line-height: 140px;
                text-align: center;
            }

            ul {
                list-style-type: none;
                margin: 0px;

                li {
                    border-bottom: 1px solid $extraLightGray1;

                    &:last-child {
                        border-bottom: none;
                    }

                    div.toggle {
                        background: $white;
                        cursor: pointer;
                        height: 70px;
                        position: relative;
                        width: 100%;
                        z-index: 1;
                        @include boxShadow(0px, 1px, 2px, $lightGray2);
                        @include nonSelectable();
                        @include sticky();

                        .center {
                            height: 70px;
                            position: relative;
                            width: 630px;
                        }

                        .arrow {
                            background: url('#{$domain}/img/web-v2/icons/arrow-right-gray.gif');
                            background-size: 11px 11px;
                            height: 11px;
                            left: -35px;
                            position: absolute;
                            top: 29px;
                            width: 11px;
                            @include transformTransition(0.2s, ease-in-out);
                        }

                        strong.name {
                            display: block;
                            font-size: 16px;
                            padding: 16px 80px 4px 0px;
                        }

                        span.details {
                            color: $mediumGray;
                            display: block !important;
                            padding-right: 80px;
                            @include textOverflow();

                            span {
                                color: $lightGray1;
                                display: inline-block;
                                padding: 0px 6px;
                            }
                        }

                        label {
                            position: absolute;
                            right: 0px;
                            top: 23px;
                        }

                        &:hover {
                            strong.name {
                                color: $blue;
                            }
                        }
                    }

                    div.positions {
                        background: $extraLightGray2;
                        overflow: hidden;

                        table {
                            border-collapse: separate;
                            border-spacing: 0px;
                            margin: 35px auto 0px auto;
                            text-align: center;
                            width: 630px;

                            thead {
                                th {
                                    border-bottom: 1px solid $lightGray3;
                                    font-weight: bold;
                                    position: relative;
                                    text-transform: uppercase;
                                }
                            }

                            tbody {
                                td {
                                    border-bottom: 1px solid $lightGray3;
                                    color: $mediumGray;
                                    padding: 6px 0px;

                                    &.position-thumb {
                                        vertical-align: top;
                                    }

                                    .featured-position {
                                        border: 5px solid $white;
                                        margin: 0px auto;
                                    }
                                }
                            }

                            th:empty,
                            td:empty {
                                border-bottom: none;
                            }

                            .ellipsis {
                                display: block;
                                padding: 0px 3px;
                                width: 99px;
                                @include textOverflow();
                            }
                        }

                        table:last-child {
                            margin-bottom: 40px;
                        }
                    }

                    &.opened {
                        div.toggle {
                            .arrow {
                                @include transform(rotate(90deg));
                            }
                        }
                    }
                }
            }
        }
    }

/* MODAL: IMPORT EVENT ---------------------------- */

    .modal#import-event-modal {
        height: 220px;
        overflow: hidden;

        .transition {
            @include transition(top, 0.4s, ease-in-out);
        }

        div.modal-body {
            background: url('#{$domain}/img/web-v2/common/import-facebook-event.gif') no-repeat bottom left;
            background-size: 640px 100px;
            height: 121px;
            left: 0px;
            padding-top: 29px;
            position: absolute;
            text-align: center;
            top: 0px;
            width: 640px;

            a {
                color: $facebook;
                font-weight: bold;
            }

            button.close {
                right: 5px;
                top: 12px;
            }
        }

        form.modal-footer {
            left: 0px;
            padding: 20px;
            position: absolute;
            top: 150px;

            input {
                float: left;
                margin-top: 0px;
                width: 463px;
            }

            button {
                float: left;
                margin-left: 20px;
                width: 105px;
            }
        }

        div.loader {
            height: 220px;
            left: 0px;
            position: absolute;
            top: 220px;
            width: 640px;
            z-index: 1;
        }

        div.loading {
            margin-left: -65px;
            width: 104px;
        }
    }

/* MODAL: MANAGE DISCOUNT CODES ------------------- */

    .modal#manage-discount-codes-modal {

        h2 {
            font-family: $sansSerifLight;
            font-size: 24px;
            line-height: 30px;
            padding: 23px 0px 3px 0px;
            text-align: center;

            span {
                font-family: $sansSerif;
            }
        }

        h2 + p {
            color: $mediumGray;
            font-size: 16px;
            line-height: 25px;
            padding: 0px 0px 14px 0px;
            text-align: center;
        }

        div.modal-header {
            background: $black;
            color: $white;
            height: 60px;
            padding: 0px;
            position: relative;
            width: 800px;

            strong.title,
            span.details {
                display: block;
                font-size: 16px;
                left: 80px;
                position: absolute;
                text-align: center;
                top: 10px;
                width: 590px;
                @include nonSelectable();
                @include textOverflow();
            }

            span.details {
                color: rgba(255, 255, 255, 0.75);
                font-size: 12px;
                top: 29px;
                @include subpixelAntialiased();

                span {
                    padding: 0px 3px;
                }
            }

            button {
                background-color: transparent;
                background-position: center center;
                background-repeat: no-repeat;
                border: none;
                height: 60px;
                padding: 0px;
                position: absolute;
                top: 0px;
                width: 60px;
                z-index: 2;

                &.back {
                    background-image: url('#{$domain}/img/web-v2/icons/arrow-left-white.gif');
                    background-size: 11px 11px;
                    cursor: default;
                    left: 0px;
                    opacity: 0;
                    @include transition(opacity, 0.25s, ease);

                    &.shown {
                        cursor: pointer;
                        opacity: 1.0;
                    }
                }

                &.close {
                    background-image: url('#{$domain}/img/web-v2/icons/close-white.gif');
                    background-size: 12px 12px;
                    right: 0px;
                }
            }

            &.single-title {
                strong.title {
                    top: 18px;
                }

                span.details {
                    display: none;
                }
            }
        }

        div.modal-body {
            background-color: white;
            padding: inherit;
            overflow: hidden;
            width: 800px;

            .wrapper {
                // height: 450px;
                width: 1600px;
                overflow: hidden;
                @include transformTransition(0.4s, ease-in-out);

                .slide {
                    float: left;
                    overflow-y: scroll;
                    position: relative;
                    width: 800px;
                }
            }
        }
    }

    .modal#manage-discount-codes-modal .slide.manage {
        div.tabs {
            background: $extraLightGray2;
            height: 50px;

            a {
                border-bottom: 1px solid $lightGray3;
                box-sizing: border-box;
                color: $gray;
                display: block;
                float: left;
                height: 50px;
                line-height: 50px;
                text-align: center;
                text-decoration: none;
                width: 250px;

                span {
                    display: inline-block;
                    position: relative;
                    @include transformTransition(0.25s, ease);

                    &:after {
                        background: rgba(54, 195, 228, 0);
                        content: '';
                        display: block;
                        height: 10px;
                        position: absolute;
                        right: -16px;
                        top: 20px;
                        width: 10px;
                        @include borderRadius(5px);
                        @include transition(background, 0.25s, ease);
                    }
                }

                &.new {
                    span {
                        @include transform(translateX(-8px));

                        &:after {
                            background: $blue;
                        }
                    }
                }

                &.active {
                    background: $white;
                    border-bottom: 0px;
                    font-weight: bold;
                }
            }

            a + a {
                border-left: 1px solid $lightGray3;
            }
        }

        div#create,
        div#multi,
        div#single {
            height: 400px;
            width: 800px;
        }

        div#create {
            overflow: hidden;
            text-align: center;

            div.no-paid-tickets {
                background: $warning;
                color: $white;
                font-size: 14px;
                line-height: 18px;
                margin: 20px 20px 0px 20px;
                padding: 16px 0px;
                @include borderRadius($borderRadius);
            }

            a {
                display: block;
                float: left;
                height: 160px;
                margin: 105px 0px 0px 60px;
                padding: 18px 0px 12px 0px;
                text-decoration: none;
                width: 300px;
                @include borderRadius($borderRadius);

                strong {
                    color: $gray;
                    display: block;
                    font-size: 20px;
                    padding-bottom: 11px;
                }

                p {
                    color: $mediumGray;
                    font-size: 16px;
                    line-height: 24px;
                    padding-bottom: 5px;
                }

                span {
                    background: $success;
                    color: $white;
                    display: block;
                    font-size: 13px;
                    font-weight: bold;
                    height: 30px;
                    line-height: 28px;
                    margin: 0px auto;
                    text-align: center;
                    width: 140px;
                    @include borderRadius($borderRadius);
                }

                &:last-child {
                    margin-left: 0px;
                }

                &:hover {
                    background: $extraLightGray1;
                }
            }

            div.divider {
                background: $lightGray3;
                float: left;
                height: 190px;
                margin: 105px 15px 0px 14px;
                position: relative;
                width: 1px;

                span {
                    background: $white;
                    border: 1px solid $lightGray3;
                    color: $mediumGray;
                    display: block;
                    font-size: 16px;
                    height: 40px;
                    left: -50%;
                    line-height: 36px;
                    margin: -20px 0px 0px -19px;
                    position: absolute;
                    top: 50%;
                    width: 40px;
                    @include borderBox();
                    @include borderRadius(20px);
                }
            }

            div.no-paid-tickets ~ a {
                cursor: default;
                margin-top: 60px;

                strong,
                p {
                    color: $lightGray2;
                }

                span {
                    opacity: 0.25;
                }

                &:hover {
                    background: $white;
                }
            }

            div.no-paid-tickets ~ div.divider {
                background: $extraLightGray1;
                margin-top: 60px;

                span {
                    border-color: $extraLightGray1;
                    color: $lightGray2;
                }
            }
        }

        div#multi,
        div#single {
            overflow: hidden;
            position: relative;

            div.empty {
                padding-top: 90px;
                text-align: center;

                a.btn-primary {
                    background: $success;
                    color: $white;
                    display: block;
                    font-size: 16px;
                    font-weight: bold;
                    height: 40px;
                    line-height: 40px;
                    margin: 0px auto;
                    padding: 0px;
                    width: 250px;
                    @include borderRadius($borderRadius);

                    &.disabled {
                        cursor: default;
                        opacity: 0.25;

                        &:hover {
                            @include boxShadowNone();
                        }
                    }
                }
            }

            div.header {
                background: $white;
                height: 50px;
                left: 0px;
                position: absolute;
                top: 0px;
                width: 800px;
                z-index: 1;
                @include boxShadow(0px, 1px, 2px, rgba(0, 0, 0, 0.1));

                h3 {
                    float: left;
                    font-family: $sansSerif;
                    font-size: 14px;
                    line-height: 50px;
                    padding-left: 20px;
                }

                input {
                    float: right;
                    margin: 10px 10px 0px 0px;
                    width: 228px;
                }
            }

            ul {
                height: 350px;
                list-style-type: none;
                margin: 50px 0px 0px 0px;
                overflow: auto;
                width: 800px;

                li {
                    border-bottom: 1px solid $extraLightGray1;

                    div.code {
                        background: $white;
                        height: 60px;
                        position: relative;
                        @include transition(all, 0.25s, ease);

                        i {
                            background: rgba(255, 0, 0, 0.55);
                            color: $white;
                            font-style: normal;
                            padding-left: 2px;
                            padding-right: 2px;
                            @include borderRadius($borderRadius);
                        }

                        div.arrow {
                            background: url('#{$domain}/img/web-v2/icons/arrow-right-gray.gif') no-repeat top left;
                            background-size: 11px 11px;
                            height: 12px;
                            left: 24px;
                            position: absolute;
                            top: 25px;
                            width: 12px;
                            @include transformTransition(0.2s, ease-in-out);
                        }

                        a.name {
                            color: $gray;
                            display: block;
                            float: left;
                            font-size: 16px;
                            font-weight: bold;
                            height: 60px;
                            line-height: 60px;
                            margin-right: 10px;
                            padding: 0px 0px 0px 60px;
                            text-decoration: none;
                            width: 190px;
                            @include textOverflow();

                            i {
                                padding-bottom: 1px;
                            }

                            &:hover {
                                color: $blue;
                            }
                        }

                        div.pair {
                            color: $mediumGray;
                            height: 60px;
                            float: left;
                            width: 100px;

                            span.label {
                                display: block;
                                font-size: 11px;
                            }

                            span.value {
                                display: block;
                                font-size: 20px;
                                font-weight: bold;
                                padding-top: 1px;
                                padding-bottom: 3px;
                                @include textOverflow();
                            }
                        }

                        div.pair.discount {
                            margin-right: 10px;
                            width: 100px;
                        }

                        div.pair.categories {
                            margin-right: 28px;
                            width: 102px;

                            div {
                                float: left;
                                height: 16px;
                                margin-right: 2px;
                                margin-top: 2px;
                                width: 15px;
                                @include borderBox();
                                @include borderRadius(2px);

                                &.placeholder {
                                    background: $extraLightGray1;
                                    border: 1px solid $lightGray3;
                                    font-weight: bold;
                                    line-height: 14px;
                                    text-indent: 1px;
                                    text-align: center;

                                    &:empty {
                                        background: $white;
                                    }
                                }
                            }
                        }

                        div.pair.uses {
                            margin-right: 30px;
                            width: 100px;

                            .progress-bar {
                                background: $extraLightGray1;
                                color: darken($lightGray1, 4%);
                                font-size: 11px;
                                font-weight: bold;
                                height: 16px;
                                line-height: 16px;
                                margin-top: 2px;
                                padding-right: 4px;
                                position: relative;
                                text-align: right;
                                width: 96px;
                                @include borderRadius(2px);
                                @include insetBoxShadow(0px, 1px, 2px, rgba(0, 0, 0, 0.05));

                                div {
                                    background: $success;
                                    color: $white;
                                    height: 16px;
                                    left: 0px;
                                    min-width: 14px;
                                    padding: 0px 4px 0px 3px;
                                    position: absolute;
                                    top: 0px;
                                    white-space: nowrap;
                                    @include borderBox();
                                    @include borderRadius(2px);

                                    &.two-digits {
                                        min-width: 18px;
                                    }

                                    &.zero {
                                        background: $lightGray0;
                                    }
                                }
                            }
                        }

                        label.new-switch {
                            float: left;
                            margin-top: 18px;
                        }
                    }

                    div.details {
                        background: $extraLightGray2;
                        height: 0px;
                        overflow: hidden;

                        p.period {
                            margin: 0px;
                            padding: 22px 0px 21px 0px;
                            text-align: center;
                        }

                        div.actions {
                            border-top: 1px solid $lightGray3;
                            margin-left: 60px;
                            padding: 18px 0px;
                            text-align: center;
                            width: 630px;

                            a {
                                height: 24px;
                                line-height: 24px;
                                padding: 0px 6px;
                                @include borderRadius($borderRadius);
                            }
                        }
                    }

                    &.opened {
                        div.arrow {
                            @include transform(rotate(90deg));
                        }

                        div.code {
                            @include boxShadow(0px, 1px, 2px, $lightGray2);
                        }
                    }

                    &.no-matches {
                        border-bottom: 0px;
                        color: $gray;
                        font-size: 16px;
                        font-weight: bold;
                        line-height: 350px;
                        text-align: center;
                    }

                    &.hide {
                        display: none;
                    }
                }
            }
        }
    }

    .modal#manage-discount-codes-modal .slide.create {
        background: $extraLightGray2;

        form {
            margin-bottom: 0px;

            input[type="text"],
            select {
                font-size: 14px;
                float: left;
                height: 32px;
                margin: 0px;
                width: 250px;
                @include borderBox();
            }

            fieldset {
                background: $white;
                border: 1px solid $lightGray1;
                width: 248px;
                @include borderRadius($borderRadius);
                @include insetBoxShadow(0px, 1px, 0px, rgba(0, 0, 0, 0.02));

                input[type="text"],
                select {
                    background-color: transparent;
                    border: none;
                    float: left;
                    height: 30px;
                    width: 248px;
                }

                div.divider {
                    background: $lightGray3;
                    float: left;
                    height: 1px;
                    width: 248px;

                    &.vertical {
                        height: 30px;
                        width: 1px;
                    }
                }
            }

            div.group {
                margin-left: 60px;
                padding: 30px 0px;
                width: 630px;

                div.label {
                    float: left;
                    font-size: 14px;
                    font-weight: bold;
                    line-height: 32px;
                    width: 160px;
                }

                div.forms {
                    float: left;
                    margin: 0px 30px;
                    width: 250px;
                }

                div.details {
                    color: $mediumGray;
                    float: left;
                    font-size: 11px;
                    line-height: 14px;
                    padding-top: 1px;
                    width: 160px;
                }
            }

            div.group.codes {
                div.forms {
                    fieldset {
                        input {
                            width: 216px;
                        }

                        a {
                            display: block;
                            float: left;
                            height: 30px;
                            position: relative;
                            width: 30px;

                            &:after {
                                background: $lightGray1 url('#{$domain}/img/web-v2/icons/remove-medium-white.gif') no-repeat center center;
                                background-size: 10px 10px;
                                content: '';
                                display: block;
                                height: 20px;
                                margin: 5px 0px 0px 5px;
                                width: 20px;
                                @include borderRadius(10px);
                            }

                            &.add {
                                &:after {
                                    background-color: $success;
                                    background-image: url('#{$domain}/img/web-v2/icons/add-white.gif');
                                    background-size: 12px 12px;
                                }
                            }

                            &:hover {
                                &:after {
                                    background-color: darken($lightGray1, 10%);
                                }

                                &.add:after {
                                    background-color: darken($success, 10%);
                                }
                            }

                            &.disabled {
                                cursor: default;
                                opacity: 0.5;

                                &:hover {
                                    &:after {
                                        background-color: $lightGray1;
                                    }

                                    &.add:after {
                                        background-color: $success;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            div.group.discount {
                div.forms {
                    fieldset {
                        input {
                            width: 180px;
                        }

                        select {
                            font-size: 18px;
                            padding-left: 10px;
                            width: 65px;
                        }
                    }
                }
            }

            div.group.categories {
                padding: 26px 0px 25px 0px;

                div.label {
                    line-height: 20px;
                }

                div.forms {
                    label {
                        display: block;
                        height: 19px;
                        padding-top: 2px;
                        overflow: hidden;
                        @include nonSelectable();

                        span.color {
                            background: $black;
                            display: inline-block;
                            height: 13px;
                            margin: 0px 6px 0px 1px;
                            vertical-align: -2px;
                            width: 12px;
                            @include borderRadius(2px);
                        }

                        &.free {
                            color: $lightGray0;

                            span.color {
                                opacity: 0.25;
                            }
                        }
                    }
                }

                div.details {
                    div {
                        color: $gray;
                        height: 21px;
                        line-height: 21px;
                        white-space: nowrap;

                        strong {
                            color: $pink;
                        }

                        &.free,
                        &.disabled {
                            color: $lightGray1;

                            strong {
                                color: inherit;
                            }
                        }
                    }
                }
            }

            div.group.period {
                div.forms {
                    fieldset {
                        float: left;
                        width: 113px;

                        input.date {
                            font-size: 12px;
                            width: 70px;
                        }

                        input.time {
                            font-size: 12px;
                            padding-left: 4px;
                            padding-right: 0px;
                            width: 40px;
                        }
                    }

                    & > div.divider {
                        color: $lightGray0;
                        float: left;
                        height: 32px;
                        line-height: 32px;
                        text-align: center;
                        width: 20px;
                    }
                }
            }

            div.group.uses {
                padding: 26px 0px 25px 0px;

                div.label {
                    line-height: 20px;
                }

                div.forms {
                    label {
                        display: block;
                        height: 20px;
                        line-height: 20px;
                        padding: 1px 0px;
                        overflow: hidden;
                        @include nonSelectable();

                        span {
                            margin-right: 5px;
                        }

                        input {
                            float: none;
                            font-size: 12px;
                            height: 20px;
                            margin: -1px 1px 0px 1px;
                            padding: 0px;
                            text-align: center;
                            width: 35px;

                            &:disabled {
                                cursor: default;
                            }
                        }
                    }
                }
            }

            div.actions {

                button {
                    background: $lightGray0;
                    color: $white;
                    display: block;
                    float: left;
                    width: 115px;
                    @include borderRadius($borderRadius);

                    &.btn-primary {
                        background: $success;
                        font-weight: bold;
                        margin: 0px 20px 0px 250px;
                    }
                }

                .activity-indicator {
                    display: none;
                }

                .success-icon {
                    background: url('#{$domain}/img/web-v2/common/success.png') no-repeat center center;
                    background-size: 44px 44px;
                    height: 44px;
                    left: 50%;
                    margin: -22px 0px 0px -22px;
                    position: absolute;
                    top: 50%;
                    width: 44px;
                    @include transform(scale(0, 0));
                    @include transformTransition(0.25s, $bounceOut);
                }

                &.loader {
                    button {
                        display: none;
                    }

                    .activity-indicator {
                        display: block;
                    }
                }

                &.success {
                    button,
                    .activity-indicator {
                        display: none;
                    }

                    .success-icon {
                        @include transform(scale(1.0, 1.0));
                    }
                }
            }
        }
    }

/* MODAL: SHARE ----------------------------------- */

    .modal#share-modal {
        min-height: 200px;

        div.modal-header {
            background: white no-repeat top center;
            background-size: cover;
            height: 180px;
            padding: 0px;
            position: relative;

            &.centered {
                background-position: center center;
            }

            button.close {
                background-image: url('#{$domain}/img/web-v2/icons/close-white.gif');
                z-index: 2;
            }

            div {
                color: $white;
                height: 57px;
                left: 0px;
                padding: 123px 20px 0px 20px;
                position: absolute;
                text-align: center;
                top: 0px;
                width: 580px;
                z-index: 1;

                strong.title {
                    display: block;
                    font-size: 16px;
                    @include textOverflow();
                }

                span.details {
                    display: block;
                    @include textOverflow();

                    span {
                        padding: 0px 3px;
                    }
                }
            }
        }

        div.modal-body {
            height: 250px;
            margin-bottom: 70px;
            overflow: auto;
            overflow-x: hidden;
            position: relative;

            h3 {
                font-size: 14px;
                font-weight: bold;
                line-height: 18px;
                padding-top: 16px;
                text-align: center;
                @include transition(opacity, 0.1s, linear);
            }

            span.hint {
                color: $mediumGray;
                display: block;
                font-size: 11px;
                line-height: 14px;
                padding-bottom: 17px;
                text-align: center;
                @include transition(opacity, 0.1s, linear);
            }

            div.search {
                height: 30px;
                left: 448px;
                right: 18px;
                position: absolute;
                top: 18px;
                @include transition(left, 0.15s, ease-in-out);

                input {
                    height: 30px;
                    width: 100%;
                    @include borderBox();
                }

                a {
                    background: url('#{$domain}/img/web-v2/icons/remove-gray.gif') no-repeat center center;
                    background-size: 8px 8px;
                    display: none;
                    height: 30px;
                    position: absolute;
                    right: 0px;
                    top: 0px;
                    width: 30px;
                }
            }

            form {
                margin-bottom: 0px;
                overflow: hidden;
                padding-bottom: 10px;

                &.results {
                    display: none;
                    min-height: 20px;
                    position: absolute;
                    width: 100%;

                    .no-results {
                        font-size: 14px;
                        font-weight: bold;
                        height: 150px;
                        line-height: 150px;
                        text-align: center;
                    }
                }

                label {
                    cursor: pointer;
                    float: left;
                    height: 30px;
                    margin: 0px 0px 10px 10px;
                    padding: 0px;
                    width: 190px;
                    @include nonSelectable();

                    input[type="checkbox"].circular + span {
                        margin: 6px 10px 0px 10px;
                    }

                    img {
                        float: left;
                        height: 30px;
                        width: 30px;
                        @include borderRadius(15px);
                    }

                    strong {
                        display: block;
                        line-height: 30px;
                        padding-left: 10px;
                        width: 112px;
                        @include textOverflow();

                        span {
                            background: $highlighted;
                        }
                    }
                }
            }

            &.search-focused {
                h3,
                span.hint {
                    opacity: 0;
                }

                div.search {
                    left: 18px;

                    a {
                        display: block;
                    }
                }
            }
        }

        div.modal-body.no-friends {
            height: 180px;
            margin-bottom: 0px;
            text-align: center;

            h3 {
                margin: 42px 0px 11px 0px;
            }

            a.btn-primary {
                width: 210px;
            }
        }

        div.modal-footer {
            background: $white;
            bottom: 0px;
            font-size: 13px;
            font-weight: bold;
            height: 70px;
            left: 0px;
            padding: 0px;
            position: absolute;
            text-align: center;
            width: 620px;
            z-index: 1;
            @include boxShadow(0px, -4px, 10px, rgba(0, 0, 0, 0.15));

            a {
                margin-top: 20px;
                width: 210px;

                &.all {
                    margin-left: 20px;
                }
            }
        }

        div.success {
            background: url('#{$domain}/img/web-v2/common/success-big.png');
            background-size: 100px 100px;
            height: 100px;
            left: 50%;
            margin: -50px 0px 0px -50px;
            position: absolute;
            top: 50%;
            width: 100px;
        }
    }

/* OVERLAY: COOKIE POLICY ------------------------- */

    div#cookie-policy-overlay {
        background: $darkGray;
        border-top: 1px solid #333;
        bottom: 0px;
        color: $mediumGray;
        display: none;
        height: 30px;
        left: 0px;
        line-height: 30px;
        position: fixed;
        text-align: center;
        width: 100%;
        z-index: 1040;

        a.btn {
            float: right;
            margin-top: 4px;
            padding: 2px 5px 1px 5px;
            @include borderRadius(2px);
        }
    }

/* OVERLAY: INSTAGRAM ----------------------------- */

    $instagram-grid-columns: 4;
    $instagram-grid-gutter: 8px;
    $instagram-grid-item-size: 200px;

    .overlay-template.instagram {
        &::-webkit-scrollbar {
            display: none;
        }

        &.disable-scrolling {
            overflow: hidden;
        }

        div.body {
            width: $instagram-grid-columns * ($instagram-grid-item-size + $instagram-grid-gutter) + $instagram-grid-gutter;

            div.header {
                height: 292px;
                text-align: center;
                width: inherit;

                div.instagram {
                    background: url('#{$domain}/img/web-v2/common/instagram.svg') no-repeat;
                    background-position: 0px 59px;
                    background-size: contain;
                    height: 150px;
                    margin: 0px auto;
                    position: relative;
                    width: 240px;

                    &:after {
                        background: $mediumGray;
                        content: '';
                        display: block;
                        height: 1px;
                        left: 50%;
                        margin-left: -20px;
                        position: absolute;
                        top: 100%;
                        width: 40px;
                    }
                }

                strong {
                    color: $lightGray0;
                    display: block;
                    font-size: 10px;
                    font-weight: bold;
                    letter-spacing: 2px;
                    margin-top: 33px;
                    text-transform: uppercase;
                }

                a {
                    color: $white;
                    display: inline-block;
                    font-family: $sansSerifLight;
                    font-size: 32px;
                    margin-top: 3px;
                    text-decoration: none;

                    &.user {
                        &:hover {
                            color: $instagram;
                        }
                    }

                    &.hash {
                        padding-right: 10px;
                    }
                }
            }

            div.grid {
                position: relative;
                width: inherit;

                img.item {
                    cursor: pointer;
                    height: $instagram-grid-item-size;
                    left: 0px;
                    position: absolute;
                    top: 0px;
                    width: $instagram-grid-item-size;

                    &.invisible {
                        visibility: hidden;
                    }
                }
            }

            div.preview-cover {
                background-color: rgba(0, 0, 0, 0);
                display: none;
                bottom: 0px;
                left: 0px;
                position: fixed;
                right: 0px;
                top: 0px;
                z-index: 4;
                @include transition(background-color, 0.25s, ease);

                &.shown {
                    background-color: rgba(0, 0, 0, 0.85);
                }
            }

            div.preview {
                display: none;
                height: 540px;
                left: 50%;
                margin: -270px 0px 0px -270px;
                position: fixed;
                top: 50%;
                width: 540px;
                z-index: 5;

                &.transitions {
                    @include transformTransition(0.3s, ease);
                }

                &.no-transform {
                    @include transformNone();
                }

                div.details {
                    background: $white;
                    color: $gray;
                    height: inherit;
                    position: absolute;
                    right: 0px;
                    top: 0px;
                    width: 280px;
                    z-index: 1;
                    @include transformTransition(0.3s, ease);

                    div.mask {
                        border-bottom: 1px solid $extraLightGray1;
                        height: 480px;
                        line-height: 18px;
                        overflow: auto;
                        padding: 0px 20px;
                        width: inherit;
                        @include borderBox();

                        a.user {
                            color: $instagram;
                            display: block;
                            font-size: 16px;
                            font-weight: bold;
                            padding-top: 15px;
                            width: 100%;
                            @include textOverflow();
                        }

                        div.date {
                            border-bottom: 1px solid $extraLightGray1;
                            color: $mediumGray;
                            padding: 1px 0px 15px 0px;
                        }

                        div.likes,
                        div.comments {
                            float: left;
                            line-height: 24px;
                            padding: 3px 15px 3px 0px;

                            svg {
                                fill: $lightGray0;
                                height: 15px;
                                margin-right: 5px;
                                vertical-align: -3px;
                                width: 15px;
                            }
                        }

                        div.caption {
                            border-top: 1px solid $extraLightGray1;
                            padding: 15px 0px;
                        }
                    }

                    a.btn.btn-primary {
                        background: $instagram;
                        font-size: 14px;
                        font-weight: bold;
                        height: 40px;
                        line-height: 40px;
                        margin: 10px 0px 0px 10px;
                        padding: 0px;
                        width: 260px;
                        @include borderRadius($borderRadius);
                    }
                }

                a.previous,
                a.next {
                    background: url('#{$domain}/img/web-v2/icons/arrow-left-white.gif') center center no-repeat;
                    background-size: 11px 11px;
                    display: none;
                    height: 540px;
                    left: -60px;
                    margin-top: -270px;
                    position: absolute;
                    top: 50%;
                    width: 60px;
                    z-index: 3;
                    @include transition(background-color, 0.2s, ease);

                    &:hover {
                        background-color: $gray;
                    }
                }

                a.next {
                    background-image: url('#{$domain}/img/web-v2/icons/arrow-right-white.gif');
                    left: 820px;
                }

                img {
                    height: 100%;
                    left: 0px;
                    position: absolute;
                    top: 0px;
                    width: 100%;
                    z-index: 2;
                }

                &.show-details {
                    @include transformImportant(translate(-140px, 0px));

                    div.details {
                        @include transform(translate(280px, 0px));
                    }
                }

                &.visible-navigation {
                    background: $white;

                    a.previous,
                    a.next {
                        display: block;
                    }
                }
            }

            div.load-more {
                height: 200px;
                position: relative;

                div.end {
                    display: none;
                    font-size: 30px;
                    height: 300px;
                    line-height: 300px;
                    text-align: center;

                    img {
                        height: 100px;
                        width: 290px;
                    }
                }

                &.the-end {
                    div.activity-indicator {
                        display: none;
                    }

                    div.end {
                        display: block;
                    }
                }
            }
        }
    }
}

@media (-webkit-min-device-pixel-ratio: 2), (min--moz-device-pixel-ratio: 2), (-o-min-device-pixel-ratio: 2), (min-device-pixel-ratio: 2) {
    .modal#buy-tickets-modal {
        div.modal-header {
            button.back {
                background-image: url('#{$domain}/img/web-v2/icons/arrow-left-white@2x.gif');
            }

            button.close {
                background-image: url('#{$domain}/img/web-v2/icons/close-white@2x.gif');
            }
        }
    }

    .modal#buy-tickets-modal .slide.complete {
        div.wrapper {
            .slide.required-information {
                div.mask {
                    form {
                        .category {
                            fieldset {
                                a.autofill {
                                    background-image: url('#{$domain}/img/web-v2/icons/autofill-blue@2x.png');
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .modal#buy-tickets-modal .slide.finish {
        div.text {
            div.success {
                background-image: url('#{$domain}/img/web-v2/common/success@2x.png');
            }
        }

        div.redirect {
            background-image: url('#{$domain}/img/web-v2/icons/redirect@2x.png');
        }
    }

    .modal#change-language-modal {
        div.modal-body {
            a {
                &:hover {
                    &:after {
                        background-image: url('#{$domain}/img/web-v2/icons/arrow-right-gray@2x.gif');
                    }
                }

                &.selected {
                    &:after {
                        background-image: url('#{$domain}/img/web-v2/common/tick-pink@2x.jpg');
                    }
                }
            }
        }
    }

    .modal#manage-discount-codes-modal {
        div.modal-header {
            button.back {
                background-image: url('#{$domain}/img/web-v2/icons/arrow-left-white@2x.gif');
            }

            button.close {
                background-image: url('#{$domain}/img/web-v2/icons/close-white@2x.gif');
            }
        }
    }

    .modal#embed-tickets-modal {
        div.modal-body {
            div.column {
                div.layout {
                    background-image: url('#{$domain}/img/web-v2/event/embed-tickets/layouts@2x.png');
                }
            }
        }
    }

    .modal#feature-modal {
        div.campaign-panel {
            button.close {
                background-image: url('#{$domain}/img/web-v2/icons/close-white@2x.gif');
            }

            div.modal-footer {
                a:first-child {
                    &:after {
                        background-image: url('#{$domain}/img/web-v2/icons/arrow-left-gray@2x.gif');
                    }
                }

                a:last-child {
                    &:after {
                        background-image: url('#{$domain}/img/web-v2/icons/arrow-right-gray@2x.gif');
                    }
                }
            }
        }
    }

    .modal#feature-modal .slide.setup {
        form {
            div.campaign-positions {
                .positions-list {
                    li {
                        a {
                            background-image: url('#{$domain}/img/web-v2/icons/trash-small-white@2x.gif');
                        }

                        &.active {
                            &:after {
                                background-image: url('#{$domain}/img/web-v2/common/feature-modal-arrow@2x.png');
                            }
                        }
                    }
                }
            }
        }
    }

    .modal#feature-modal .slide.finish {
        h1 {
            background-image: url('#{$domain}/img/web-v2/common/success-big@2x.png');
        }
    }

    .modal#featured-report-modal {
        .modal-content {
            background-color: white;
        }
        .modal-header {
            form {
                a {
                    background-image: url('#{$domain}/img/web-v2/icons/remove-gray@2x.gif');
                }
            }
        }

        .modal-body {
            ul {
                li {
                    div.toggle {
                        .arrow {
                            background-image: url('#{$domain}/img/web-v2/icons/arrow-right-gray@2x.gif');
                        }
                    }
                }
            }
        }
    }

    .modal#import-event-modal {
        div.modal-body {
            background-image: url('#{$domain}/img/web-v2/common/import-facebook-event@2x.gif');
        }
    }

    .modal#manage-discount-codes-modal {
        .slide.manage {
            div#multi,
            div#single {
                ul {
                    li {
                        div.code {
                            div.arrow {
                                background-image: url('#{$domain}/img/web-v2/icons/arrow-right-gray@2x.gif');
                            }
                        }
                    }
                }
            }
        }

        .slide.create {
            form {
                div.group.codes {
                    div.forms {
                        fieldset {
                            a {
                                &:after {
                                    background-image: url('#{$domain}/img/web-v2/icons/remove-medium-white@2x.gif');
                                }

                                &.add {
                                    &:after {
                                        background-image: url('#{$domain}/img/web-v2/icons/add-white@2x.gif');
                                    }
                                }
                            }
                        }
                    }
                }

                div.actions {
                    .success-icon {
                        background-image: url('#{$domain}/img/web-v2/common/success@2x.png');
                    }
                }
            }
        }
    }

    .modal#share-modal {
        div.modal-header {
            button.close {
                background-image: url('#{$domain}/img/web-v2/icons/close-white@2x.gif');
            }
        }

        div.modal-body {
            div.search {
                a {
                    background-image: url('#{$domain}/img/web-v2/icons/remove-gray@2x.gif');
                }
            }
        }

        div.success {
            background-image: url('#{$domain}/img/web-v2/common/success-big@2x.png');
        }
    }

    .sidebar-widget#featured-widget {
        .cool {
            background-image: url('#{$domain}/img/web-v2/common/featured-widget-cool@2x.png');
        }

        .overlay {
            background-image: url('#{$domain}/img/web-v2/common/featured-widget-gradient@2x.png')
        }
    }

    .sidebar-widget#copromote-request-widget {
        div.body {
            &.success {
                strong {
                    background-image: url('#{$domain}/img/web-v2/common/success@2x.png');
                }
            }
        }
    }

    .overlay-template.instagram {
        div.body {
            div.preview {
                a.previous {
                    background-image: url('#{$domain}/img/web-v2/icons/arrow-left-white@2x.gif');
                }

                a.next {
                    background-image: url('#{$domain}/img/web-v2/icons/arrow-right-white@2x.gif');
                }
            }
        }
    }
}
