/* FOOTER ----------------------------------------- */

    // a#to-the-top {
    //     background: rgba(0, 0, 0, 0.2) url('/img/web-v2/icons/arrow-top-white.gif') center center no-repeat;
    //     background-size: 11px 11px;
    //     bottom: 60px;
    //     display: block;
    //     height: 30px;
    //     position: fixed;
    //     right: -30px;
    //     width: 30px;
    //     z-index: 1040;

    //     -webkit-transition: right 0.3s ease-in-out;
    //        -moz-transition: right 0.3s ease-in-out;
    //          -o-transition: right 0.3s ease-in-out;
    //             transition: right 0.3s ease-in-out;
    // }

    // a#to-the-top:hover {
    //     background-color: rgba(0, 0, 0, 0.4);
    // }


    #footer {
        margin-top: 60px;
    }

    footer nav {
        background: #e6e6e6;
        border-top: 1px solid #d7d7d7;
        z-index: 1030;
        line-height: 50px;

        button.collapsed {
            margin-right:0px;
        }

        .navbar-brand {
            font-size: inherit;
            color: #8a94a5;
        }

        a {
            color: #63676d;
        }
    }

    body.cpo a#to-the-top {
        bottom: 91px;
    }

    body.cpo div.footer-fix {
        height: 81px;
    }

    body.cpo footer {
        bottom: 31px;
    }
