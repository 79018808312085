@media all {

/* PAGE: EXCHANGE --------------------------------- */
	.field-error {
        border: 1px solid #ff355b !important;
        box-shadow: 0px 0px 2px #ff355b!important;
    }

    .exchange-error-container {
    	margin-top: 20px;
    	margin-bottom: 10px;
    	display: none;
    }
    
    .exchange-input-group-addon {
    	max-width:15%;
    	width:15%;
    	line-height: 40px;
    }
    
    #send_amount {
    	max-width:35%;
    }
    
    #receive_amount {
    	max-width:35%;
    }

    .exchange-from-container {
    	margin-bottom:10px;
    }
    
    .exchange-to-container {
    	margin-bottom:10px;
    }
    
    .exchange-wallet-container {
    	margin-bottom:10px;
    }
    
    .exchange-switch-container {
    	margin-bottom:10px;
    }
    
    .loading-spinner {
	    vertical-align: middle;
    }
}
