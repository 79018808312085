div.statistics {
  padding-top: 40px;

  div.summary {
    hgroup {
      h1 {
        color: #444;
        font-size: 20px;
        line-height: 24px;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      h2 {
        color: #888;
        font-size: 14px;
        line-height: 18px;
      }
    }
    a {
      display: inline-block;
    }
    aside.widgets section.manage-event-widget {
      margin-bottom: 20px !important;
    }
  }
  div.stats {
    div.item {
      color: #888;
      text-align: center;
      line-height: 24px;
      padding-top: 30px;

      span {
        color: #444;
        display: block;
        font-size: 30px;
      }
    }
  }
}

/* PAGE: STATISTICS ------------------------------- */

  div.statistics {
    padding-top: 40px;
  }

  div.statistics div.summary {
    margin-right: 20px;
  }

  div.statistics div.summary hgroup {
    height: 66px;
    padding-left: 20px;
  }

  div.statistics div.summary hgroup h1 {
    color: #444;
    font-size: 20px;
    line-height: 24px;
    overflow: hidden;
    padding: 11px 20px 0px 0px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  div.statistics div.summary hgroup h2 {
    color: #888;
    font-size: 14px;
    line-height: 18px;
    padding: 2px 20px 0px 0px;
  }

  div.statistics div.summary a {
    display: inline-block;
    margin: 3px 0px 0px 20px;
  }

  div.statistics aside.widgets section.manage-event-widget {
    margin-bottom: 20px !important;
  }

  div.statistics div.stats {
    padding: 20px 0px 10px 20px;
  }

  div.statistics div.stats div#chart {
    margin: 0px 20px 20px 0px;
  }

  div.statistics div.stats div.item {
    border-top: 1px solid #f0f0f0;
    color: #888;
    float: left;
    line-height: 24px;
    margin-right: 20px;
    padding: 19px 10px 12px 10px;
    width: 200px;
  }

  div.statistics div.stats div.item span {
    background: white;
    color: #444;
    display: block;
    font-size: 30px;
  }

  div.statistics div.stats div.item span.marker-green {
    color: #4fb260;
  }

  div.statistics div.stats div.item span.marker-yellow {
    color: #fdad17;
  }

  div.statistics div.stats div.item span.marker-pink {
    color: #ce5aa4;
  }

  div.statistics div.stats div.item span.marker-blue {
    color: #556db9;
  }
