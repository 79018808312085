@media all {

/* PAGE: TICKETS EDITOR --------------------------- */


    legend {
        font-family: $sansSerif;
        font-size: 11px;
        font-weight: bold;
        text-transform: uppercase;
        width: 100%;
    }

    .tickets-editor {

        div.headings {

            padding: 34px 0px 19px 0px;

            &:nth-child(1) {
                border-top: 1px solid $extraLightGray1;
            }

            &:nth-child(2) {
                display: none;
            }

            h2 {
                font-family: $sansSerifLight;
                font-size: 24px;
                line-height: 30px;
                margin: 0px;
                padding: 0px 0px 3px 0px;
                text-align: center;
            }

            p {
                color: $mediumGray;
                font-size: 16px;
                line-height: 25px;
                margin: 0px;
                padding: 0px 0px 14px 0px;
                text-align: center;
            }
        }

        div.settings {
            display: none;
            margin: 0px auto;
            padding-bottom: 40px;

            fieldset.currency {
                padding-bottom: 20px;

                select {
                    font-size: 14px;
                    height: 40px;
                    padding-left: 8px;
                }
            }

            .tax {
                padding-bottom: 20px;
            }

            .restrictions {
                label {
                    width: 100%;
                    display: block;
                }
            }

            label + label {
                padding-top: 8px;
            }

            label.disabled {
                cursor: not-allowed;
            }
        }

        .categories {
            list-style-type: none;
            margin: 0px;

            & > div.card {
                background: $white;
                padding-left: 6px;
                position: relative;

                @include borderRadius($borderRadius);
                @include boxShadow(0px, 1px, 3px, rgba(0, 0, 0, 0.12));
                @include transformTransition(0.4s, ease);

                &.hidden-right {
                    @include transform(translateX(690px));
                }

                .color {
                    background: $mediumGray;
                    bottom: 0px;
                    left: 0px;
                    position: absolute;
                    top: 0px;
                    width: 6px;
                    @include borderRadius($borderRadius 0px 0px $borderRadius);

                    &:hover {
                        @include insetBoxShadow(100px, 0px, 0px, rgba(0, 0, 0, 0.25));
                    }

                    &.disabled {
                        &:hover {
                            @include boxShadowNone();
                        }
                    }
                }

                fieldset.name,
                fieldset.quantity,
                fieldset.price,
                fieldset.active,
                fieldset.delete {
                    border-left: 1px solid $extraLightGray1;
                    height: 70px;

                    legend {
                        text-align: left;
                        @include transition(color, 0.1s, linear);

                        &.light {
                            color: $lightGray0;
                        }
                    }

                    input {
                        border: none;
                        font-size: 20px;
                        height: 30px;
                        line-height: 30px;
                        padding: 0px;
                        width: 50px;
                    }

                    span.label {
                        font-size: 20px;
                        line-height: 30px;
                    }
                }

                fieldset.name {
                    border-left: none;

                    input {
                    }
                }

                fieldset.quantity {
                    width: 90px;

                    input {
                        width: 80px;
                    }

                    &.wide {
                        width: 141px;

                        input {
                            width: 131px;
                        }
                    }
                }

                fieldset.price {
                    width: 90px;

                    input {
                        width: 80px;
                    }
                }

                fieldset.active {
                    height: 47px;
                    width: 75px;

                    label.new-switch {
                        padding: 0px;
                    }
                }

                fieldset.delete {
                    text-align: center;
                    padding-top: 24px;
                    width: 50px;

                    a {

                        &:hover {
                            background-color: #fbfbfb;
                        }
                    }
                }

                .options {
                    @include insetBoxShadow(0px, 3px, 2px, $lightGray2, -2px);

                    fieldset {

                        legend {
                            text-align: left;
                        }
                    }

                    fieldset.sales-period {
                        border-bottom: 1px solid $extraLightGray1;

                        legend {
                            a.hint {
                                margin-left: 5px;
                            }
                        }

                        input {
                            &.rounded.left {
                                border-right: 0px;
                            }

                            &.rounded.right {
                                border-top: 1px solid $lightGray1 !important;
                            }
                        }

                        &.start {
                            // margin-left: 20px;
                        }

                        &.end {
                            // padding-left: 20px;
                        }
                    }

                    fieldset.description {

                        input {

                        }

                        &.wide {
                            input {

                            }
                        }
                    }

                    fieldset.limit {

                        select {
                        }
                    }

                    fieldset.required-information {
                        border-top: 1px solid $extraLightGray1;

                        ul.token-list {
                            padding: 7px;

                            &:last-child {
                                padding-bottom: 20px;
                            }
                        }

                        select {
                            margin-bottom: 20px;
                        }
                    }
                }

                .toggle-options {
                    border-top: 1px solid $extraLightGray1;
                    display: block;
                    // height: 29px;
                    line-height: 28px;
                    text-align: center;
                    text-decoration: none;

                    span {
                        background: url('#{$domain}/img/web-v2/common/expand-collapse.gif') no-repeat bottom right;
                        background-size: 8px 60px;
                        padding: 0px 15px;
                    }

                    span:first-child {
                        display: none;
                    }

                    span:last-child {
                        display: inline-block;
                    }
                }

                .toggle-options.toggled {
                    span {
                        background-position: top right;
                    }

                    span:first-child {
                        display: inline-block;
                    }

                    span:last-child {
                        display: none;
                    }
                }

                a.locked-hint {
                    background: url('#{$domain}/img/web-v2/icons/locked-gray.png');
                    background-size: 12px 14px;
                    display: block;
                    height: 14px;
                    position: absolute;
                    margin-top: -8px;
                    right: -31px;
                    top: 50%;
                    width: 12px;
                }
            }
        }

        div.terms {
            color: $mediumGray;
            display: none;
            height: 70px;
            line-height: 18px;
            margin-bottom: 10px;
            padding-top: 16px;
            text-align: center;
            @include borderBox();
        }

        div.buttons {
            height: 80px;

            a {
                background: $success url('#{$domain}/img/web-v2/icons/add-white.gif') no-repeat 14px 14px;
                background-size: 12px 12px;
                color: $white;
                display: inline-block;
                font-size: 16px;
                font-weight: bold;
                margin: 5px 10px;
                text-align: center;
                text-decoration: none;
                text-indent: 10px;
                padding: 10px 10px 10px 30px;
                @include borderRadius($borderRadius);

                &.disabled {
                    background-color: $lightGray0;
                    cursor: default;
                }

                &.loading {
                    background-image: none;
                }

                &:hover {
                    @include boxShadows(e('inset 0px -1px 0px rgba(0, 0, 0, 0.08), inset 0px 100px 0px rgba(0, 0, 0, 0.04)'));
                }
            }

            a {
                text-indent: 0px;
            }

            & > div {
                background: $lightGray3;
                color: $lightGray0;
                display: none;
                font-size: 14px;
                height: 40px;
                line-height: 40px;
                text-align: center;
                text-shadow: 0px 1px 0px rgba(255, 255, 255, 0.5);
                width: 630px;
                @include borderRadius($borderRadius);
            }

            &.limit-reached {
                padding-left: 50px;

                a {
                    display: none;
                }

                div {
                    display: block;
                }
            }
        }

        &.added-categories {
            background: $extraLightGray2;
            padding-top: 1px;
            @include insetBoxShadow(0px, 3px, 2px, $lightGray2, -2px);

            div.headings {
                &:nth-child(1) {
                    display: none;
                }

                &:nth-child(2) {
                    display: block;
                }
            }

            div.settings,
            ul.categories,
            div.terms {
                display: block;
            }
        }
    }
}

@media (-webkit-min-device-pixel-ratio: 2), (min--moz-device-pixel-ratio: 2), (-o-min-device-pixel-ratio: 2), (min-device-pixel-ratio: 2) {
    .tickets-editor {
        ul.categories {
            & > li {
                fieldset.delete {
                    a {
                        // background-image: url('#{$domain}/img/web-v2/icons/trash-gray@2x.gif');
                    }
                }

                a.toggle-options {
                    span {
                        background-image: url('#{$domain}/img/web-v2/common/expand-collapse@2x.gif');
                    }
                }

                a.locked-hint {
                    background-image: url('#{$domain}/img/web-v2/icons/locked-gray@2x.png');
                }
            }
        }

        div.buttons {
            a {
                background-image: url('#{$domain}/img/web-v2/icons/add-white@2x.gif');
            }
        }
    }
}
