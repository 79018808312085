@import './definitions';

@media all {
    
    .breadcrumb-item {
      &a {
        color: $blue;
      }
      span{
        color: #bbb;
          font-style: italic;
        
        &.active {
          font-weight: bold;
          color: $pink;
        }
      }
    }

    .hidden { display: none !important;  }
    * {
        outline: none !important;
    }

    article,
    aside,
    details,
    figcaption,
    figure,
    footer,
    header,
    hgroup,
    nav,
    section {
      display: block;
    }

    time {
      display: inline;
    }

    audio,
    canvas,
    video {
      display: inline-block;
      *display: inline;
      *zoom: 1;
    }

    audio:not([controls]) {
      display: none;
    }

    html {
      position: relative;
      min-height: 100%;
      font-size: 100%;
      -webkit-text-size-adjust: 100%;
          -ms-text-size-adjust: 100%;
    }

    a:focus {
      outline: thin dotted #333;
      outline: 5px auto -webkit-focus-ring-color;
      outline-offset: -2px;
    }

    a:hover,
    a:active {
      outline: 0;
    }

    sub,
    sup {
      position: relative;
      font-size: 75%;
      line-height: 0;
      vertical-align: baseline;
    }

    sup {
      top: -0.5em;
    }

    sub {
      bottom: -0.25em;
    }

    img {
      max-width: 100%;
      vertical-align: middle;
      border: 0;
      -ms-interpolation-mode: bicubic;
    }

    #map_canvas img {
      max-width: none;
    }

    button,
    input,
    select,
    textarea {
      margin: 0;
      font-size: 100%;
      vertical-align: middle;
    }

    button,
    input {
      *overflow: visible;
      line-height: normal;
    }

    button::-moz-focus-inner,
    input::-moz-focus-inner {
      padding: 0;
      border: 0;
    }

    button,
    input[type="button"],
    input[type="reset"],
    input[type="submit"] {
      cursor: pointer;
      -webkit-appearance: button;
    }

    input[type="search"] {
      -webkit-box-sizing: content-box;
         -moz-box-sizing: content-box;
              box-sizing: content-box;
      -webkit-appearance: textfield;
    }

    input[type="search"]::-webkit-search-decoration,
    input[type="search"]::-webkit-search-cancel-button {
      -webkit-appearance: none;
    }

    textarea {
      overflow: auto;
      vertical-align: top;
      resize: vertical;
    }

    .clearfix {
      *zoom: 1;
    }

    .clearfix:before,
    .clearfix:after {
      display: table;
      content: "";
    }

    .clearfix:after {
      clear: both;
    }

    .hide-text {
      font: 0/0 a;
      color: transparent;
      text-shadow: none;
      background-color: transparent;
      border: 0;
    }

    .input-block-level {
      display: block;
      width: 100%;
      min-height: 28px;
      -webkit-box-sizing: border-box;
         -moz-box-sizing: border-box;
          -ms-box-sizing: border-box;
              box-sizing: border-box;
    }


    .pre-scrollable {
      max-height: 340px;
      overflow-y: scroll;
    }

    form {
      margin: 0 0 18px;
    }

    fieldset {
      padding: 0;
      margin: 0;
      border: 0;
    }

    label,
    input,
    button,
    select,
    textarea {
      font-size: 12px;
      font-weight: normal;
      line-height: 18px;
    }

    input,
    button,
    select,
    textarea {
      font-family: "HelveticaNeue", "Helvetica", Arial, sans-serif;
    }

    label {
      display: block;
    }

    select,
    textarea,
    input[type="text"],
    input[type="password"],
    input[type="datetime"],
    input[type="datetime-local"],
    input[type="date"],
    input[type="month"],
    input[type="time"],
    input[type="week"],
    input[type="number"],
    input[type="email"],
    input[type="url"],
    input[type="search"],
    input[type="tel"],
    input[type="color"],
    .uneditable-input {

    }

    // input,
    // textarea {
    //   width: 210px;
    // }

    textarea {
      height: auto;
    }

    textarea,
    input[type="text"],
    input[type="password"],
    input[type="datetime"],
    input[type="datetime-local"],
    input[type="date"],
    input[type="month"],
    input[type="time"],
    input[type="week"],
    input[type="number"],
    input[type="email"],
    input[type="url"],
    input[type="search"],
    input[type="tel"],
    input[type="color"],
    .uneditable-input {
      background-color: #ffffff;
      border: 1px solid #d2d2d2;
    }

    textarea:focus,
    input[type="text"]:focus,
    input[type="password"]:focus,
    input[type="datetime"]:focus,
    input[type="datetime-local"]:focus,
    input[type="date"]:focus,
    input[type="month"]:focus,
    input[type="time"]:focus,
    input[type="week"]:focus,
    input[type="number"]:focus,
    input[type="email"]:focus,
    input[type="url"]:focus,
    input[type="search"]:focus,
    input[type="tel"]:focus,
    input[type="color"]:focus,
    .uneditable-input:focus {
      outline: 0;
      outline: thin dotted \9;
    }

    input[type="radio"],
    input[type="checkbox"] {
      margin: 3px 0;
      *margin-top: 0;
      /* IE7 */

      line-height: normal;
      cursor: pointer;
    }

    input[type="submit"],
    input[type="reset"],
    input[type="button"],
    input[type="radio"],
    input[type="checkbox"] {
      width: auto;
    }

    .uneditable-textarea {
      width: auto;
      height: auto;
    }

    // select,
    input[type="file"] {
      height: 28px;
      /* In IE7, the height of the select element cannot be changed by height, only font-size */

      *margin-top: 4px;
      /* For IE7, add top margin to align select with labels */

      line-height: 28px;
    }

    select {
      width: 220px;
      border: 1px solid #d2d2d2;
    }

    select[multiple],
    select[size] {
      height: auto;
    }

    select:focus,
    input[type="file"]:focus,
    input[type="radio"]:focus,
    input[type="checkbox"]:focus {
      outline: 0;
      outline: thin dotted \9;
    }

    .radio,
    .checkbox {
      min-height: 18px;
      padding-left: 18px;
    }

    .radio input[type="radio"],
    .checkbox input[type="checkbox"] {
      float: left;
      margin-left: -18px;
    }

    input[disabled],
    select[disabled],
    textarea[disabled],
    input[readonly],
    select[readonly],
    textarea[readonly] {
      cursor: not-allowed;
      background-color: #f0f0f0;
      border-color: #d2d2d2;
      color: #444;
    }

    input[type="radio"][disabled],
    input[type="checkbox"][disabled],
    input[type="radio"][readonly],
    input[type="checkbox"][readonly] {
      background-color: transparent;
    }

    .uneditable-input {
      overflow: hidden;
      white-space: nowrap;
      cursor: not-allowed;
      background-color: #e2e2e2;
      border-color: #d2d2d2;
    }

    table {
      max-width: 100%;
      background-color: transparent;
      border-collapse: collapse;
      border-spacing: 0;
    }

    .table {
      width: 100%;
      margin-bottom: 18px;
    }

    .table th,
    .table td {
      padding: 8px;
      line-height: 18px;
      text-align: left;
      vertical-align: top;
      border-top: 1px solid #d2d2d2;
    }

    .table th {
      font-weight: bold;
    }

    .table thead th {
      vertical-align: bottom;
    }

    .table caption + thead tr:first-child th,
    .table caption + thead tr:first-child td,
    .table colgroup + thead tr:first-child th,
    .table colgroup + thead tr:first-child td,
    .table thead:first-child tr:first-child th,
    .table thead:first-child tr:first-child td {
      border-top: 0;
    }

    .table tbody + tbody {
      border-top: 2px solid #d2d2d2;
    }

    .table-condensed th,
    .table-condensed td {
      padding: 4px 5px;
    }

    .table-bordered {
      border: 1px solid #d2d2d2;
      border-collapse: separate;
      *border-collapse: collapsed;
      border-left: 0;
    }

    .table-bordered th,
    .table-bordered td {
      border-left: 1px solid #d2d2d2;
    }

    .table-bordered caption + thead tr:first-child th,
    .table-bordered caption + tbody tr:first-child th,
    .table-bordered caption + tbody tr:first-child td,
    .table-bordered colgroup + thead tr:first-child th,
    .table-bordered colgroup + tbody tr:first-child th,
    .table-bordered colgroup + tbody tr:first-child td,
    .table-bordered thead:first-child tr:first-child th,
    .table-bordered tbody:first-child tr:first-child th,
    .table-bordered tbody:first-child tr:first-child td {
      border-top: 0;
    }

    .table-striped tbody tr:nth-child(odd) td,
    .table-striped tbody tr:nth-child(odd) th {
      background-color: #f8f8f8;
    }

    .table tbody tr:hover td,
    .table tbody tr:hover th {

    }

    .dropdown-toggle {
      *margin-bottom: -3px;
    }

    .dropdown-toggle:active,
    .open .dropdown-toggle {
      outline: 0;
    }

    .caret {
      display: inline-block;
      width: 0;
      height: 0;
      vertical-align: top;
      border-top: 4px solid #d2d2d2;
      border-right: 4px solid transparent;
      border-left: 4px solid transparent;
      content: "";
    }

    .dropdown .caret {
      margin-top: 8px;
      margin-left: 2px;
    }

    .dropdown:hover .caret,
    .open .caret {
      border-top: 4px solid #888;
    }

    .borders {
      border: 1px solid #d2d2d2;

      -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
         -moz-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
              box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
    }

    h1.h1-new,
    h2.h2-new,
    h3.h3-new {
        font-family: $sansSerif;
    }

    .noise {
        background-image: url(#{$domain}/img/web-v2/common/noise.png);
    }

    .sticky {
        position: -webkit-sticky;
        position: -moz-sticky;
        position: -ms-sticky;
        position: -o-sticky;
        position: sticky;
        top: 0px;
    }

    a.hint {
        background: $lightGray1 url(#{$domain}/img/web-v2/common/hint-question.png);
        background-size: 13px 13px;
        display: inline-block;
        height: 13px;
        vertical-align: -2px;
        width: 13px;
        @include borderRadius(6px);

        &:hover {
            background-color: $blue;
        }
    }

    .paypal-button {
        background: url(#{$domain}/img/web-v2/common/paypal-button.png);
        background-size: 106px 26px;
        display: inline-block;
        height: 26px;
        width: 106px;
    }

    .btn.rounded {
        @include borderRadius(2px);

        &.bottom {
            @include borderRadius(0px 0px 2px 2px);
        }

        &.left {
            @include borderRadius(2px 0px 0px 2px);
        }

        &.right {
            @include borderRadius(0px 2px 2px 0px);
        }

        &.top {
            @include borderRadius(2px 2px 0px 0px);
        }
    }

/* ACTIVITY INDICATOR ----------------------------- */

    .activity-indicator {
        background-image: url(#{$domain}/img/web-v2/common/activity-indicator.png);
        background-size: 20px 20px;
        height: 20px;
        left: 50%;
        margin: -30px 0px 0px -10px;
        position: absolute;
        top: 100%;
        width: 20px;
        @include animation(activity, 0.5s, steps(12), infinite);

        &.gray {
            background-image: url(#{$domain}/img/web-v2/common/activity-indicator-gray.png);
        }
    }

/* AUTOCOMPLETE ----------------------------------- */

    ul.ui-autocomplete.autocomplete-new {
        border: none;
        max-height: 120px;
        padding: 2px;

        @include borderRadius(2px);
        @include boxShadow(0px, 2px, 10px, rgba(0, 0, 0, 0.25));

        li {
            display: block;

            a {
                color: $gray;
                height: 24px;
                line-height: 24px;

                &.ui-state-focus {
                    @include borderRadius(2px);
                }
            }

            &.autocomplete-image-item {
                width: 100%;

                a {
                    height: 22px;
                    line-height: 22px;
                    padding: 4px 10px 4px 4px;

                    img {
                        height: 22px;
                        margin: 0px 10px 0px 0px;
                        width: 22px;
                    }
                }
            }
        }
    }

/* CATEGORIES LIST -------------------------------- */

    .categories-list {

        label {
            color: $mediumGray;
            cursor: pointer;
            line-height: 10px;
            padding: 51px 1px 0px 0px !important;
            margin-bottom: 2px;
            text-align: center;

            &:hover {
                color: $mediumGray !important;
            }

            &.c1  { background-position: 0px -70px; }
            &.c2  { background-position: -90px -70px; }
            &.c3  { background-position: -180px -70px; }
            &.c4  { background-position: -270px -70px; }
            &.c5  { background-position: -360px -70px; }
            &.c6  { background-position: -450px -70px; }
            &.c7  { background-position: -540px -70px; }
            &.c8  { background-position: -630px -70px; }
            &.c9  { background-position: -720px -70px; }
            &.c10 { background-position: -810px -70px; }
            &.c11 { background-position: -900px -70px; }
            &.c12 { background-position: -990px -70px; }
            &.c13 { background-position: -1080px -70px; }
            &.c14 { background-position: -1170px -70px; }
            &.c15 { background-position: -1260px -70px; }

            &.unchecked {
                color: $lightGray1;

                &.c1  { background-position: 0px 0px; }
                &.c2  { background-position: -90px 0px; }
                &.c3  { background-position: -180px 0px; }
                &.c4  { background-position: -270px 0px; }
                &.c5  { background-position: -360px 0px; }
                &.c6  { background-position: -450px 0px; }
                &.c7  { background-position: -540px 0px; }
                &.c8  { background-position: -630px 0px; }
                &.c9  { background-position: -720px 0px; }
                &.c10 { background-position: -810px 0px; }
                &.c11 { background-position: -900px 0px; }
                &.c12 { background-position: -990px 0px; }
                &.c13 { background-position: -1080px 0px; }
                &.c14 { background-position: -1170px 0px; }
                &.c15 { background-position: -1260px 0px; }
            }

            &.disabled {
                cursor: default;
            }

            &.disabled:hover {
                color: $mediumGray !important;
            }

            &.disabled.unchecked:hover {
                color: $lightGray1 !important;
            }

            input {
                display: none;
            }
        }

        .empty {
            background: $white;
            float: left;
            height: 70px;
            margin: 1px 1px 0px 0px;
            width: 90px;
        }

        label:nth-child(8),
        .empty:nth-child(16) {
            // margin-right: 0px;
            // width: 93px;
        }
    }

/* COLOR PICKER PLUGIN ---------------------------- */

    .color-picker-popover {
        height: 2px;
        position: absolute;
        width: 2px;
        z-index: 10;

        & > div {
            background: white;
            height: auto;
            line-height: 0px;
            position: absolute;
            @include borderRadius(4px);
            @include boxShadow(0px, 0px, 10px, rgba(0, 0, 0, 0.25));
            @include transformTransition(0.2s, cubic-bezier(0.34, 1.61, 0.7, 1));

            &:after {
                content: '';
                display: block;
                position: absolute;
            }

            &.hidden {
                @include transform(scale(0, 0));
            }

        /* POSITION */

            &.bottom {
                top: 8px;
                @include transformOrigin(top center);

                &:after {
                    top: -7px;
                    left: 50%;
                    margin-left: -8px;
                    @include arrowUp(16px, 7px, $white);
                }
            }

            &.left {
                right: 8px;
                @include transformOrigin(right center);

                &:after {
                    right: -7px;
                    margin-top: -8px;
                    top: 50%;
                    @include arrowRight(7px, 16px, $white);
                }
            }

            &.right {
                left: 8px;
                @include transformOrigin(left center);

                &:after {
                    left: -7px;
                    margin-top: -8px;
                    top: 50%;
                    @include arrowLeft(7px, 16px, $white);
                }
            }

            &.top {
                bottom: 8px;
                @include transformOrigin(bottom center);

                &:after {
                    bottom: -7px;
                    left: 50%;
                    margin-left: -8px;
                    @include arrowDown(16px, 7px, $white);
                }
            }

        /* ITEMS */

            & > strong {
                border-bottom: 1px solid $lightGray2;
                display: block;
                font-size: 13px;
                height: 32px;
                line-height: 32px;
                text-align: center;
            }

            div.colors {
                padding: 14px;

                & > div {
                    background: $extraLightGray1;
                    border: 2px solid $white;
                    cursor: pointer;
                    display: inline-block;
                    height: 30px;
                    width: 30px;
                    @include borderRadius(8px);

                    &:hover {
                        background: $blue;
                    }

                    div {
                        border: 2px solid $white;
                        height: 22px;
                        margin: 2px 0px 0px 2px;
                        width: 22px;
                        @include borderRadius(4px);
                        @include insetBoxShadow(0px, 2px, 1px, rgba(0, 0, 0, 0.1));
                    }

                    &.disabled {
                        cursor: not-allowed;
                        opacity: 0.25;

                        &:hover {
                            background: $extraLightGray1;
                        }
                    }

                    &.selected {
                        background: $blue;
                        cursor: default;

                        &:hover {
                            background: $blue;
                        }
                    }
                }
            }
        }
    }

/* DATATABLES ------------------------------------- */

    table.dataTable {
        background: $white;
        border-bottom: 4px solid $blue;
        border-top: 4px solid $blue;
        font-size: 12px;
        width: 100%;
        @include borderBox();

        thead {
            th {
                border-bottom: 1px solid $lightGray3;
                font-weight: bold;
                height: 50px;

                &.sorting,
                &.sorting_asc,
                &.sorting_desc {
                    cursor: pointer;
                }

                &.sorting_asc:after,
                &.sorting_desc:after {
                    content: '';
                    display: inline-block;
                    margin-left: 4px;
                    margin-bottom: 1px;
                    @include arrowUp(10px, 6px, $success);
                    @include transformTransition(0.1s, ease);
                }

                &.sorting_desc:after {
                    @include arrowUp(10px, 6px, $error);
                    @include transform(rotate(180deg));
                }
            }
        }

        tbody {
            tr {
                &:nth-child(even) {
                    background: $extraLightGray2;
                }

                &:hover {
                    background: $extraLightGray1;
                }

                td {
                    height: 40px;
                    text-align: center;

                    &.dataTables_empty {
                        background: $white !important;
                        color: $mediumGray;
                        font-size: 16px;
                        height: 100px;
                        vertical-align: middle;
                    }

                    &.sorting_1 {
                        background: rgba(71, 211, 95, 0.1) !important;
                    }
                }
            }
        }
    }

    .dataTables_wrapper {
        overflow: hidden;
        position: relative;

        .dataTables_length {
            color: $mediumGray;
            float: left;
            padding: 10px 0px 10px 10px;

            select {
                margin: 0px 5px 0px 0px;
                width: 60px;
            }
        }

        .dataTables_filter {
            color: $mediumGray;
            float: right;
            padding: 10px 10px 10px 0px;

            input {
                width: 238px;
            }
        }

        .dataTables_info {
            color: $mediumGray;
            float: left;
            height: 50px;
            line-height: 50px;
            padding: 0px 0px 0px 15px;
        }

        .dataTables_paginate {
            float: right;
            height: 30px;
            padding: 10px 10px 10px 0px;

            .paginate_button {
                background: $white;
                border: 1px solid $lightGray3;
                color: $gray;
                cursor: pointer;
                display: inline-block;
                height: 28px;
                line-height: 28px;
                padding: 0px 8px;
                text-align: center;
                text-decoration: none;

                &.next,
                &.previous {
                    @include borderRadius(2px);
                }

                &:hover {
                    background: $extraLightGray2;
                }

                &.current {
                    background: $blue;
                    border: none;
                    color: $white;
                    font-weight: bold;
                    height: 30px;
                    line-height: 30px;
                }

                &.disabled,
                &.disabled:hover {
                    background: $white;
                    cursor: default;
                    color: $mediumGray;
                }
            }

            span {
                color: $mediumGray;
                display: inline-block;
                height: 30px;
                line-height: 30px;
                padding: 0px 8px;
            }
        }
    }

/* FEATURED POSITION ------------------------------ */

    .featured-position {
        background: url(#{$domain}/img/web-v2/common/featured-positions.jpg);
        background-size: 84px 600px;
        display: block;
        height: 60px;
        width: 84px;

        &.caledar_daily { background-position: 0px 0px; }
        &.calendar_sidebar { background-position: 0px -60px; }
        &.city_page { background-position: 0px -120px; }
        &.event_sidebar { background-position: 0px -180px; }
        &.map_box { background-position: 0px -240px; }
        &.map_pin { background-position: 0px -300px; }
        &.promoter_sidebar { background-position: 0px -360px; }
        &.stream_newestintown { background-position: 0px -420px; }
        &.yard_head { background-position: 0px -480px; }
        &.yard_sidebar { background-position: 0px -540px; }
    }

    .featured-position-preview {
        background: $lightGray1;
        color: $white;
        margin: 0px auto;

        &.calendar_sidebar,
        &.event_sidebar,
        &.promoter_sidebar,
        &.yard_sidebar {
            background-color: $white;
            background-position: top center !important;
            background-repeat: no-repeat;
            height: 180px;
            position: relative;
            width: 150px;
            @include backgroundSize(cover);

            .cool {
                background: url(#{$domain}/img/web-v2/common/featured-widget-cool.png);
                background-size: 40px 40px;
                height: 40px;
                left: -1px;
                position: absolute;
                top: -1px;
                width: 40px;
            }

            .overlay {
                background: url(#{$domain}/img/web-v2/common/featured-widget-gradient.png) repeat-x 0px 80px;
                background-size: 1px 160px;
                color: $white;
                cursor: pointer;
                height: 180px;
                left: 0px;
                overflow: hidden;
                position: absolute;
                text-align: center;
                top: 0px;
                width: 150px;

                div {
                    bottom: 0px;
                    left: 0px;
                    position: absolute;
                }

                span.category,
                span.verified {
                    background: $blue;
                    font-size: 10px;
                    height: 13px;
                    left: 0px;
                    line-height: 12px;
                    padding: 0px 5px;
                    position: absolute;
                    text-transform: uppercase;
                    top: -13px;
                }

                span.verifed {
                    background: $pink;
                }

                strong {
                    display: block;
                    font-size: 11px;
                    line-height: 12px;
                    padding: 7px 5px 0px 5px;
                    width: 140px;
                }

                span.details {
                    color: $mediumGray;
                    display: block;
                    font-size: 10px;
                    line-height: 12px;
                    padding: 3px 5px 8px 5px;
                    @include textOverflow();
                }
            }
        }

        &.yard_head {
            background: $white;
            height: 40px;
            overflow: hidden;
            position: relative;
            width: 226px;

            img {
                min-height: 40px;
                width: 95px;
            }

            a {
                display: block;
                height: 40px;
                left: 0px;
                line-height: 14px;
                padding-left: 76px;
                position: absolute;
                text-decoration: none;
                top: 0px;
                width: 150px;
                z-index: 1;

                strong {
                    display: block;
                    font-size: 11px;
                    margin: 6px 20px 0px 0px;
                    @include textOverflow();
                }

                span {
                    display: block;
                    font-size: 10px;
                    margin: 0px 20px 0px 0px;
                    @include textOverflow();
                }
            }
        }
    }

/* FREE TICKETS PROMO BANNERS --------------------- */

    a.free-tickets-horizontal-banner,
    a.free-tickets-sidebar-banner {
        color: $white;
        display: block;
        position: relative;
        text-decoration: none;

        strong {
            display: block;
            font-size: 20px;
            line-height: 22px;
            position: absolute;
            text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
        }

        span {
            color: rgba(0, 0, 0, 0.5);
            display: block;
            font-size: 14px;
            font-weight: bold;
            position: absolute;
        }

        div {
            background: $error;
            font-weight: bold;
            height: 50px;
            line-height: 50px;
            position: absolute;
            text-align: center;
            width: 50px;
            z-index: 1;
            @include borderRadius(30px);
            @include subpixelAntialiased();
            @include transformTransition(0.2s, ease-out);
        }

        &:hover {
            div {
                @include transform(scale(1.3, 1.3));
            }
        }
    }

    a.free-tickets-horizontal-banner {
        background: $blue url(#{$domain}/img/web-v2/common/free-tickets-horizontal-banner.jpg) no-repeat top left;
        background-size: 190px 100px;
        height: 100px;
        margin-bottom: 30px;
        width: 730px;

        strong {
            left: 230px;
            top: 27px;
        }

        span {
            left: 230px;
            top: 52px;
        }

        div {
            right: 35px;
            top: 25px;
        }
    }

    a.free-tickets-sidebar-banner {
        background: $blue url(#{$domain}/img/web-v2/common/free-tickets-sidebar-banner.jpg) no-repeat 50px 50px;
        background-size: 130px 75px;
        height: 280px;
        margin-bottom: 0px;
        text-align: center;
        width: 230px;

        strong {
            font-size: 18px;
            left: 0px;
            top: 160px;
            width: 230px;
        }

        span {
            border-top: 1px solid rgba(255, 255, 255, 0.2);
            bottom: 0px;
            font-size: 12px;
            height: 40px;
            left: 0px;
            line-height: 40px;
            width: 230px;
        }

        div {
            height: 40px;
            line-height: 40px;
            left: 145px;
            top: 35px;
            width: 40px;
        }
    }

/* FORMS ------------------------------------------ */

    ::-webkit-input-placeholder { color: $lightGray0; }
    :focus::-webkit-input-placeholder { color: $lightGray1; }

    :-moz-placeholder { color: $lightGray0; opacity: 1; }
    :focus:-moz-placeholder { color: $lightGray1; opacity: 1; }

    ::-moz-placeholder { color: $lightGray0; opacity: 1; }
    :focus::-moz-placeholder { color: $lightGray1; opacity: 1; }

    :-ms-input-placeholder { color: $lightGray0; }
    :focus:-ms-input-placeholder { color: $lightGray1; }

    label {
        input[type="checkbox"].circular,
        input[type="radio"].circular {
            display: none;
        }

        input[type="checkbox"].circular + span,
        input[type="radio"].circular + span {
            background: $white;
            border: 1px solid $lightGray1;
            display: block;
            float: left;
            height: 16px;
            vertical-align: 0px;
            width: 16px;
            @include borderRadius(10px);
        }

        input[type="checkbox"].circular:checked + span,
        input[type="radio"].circular:checked + span  {
            background: url(#{$domain}/img/web-v2/common/tick-pink.jpg) center center;
            background-size: 20px 20px;
            border: none;
            height: 18px;
            width: 18px;
        }
    }

    input.rounded,
    select.rounded,
    textarea.rounded {
        @include borderRadius(2px);
        @include insetBoxShadow(0px, 1px, 0px, rgba(0, 0, 0, 0.02));

        &.bottom {
            @include borderRadius(0px 0px 2px 2px);
        }

        &.left {
            @include borderRadius(2px 0px 0px 2px);
        }

        &.right {
            @include borderRadius(0px 2px 2px 0px);
        }

        &.top {
            @include borderRadius(2px 2px 0px 0px);
        }
    }

    label.new-switch {
        height: 24px;
        position: relative;
        width: 60px;
        @include nonSelectable();

        input {
            display: none;
        }

        div.labels {
            background: $lightGray1;
            height: 24px;
            overflow: hidden;
            position: relative;
            width: 60px;
            @include borderRadius(12px);
            @include maskedRoundedBox();

            span {
                color: $white;
                display: block;
                float: left;
                height: 24px;
                left: 0px;
                line-height: 24px;
                position: absolute;
                text-align: center;
                top: 0px;
                width: 48px;
                @include borderBox();
                @include transition(all, 0.15s, ease-in-out);

                &:first-child {
                    font-weight: bold;
                    left: -36px;
                    padding-right: 8px;
                }

                &:last-child {
                    left: 12px;
                    padding-left: 8px;
                }
            }
        }

        div.thumb {
            background: $white;
            height: 24px;
            left: 0px;
            position: absolute;
            top: 0px;
            width: 24px;
            z-index: 1;
            @include borderRadius(12px);
            @include boxShadow(0px, 0px, 2px, rgba(0, 0, 0, 0.3));
            @include transform(scale(0.9, 0.9));
            @include transformTransition(0.15s, ease-in-out);
        }

        input:checked ~ div.labels {
            span {
                @include transform(translateX(36px));

                &:first-child {
                    background: $success;
                }
            }
        }

        input:checked ~ div.thumb {
            @include transform(translateX(36px));
        }
    }

    form.credit-card {
        fieldset {
            background: $white;
            border: 1px solid $lightGray1;
            height: 61px;
            min-width: 253px;
            width: 253px;
            @include borderRadius($borderRadius);
            @include insetBoxShadow(0px, 1px, 0px, rgba(0, 0, 0, 0.02));

            &.disabled {
                background: $extraLightGray2;

                input[disabled],
                select[disabled] {
                    background: transparent;
                }
            }

            & > div.icon {
                background: url(#{$domain}//web-v2/icons/credit-cards.png);
                background-size: 30px 180px;
                float: left;
                height: 30px;
                width: 30px;

                &.card {
                    border-bottom: 1px solid $lightGray1;

                    &.visa {
                        background-position: 0px -30px;
                    }

                    &.maestro {
                        background-position: 0px -60px;
                    }

                    &.mastercard {
                        background-position: 0px -90px;
                    }
                }

                &.date {
                    background-position: 0px -120px;
                }

                &.cvc {
                    background-position: 0px -150px;
                    border-left: 1px solid $lightGray1;
                }
            }

            input {
                background: transparent;
                border: none;
                float: left;
                font-size: 14px;
                height: 30px;
                padding: 0px;

                &.cc-number {
                    border-bottom: 1px solid $lightGray1;
                    width: 223px;
                }

                &.cc-cvc {
                    width: 40px;
                }
            }

            select.cc-expiry-date {
                border: none;
                float: left;
                font-size: 14px;
                height: 30px;
                margin: 0px;
                padding: 0px;
                width: 132px;
            }

            a.hint {
                float: left;
                margin-top: 8px;
            }
        }

        button.submit {
            visibility: hidden;
        }
    }

/* HIGHLIGHT PLUGIN ------------------------------- */

    .highlight-tooltip {
        height: 2px;
        position: absolute;
        width: 2px;
        z-index: 10;

        & > div {
            font-size: 12px;
            line-height: 34px;
            position: absolute;
            white-space: nowrap;
            @include borderRadius(2px);
            @include boxShadow(0px, 2px, 4px, rgba(0, 0, 0, 0.2));
            @include subpixelAntialiased();
            @include transformAndOtherTransition(0.25s, cubic-bezier(0.34, 1.61, 0.7, 1), 0s, e('opacity 0.1s ease'));

            &:after {
                content: '';
                display: block;
                position: absolute;
            }

            &.hidden {
                opacity: 0;
                @include transform(scale(0.5, 0.5));
            }

        /* COLOR */

            &.green {
                background: $success;
                color: $white !important;
            }

            &.red {
                background: $error;
                color: $white !important;
            }

            &.yellow {
                background: $warning;
                color: $white !important;
            }

        /* POSITION */

            &.bottom {
                padding: 0px 15px;
                top: 5px;
                @include transformOrigin(top center);

                &:after {
                    top: -7px;
                    left: 50%;
                    margin-left: -8px;
                    @include arrowUp(16px, 7px, $error);
                }

                &.green:after {
                    @include arrowUp(16px, 7px, $success);
                }

                &.yellow:after {
                    @include arrowUp(16px, 7px, $warning);
                }
            }

            &.left {
                right: 8px;
                padding: 0px 10px 0px 15px;
                @include transformOrigin(right center);

                &:after {
                    right: -10px;
                    margin-top: -16px;
                    top: 50%;
                    @include arrowRight(10px, 32px, $error);
                }

                &.green:after {
                    @include arrowRight(10px, 32px, $success);
                }

                &.yellow:after {
                    @include arrowRight(10px, 32px, $warning);
                }
            }

            &.right {
                left: 8px;
                padding: 0px 15px 0px 10px;
                @include transformOrigin(left center);

                &:after {
                    left: -10px;
                    margin-top: -16px;
                    top: 50%;
                    @include arrowLeft(10px, 32px, $error);
                }

                &.green:after {
                    @include arrowLeft(10px, 32px, $success);
                }

                &.yellow:after {
                    @include arrowLeft(10px, 32px, $warning);
                }
            }

            &.top {
                bottom: 5px;
                padding: 0px 15px;
                @include transformOrigin(bottom center);

                &:after {
                    bottom: -7px;
                    left: 50%;
                    margin-left: -8px;
                    @include arrowDown(16px, 7px, $error);
                }

                &.green:after {
                    @include arrowDown(16px, 7px, $success);
                }

                &.yellow:after {
                    @include arrowDown(16px, 7px, $warning);
                }
            }

        /* ICON */

            div.icon {
                background: url(#{$domain}/img/web-v2/common/highlight-icons.png);
                background-size: 18px 102px;
                display: inline-block;
                height: 34px;
                margin: 0px 8px 0px -5px;
                vertical-align: bottom;
                width: 18px;

                &.green {
                    background-position: center center;
                }

                &.red {
                    background-position: top center;
                }

                &.yellow {
                    background-position: bottom center;
                }
            }
        }
    }

/* HIGHLIGHT RSVP --------------------------------- */

    a#calendar-button {
        &.rsvp-1, &.rsvp-2, &.rsvp-3 {
            color: $white;
            @include insetBoxShadow(0px, -3px, 0px, rgba(0, 0, 0, 0.1));
        }

        &.rsvp-1 { background: $rsvp1; }
        &.rsvp-2 { background: $rsvp2; }
        &.rsvp-3 { background: $rsvp3; }

        &.fadeColor {
            @include transition(all, 0.1s, ease-out);
        }
    }

    div#calendar-tooltip {
        background: $darkGray;
        border-top: 4px solid $darkGray;
        color: white;
        display: none;
        left: 0px;
        line-height: 18px;
        margin-left: -150px;
        padding: 10px 0px 11px 0px;
        position: absolute;
        text-align: center;
        top: 60px;
        width: 300px;
        @include borderRadius(2px);

        &.hide {
            opacity: 0;
            @include transition(opacity, 0.1s, ease-out);
        }

        &:before {
            content: '';
            display: block;
            left: 50%;
            margin-left: -10px;
            position: absolute;
            top: -13px;
            @include arrowUp(19px, 9px, red);
        }

        span {
            display: none;
        }

        &.rsvp-1 {
            border-top-color: $rsvp1;
            display: block;

            &:before {
                border-bottom-color: $rsvp1;
            }

            span:nth-child(1) {
               display: block;
            }
        }

        &.rsvp-2 {
            border-top-color: $rsvp2;
            display: block;

            &:before {
                border-bottom-color: $rsvp2;
            }

            span:nth-child(2) {
               display: block;
            }
        }

        &.rsvp-3 {
            border-top-color: $rsvp3;
            display: block;

            &:before {
                border-bottom-color: $rsvp3;
            }

            span:nth-child(3) {
               display: block;
            }
        }

        &.rsvp-4 {
            border-top-color: $rsvp4;
            display: block;
            top: 65px;

            &:before {
                border-bottom-color: transparent;
            }

            span:nth-child(4) {
               display: block;
            }
        }
    }

/* LANGUAGE FLAGS --------------------------------- */

    .language-flag {
        background: url(#{$domain}/img/web-v2/common/flags.png) no-repeat;
        background-size: 24px 140px;
        height: 20px;
        width: 24px;

        &.shadowless {
            background-image: url(#{$domain}/img/web-v2/common/flags.png);
        }

        &.en_UK { background-position: 0px 0px; }
        &.bg_BG { background-position: 0px -20px; }
        &.es_MX { background-position: 0px -40px; }
        &.es_ES { background-position: 0px -60px; }
        &.ru_RU { background-position: 0px -80px; }
        &.gr_GR { background-position: 0px -100px; }
        &.ro_RO { background-position: 0px -120px; }
    }

/* LIST LOADER ------------------------------------ */

    .list-loader {
        height: 36px;
        padding-top: 20px;
        text-align: center;

        &.hidden {
            display: none;
        }

        a.btn-primary {
            background: $success;
            font-size: 14px;
            font-weight: bold;
            height: 36px;
            line-height: 36px;
            padding: 0px 10px;
            position: relative;
            width: 210px;
        }
    }

/* LOGO ------------------------------------------- */

    .logotype.big {
        background: url(#{$domain}/img/logo.svg) no-repeat center center;
        background-size: 190px 50px;
        display: block;
        height: 50px;
        width: 190px;

        &.white {
            background-image: url(#{$domain}/img/logo.svg);
        }
    }

/* OVERLAY ---------------------------------------- */

    .overlay-template {
        background: rgba(0, 0, 0, 0.85);
        bottom: 0px;
        display: none;
        left: 0px;
        position: fixed;
        right: 0px;
        top: 0px;
        opacity: 0;
        overflow: auto;
        z-index: 1060;
        @include transition(opacity, 0.25s, ease);

        &.shown {
            opacity: 1.0;
        }

        & > div.activity-indicator {
            position: fixed;
            z-index: 10;
        }

        & > a.close {
            background: url(#{$domain}/img/web-v2/icons/close-white.gif) no-repeat center center;
            background-size: 12px 12px;
            height: 100px;
            position: fixed;
            right: 0px;
            top: 0px;
            width: 100px;
        }

        & > div.body {
            color: $white;
            margin: 0px auto;
            width: 980px;
        }
    }

/* PAGE CONTROL ----------------------------------- */

    .page-control {
        font-size: 0px;
        height: 10px;
        line-height: 10px;
        text-align: center;
        width: 100%;
        @include transition(opacity, 0.25s, linear);

        &.hidden {
            opacity: 0;
        }

        a {
            background: rgba(255, 255, 255, 0.25);
            cursor: pointer;
            display: inline-block;
            height: 10px;
            margin-right: 6px;
            width: 10px;
            @include borderRadius(5px);

            &:last-child {
                margin-right: 0px;
            }

            &.active {
                background: $white;
            }
        }

        &.vertical {
            height: auto;
            width: 10px;

            a {
                display: block;
                margin-bottom: 6px;
                margin-right: 0px;

                &:last-child {
                    margin-bottom: 0px;
                }
            }
        }

        &.gray {
            a {
                background: $lightGray1;

                &.active {
                    background: $mediumGray;
                }
            }
        }

        &.gray-blue {
            a {
                background: $lightGray1;

                &.active {
                    background: $blue;
                }
            }
        }

        &.border-blue {
            a {
                background: $white;
                border: 1px solid $lightGray1;
                @include borderBox();

                &.active {
                    background: $blue;
                    border: none;
                }
            }
        }
    }

/* ROUND BUTTONS ---------------------------------- */

    .round-buttons {
        a {
            background: $lightGray1 url(#{$domain}/img/web-v2/common/round-buttons.png?2) no-repeat;
            background-size: 44px 704px;
            display: inline-block;
            font-size: 12px;
            height: 44px;
            margin-right: 5px;
            width: 44px;
            @include borderRadius(22px);

            &:last-child {
                margin-right: 0px;
            }

            &.primary {
                background-color: $pink;
            }

            &.accept { background-position: 0px 0px; }
            &.cancel, &.decline, &.delete, &.remove { background-position: 0px -44px; }
            &.add { background-position: 0px -88px; }
            &.friend { background-position: 0px -132px; }
            &.unfriend { background-position: 0px -176px; }
            &.message { background-position: 0px -220px; }
            &.edit { background-position: 0px -264px; }
            &.settings { background-position: 0px -308px; }
            &.facebook { background-color: $facebook; background-position: 0px -352px; }
            &.campaigns-report { background-position: 0px -396px; }
            &.new-campaign { background-position: 0px -440px; }
            &.event-statistics { background-position: 0px -484px; }
            &.tickets { background-position: 0px -528px; }
            &.billing { background-position: 0px -572px; }
            &.embed { background-position: 0px -616px; }
            &.discount-codes { background-position: 0px -660px; }
        }
    }

/* SIDEBAR MENU ----------------------------------- */

    .sidebar-menu {
        background: $darkBlue;
        color: $white;
        margin-bottom: 20px;

        a {
            border-bottom: 1px solid rgba(255, 255, 255, 0.08);
            color: $lightGray1;
            display: block;
            font-weight: bold;
            line-height: 30px;
            padding: 10px 40px;
            position: relative;
            text-decoration: none;

            span {
                display: block;
                padding-right: 15px;
                @include textOverflow();
            }

            span.icon {
                display: block;
                height: 30px;
                left: 0px;
                padding-right: 0px;
                position: absolute;
                top: 10px;
                width: 40px;
            }

            span.badge {
                background: $pink;
                color: $white;
                float: left;
                font-size: 10px;
                height: 13px;
                line-height: 12px;
                margin-top: -6px;
                padding: 0px 3px;
                position: absolute;
                right: 9px;
                top: 50%;
                @include borderRadius(2px);
            }

            &:hover {
                color: $white;
            }

            &:last-child {
                border-bottom: none;
            }

            &.indent {
                border-bottom: none;

                &:after {
                    background: rgba(255, 255, 255, 0.08);
                    content: '';
                    display: block;
                    height: 1px;
                    margin-left: 0px;
                }
            }

            &.high {
                line-height: 40px;

                &.active:after {
                    @include arrowRight(10px, 40px, $blue);
                }

                span.icon {
                    height: 40px;
                }
            }

            &.active {
                background: $blue;
                color: white;

                &:after {
                    content: '';
                    display: block !important;
                    position: absolute;
                    right: -10px;
                    top: 0px;

                    @include arrowRight(10px, 30px, $blue);
                }

                span.badge {
                    display: none;
                }
            }
        }
    }

/* SLIDE CONTROL ---------------------------------- */

    .slide-control {
        bottom: 0px;
        left: 0px;
        position: absolute;
        right: 0px;
        top: 0px;
        @include transform(translateZ(0px));

        &.transitions {
            @include transformTransition(0.5s, ease-in-out);
        }

        section {
            height: 100%;
            position: relative;
            @include borderBox();
        }
    }

/* STEPS BAR CONTROL ------------------------------ */

    .steps-bar-control {
        font-size: 0px;
        height: 30px;
        text-align: center;
        width: 100%;

        div {
            background: $white;
            border: 1px solid $lightGray3;
            color: $mediumGray;
            display: inline-block;
            font-size: 12px;
            height: 30px;
            line-height: 28px;
            margin-right: 20px;
            position: relative;
            min-width: 140px;
            max-width: 200px;
            padding-left: 10px;
            padding-right: 10px;
            @include borderBox();
            @include borderRadius(15px);

            &:after {
                background: $lightGray3;
                content: '';
                display: inline-block;
                height: 1px;
                right: -21px;
                position: absolute;
                top: 14px;
                width: 20px;
            }

            &.past {
                background: $extraLightGray1;
            }

            &.current {
                background: $success;
                border-color: $success;
                color: $white;
            }
        }

        div:last-child {
            margin-right: 0px;

            &:after {
               display: none;
            }
        }

        &.clickable {
            div {
                cursor: pointer;
                @include nonSelectable();

                &.current {
                    cursor: default;
                }
            }
        }
    }

/* TICKET ----------------------------------------- */

    div.ticket {
        @include borderRadius(4px);
        @include boxShadow(0px, 1px, 4px, rgba(0, 0, 0, 0.1));

        strong.label {
            color: $lightGray0;
            display: block;
            font-size: 10px;
            line-height: 12px;
            padding-bottom: 1px;
            text-transform: uppercase;
        }

        span.value {
            display: block;
            font-size: 14px;
            @include textOverflow();
        }

        a.event-details {
            border-bottom: 1px solid $extraLightGray1;
            display: block;
            height: 82px;
            padding: 16px 20px 0px 20px;
            text-decoration: none;
            width: 190px;

            & > strong {
                color: $darkGray;
                display: block;
                font-size: 16px;
                line-height: 18px;
                padding-bottom: 5px;
                @include textOverflow();

                & + span {
                    @include textOverflow();
                }
            }

            span {
                color: $mediumGray;
                display: block;
                line-height: 16px;
                width: 190px;
            }
        }

        div.ticket-details {
            border-bottom: 1px solid $extraLightGray1;
            height: 83px;
            padding: 7px 0px 0px 20px;

            strong.label {
                padding-top: 5px;
            }

            span.color {
                display: block;
                float: left;
                height: 10px;
                margin: 4px 5px 0px 0px;
                width: 10px;
                @include borderRadius(2px);
            }

            span.value:last-child {
                width: 175px;
            }
        }

        div.ticket-codes {
            height: 95px;

            img {
                float: left;
                height: 65px;
                margin: 15px 20px 15px 20px;
                width: 65px;
            }

            strong.label {
                padding-bottom: 2px;
            }

            span.value {
                font-size: 18px;
            }

            div {
                &.number {
                    padding-top: 12px;
                }

                &.code {
                    padding-top: 6px;

                    span.value {
                        padding-bottom: 10px;
                    }
                }
            }
        }

        div.signature {
            background: $blue;
            color: $pink;
            font-size: 9px;
            font-weight: bold;
            height: 25px;
            text-align: center;
            text-transform: uppercase;
            @include borderRadius(0px 0px 3px 3px);

            img {
                height: 25px;
                margin-left: 2px;
                vertical-align: -9px;
            }
        }
    }

    div.ticket-preview {
        background: $white;
        cursor: default;
        height: 200px;
        overflow: hidden;
        width: 150px;
        border-style: soild;
        border-width: 1px;
        display: inline-block;
        @include borderRadius(4px);
        @include nonSelectable();
        
        & > div:nth-child(1) {
            border-bottom: 1px solid $extraLightGray1;
            height: 57px;

            div:nth-child(1) {
                font-size: 12px;
                font-weight: bold;
                margin: 7px 13px 0px 13px;
                text-align: left;
                width: 124px;
                @include textOverflow();
            }

            div:nth-child(2),
            div:nth-child(3) {
                background: $extraLightGray1;
                height: 5px;
                margin: 0px 0px 5px 13px;
                width: 95px;
            }

            div:nth-child(3) {
                width: 85px;
            }
        }

        & > div:nth-child(2) {
            border-bottom: 1px solid $extraLightGray1;
            height: 50px;

            div {
                background: $extraLightGray1;
                height: 5px;
                margin: 0px 0px 5px 13px;
                width: 95px;
            }

            div:nth-child(1) {
                margin-top: 10px;
                width: 25px;
            }

            div:nth-child(2) {
                width: 34px;
            }

            div:nth-child(3) {
                margin-top: 10px;
                width: 30px;
            }

            div:nth-child(4) {
                width: 54px;
            }
        }

        & > div:nth-child(3) {
            height: 58px;
            overflow: hidden;

            div:nth-child(1) {
                background: url(#{$domain}/qr/?code=evedo);
                background-size: 38px 38px;
                float: left;
                height: 38px;
                margin: 10px 0px 0px 13px;
                width: 38px;
            }

            div:nth-child(2),
            div:nth-child(3),
            div:nth-child(4),
            div:nth-child(5) {
                background: $extraLightGray1;
                float: left;
                height: 5px;
                margin: 0px 0px 5px 13px;
                width: 60px;
            }

            div:nth-child(2) {
                margin-top: 10px;
                width: 25px;
            }

            div:nth-child(3) {
                margin-right: 30px;
                width: 29px;
            }

            div:nth-child(4) {
                margin-top: 3px;
                width: 40px;
            }
        }

        & > div:nth-child(4) {
            background: $blue;
            height: 16px;
        }
    }

/* TOKEN LIST ------------------------------------- */

    ul.token-list {
        list-style-type: none;
        margin: 0px;
        padding: 0px;
        @include borderBox();

        &:empty {
            padding-bottom: 0px !important;
        }

        li {
            background: $white;
            border: 1px solid $lightGray2;
            display: inline-block;
            height: 22px;
            line-height: 22px;
            margin: 0px 3px 3px 0px;
            padding-left: 10px;
            vertical-align: middle;
            @include borderRadius(12px);
            @include boxShadow(1px, 1px, 0px, rgba(0, 0, 0, 0.02));

            &.highlighted {
                background: $highlighted;
            }

            &.special {
                color: $blue;
            }

            &.transition {
                @include transition(background, 0.2s, linear, 0s);
            }

            span {
                display: block;
                float: left;
                max-width: 180px;
                @include textOverflow();

                &:last-child {
                    padding-right: 10px;
                }
            }

            span + span {
                color: $mediumGray;
                font-size: 10px;
                line-height: 18px;
                padding-left: 2px;
                padding-top: 1px;
                max-width: 70px;
            }

            a {
                background: url(#{$domain}/img/web-v2/icons/remove-gray.gif) no-repeat 6px 7px;
                background-size: 8px 8px;
                display: block;
                float: left;
                height: 22px;
                vertical-align: middle;
                width: 22px;
            }
        }
    }




/* FORMS ------------------------------------------ */

    /* input and textarea */

        form.standard input,
        form.standard textarea {
            padding: 12px;
            margin-top: 10px;
        }

        // form.standard fieldset input {
        //     margin-top: 0px;
        // }

        input.loading {
            background: white url(#{$domain}/img/web/input-loading.gif) no-repeat top right;
        }

    /* errors */

        form.standard .error-message {
            background: #e33535;
            color: white;
            display: none;
            font-weight: bold;
            line-height: 40px;
            padding: 0px 20px;
            text-align: center;
        }

        form.standard .error-message.shown {
            display: block;
        }

        form.standard .error {
            color: #e62e83;
        }

        form.standard input.error:-moz-placeholder,
        form.standard textarea.error:-moz-placeholder {
          color: #e62e83;
        }

        form.standard .error:-ms-input-placeholder {
          color: #e62e83;
        }

        form.standard .error::-webkit-input-placeholder {
          color: #e62e83;
        }

    /* select */

        select {
            background: white url(#{$domain}/img/web-v2/common/select-arrow.gif) no-repeat center right;
            background-size: 19px 11px;
            // height: 30px;
            font-size: 12px;
            line-height: normal;
            margin-top: 10px;
            padding: 0px 0px 0px 5px;
            text-indent: 2px;
            width: 200px;
            -webkit-appearance: none;
               -moz-appearance: none;
                    appearance: none;
            -webkit-border-radius: 0px;
        }

        form.standard fieldset select {
            float: left;
            margin-top: 0px;
        }

    /* label - checkbox and radio */

        form.standard label {
            line-height: 13px;
            padding: 20px 0px 0px 0px;
        }

        form.standard label:first-child {
            padding-top: 10px;
        }

        form.standard label + label {
            padding-top: 10px;
        }

        form.standard label + input,
        form.standard label + select {
            margin-top: 20px;
        }

        label input[type="checkbox"].custom,
        label input[type="radio"].custom{
            display: none;
        }

        label input[type="checkbox"].custom + span {
            background: url(#{$domain}/img/web-v2/common/custom-checkbox.png);
            background-size: 14px 26px;
            cursor: pointer;
            display: inline-block;
            height: 13px;
            margin-right: 4px;
            vertical-align: -2px;
            width: 14px;
        }

        label input[type="radio"].custom + span {
            background: url(#{$domain}/img/web-v2/common/custom-radio.png);
            background-size: 13px 26px;
            cursor: pointer;
            display: inline-block;
            height: 13px;
            margin-right: 4px;
            vertical-align: -2px;
            width: 13px;
        }

        label input[type="checkbox"].custom:checked + span,
        label input[type="radio"].custom:checked + span {
            background-position: bottom left;
        }

        label input[type="checkbox"].custom:disabled + span,
        label input[type="radio"].custom:disabled + span {
            cursor: not-allowed;
            opacity: 0.4;
        }

    /* label - switch */

        label.switch {
            color: #888;
            cursor: pointer;
            height: 26px;
            margin: 0px;
            overflow: hidden;
            padding: 0px !important;
            position: relative;
            text-align: center;
            text-transform: uppercase;
            width: 60px;

            -webkit-border-radius: 2px;
               -moz-border-radius: 2px;
                    border-radius: 2px;
        }

        label.switch input {
            display: none;
        }

        label.switch span {
            display: block;
            height: 26px;
            line-height: 26px;
            position: absolute;
            top: 0px;

            -webkit-border-radius: 2px;
               -moz-border-radius: 2px;
                    border-radius: 2px;

            -webkit-transition: left 0.1s ease-in-out;
               -moz-transition: left 0.1s ease-in-out;
                 -o-transition: left 0.1s ease-in-out;
                    transition: left 0.1s ease-in-out;
        }

        label.switch span.on,
        label.switch span.off {
            background: #10345d;
            color: white;
            left: 0px;
            padding: 0px 16px 0px 0px;
            width: 44px;
            z-index: 2;
        }

        label.switch span.thumb {
            background: white;
            height: 22px;
            left: 44px;
            top: 2px;
            width: 14px;
            z-index: 3;

            -webkit-box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
               -moz-box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
                    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
        }

        label.switch span.off {
            background: #d2d2d2;
            color: white;
            left: 42px;
            padding: 0px 0px 0px 16px;
            z-index: 1;
        }

        label.switch.unchecked span.on {
            left: -42px;
            z-index: 1;
        }

        label.switch.unchecked span.thumb {
            left: 2px;
        }

        label.switch.unchecked span.off {
            left: 0px;
            z-index: 2;
        }

    /* radio - tabs */

        .radio-tabs {
            height: 40px;
            padding: 10px 0px;
        }

        .radio-tabs label {
            border-left: 1px solid #e8e8e8;
            cursor: pointer;
            float: left;
            height: 40px;
            line-height: 40px !important;
            padding-top: 0px !important;
            position: relative;
            text-align: center;
            width: 200px;
        }

        .radio-tabs label:after {
            background: url(#{$domain}/img/web/radio-tabs-arrow.gif);
            content: '';
            display: block;
            height: 12px;
            left: 50%;
            margin-left: -8px;
            position: absolute;
            top: 43px;
            width: 15px;
        }

        .radio-tabs label.no-arrow:after,
        .radio-tabs label.unchecked:after {
            display: none;
        }

        .radio-tabs label:first-child {
            border-left: none;
        }

        .radio-tabs label.disabled strong,
        .radio-tabs label.disabled span {
            color: #d2d2d2 !important;
        }

        .radio-tabs label.multiple-lines {
            height: 34px;
            line-height: 14px !important;
            padding-top: 6px !important;
        }

        .radio-tabs label input {
            display: none;
        }

        .radio-tabs label strong {
            color: #10345d;
        }

        .radio-tabs label.unchecked strong {
            color: #444;
        }

        .radio-tabs label.unchecked:hover strong {
            color: #10345d;
        }

        .radio-tabs label span {
            color: #888;
            font-size: 11px;
        }

        .radio-tabs-block {
            background: #e8e8e8 url(#{$domain}/img/web/inner-box-shadow.png) repeat-x;
            display: none;
            padding: 10px 0px 20px 20px;
        }

    /* spinner */

        .ui-spinner {
            background: white;
            border: 1px solid #d2d2d2;
            display: inline-block;
            height: 28px;
            overflow: hidden;
            position: relative;
            text-align: left;
            width: 60px;
        }

        .ui-spinner-input {
            background: white !important;
            border: none !important;
            color: #444 !important;
            cursor: default !important;
            height: 28px;
            width: 30px;
        }

        .ui-spinner-up,
        .ui-spinner-down {
            background: url(#{$domain}/img/web-v2/icons/arrow-top-gray.gif) no-repeat center center;
            background-size: 11px 11px;
            border: none;
            border-left: 1px solid #ccc;
            cursor: default;
            height: 15px;
            font-size: 0px;
            position: absolute;
            right: 0px;
            width: 21px;
            z-index: 100;
        }

        .ui-spinner-down {
            background: url(#{$domain}/img/web-v2/icons/arrow-bottom-gray.gif) no-repeat center center;
            background-size: 11px 11px;
            bottom: 0;
        }

    /* browse button */

    form#pictureForm  {

        label {
            cursor: pointer;
          }

        .custom-browse {
            background: #10345d;
            color: #d9f1f8;
            font-size: 11px;
            height: 40px;
            line-height: 14px;
            padding: 6px;
            position: relative;
            text-align: center;
            width: 100%;
          input {
            font-size: 180px;
            left: 0px;
            opacity: 0;
          }
          strong {
            color: white;
            font-size: 16px;
          }
        }
    }
    
    form .custom-browse {
      background: rgba(0, 0, 0, 0.8);
      color: #d2d2d2;
      left: 0px;
      line-height: 16px;
      padding: 0px;
      position: absolute;
      top: 0px;
      width: 100%;
      height: 100%;

      -webkit-transition: opacity 0.02s linear;
         -moz-transition: opacity 0.02s linear;
           -o-transition: opacity 0.02s linear;
              transition: opacity 0.02s linear;
    }

    form:hover .custom-browse {
      // opacity: 1.0;
    }

    form .custom-browse strong {
      // display: block;
      // margin-top: 98px;
    }

    form .custom-browse input {
      cursor: pointer;
      top: 0px;
    }

    form .custom-browse a.remove {
      background: rgba(0, 0, 0, 0.2) url(#{$domain}/img/web/icon-remove-white.gif) no-repeat 8px 8px;
      display: block;
      height: 24px;
      position: absolute;
      right: 0px;
      top: 0px;
      width: 24px;
      z-index: 1;
    }

    form .custom-browse a.remove:hover {
      background-color: rgba(0, 0, 0, 0.5);
    }

    /* other */

        form.standard     fieldset {
            margin-top: 10px;
        }

        form.standard div.block {
        }

        form.standard div.whitespace {
            color: #d2d2d2;
            float: left;
            height: 30px;
            margin-top: 10px;
            line-height: 30px;
            text-align: center;
            width: 20px;
        }

        form.standard div.actions {

            padding: 21px 0px 20px 0px;
            text-align: center;
        }

        form.standard div.actions.no-border {
            background: none;
            padding-top: 20px;
        }

        form.standard div.actions .btn {
        }

      .custom-browse {
        background: #10345d;
        color: #d9f1f8;
        font-size: 11px;
        line-height: 14px;
        padding: 6px;
        position: relative;
        text-align: center;
        width: 100%;

        strong {
            color: white;
            font-size: 16px;
        }

        input {
            cursor: pointer;
            font-size: 180px;
            height: 220px;
            left: 0px;
            opacity: 0;
            position: absolute;
            top: -185px;
            width: 100%;
        }
      }

/* TOKEN INPUT ------------------------------------ */

    ul.token-input-list {
        background: white;
        border: 1px solid #d2d2d2;
        clear: left;
        cursor: text;
        height: auto;
        margin: 0px;
        min-height: 30px;
        overflow: hidden;
        padding: 0px 4px 4px 0px;
        z-index: 999;

        -webkit-box-sizing: border-box;
           -moz-box-sizing: border-box;
                box-sizing: border-box;
    }

    fieldset input + ul.token-input-list {
        border-top: none !important;
        min-height: 29px;
    }

    ul.token-input-list li {
        list-style-type: none;
    }

    ul.token-input-list li input {
        border: none;
        float: left;
        margin-top: 4px !important;
        padding: 1px 10px;
        width: 100%;
        min-width: 100px;
        -webkit-appearance: caret;
        &::-webkit-input-placeholder  {content:"Tags";}
    }

    li.token-input-token {
        background: white;
        border: 1px solid #e2e2e2;
        color: #888;
        cursor: default;
        display: block;
        float: left;
        line-height: 18px;
        margin: 4px 0px 0px 4px;
        overflow: hidden;
    }

    li.token-input-token p {
        float: left;
        margin: 0px;
        padding: 0px 3px 0px 5px;
    }

    li.token-input-token span {
        background: url(#{$domain}/img/web/icon-remove-gray.gif) no-repeat center center;
        color: #d2d2d2;
        cursor: pointer;
        display: block;
        float: right;
        font-size: 1px;
        text-align: center;
        width: 18px;
    }

    li.token-input-selected-token {
        background-color: #e62e83;
        border: 1px solid #e62e83;
        color: white;
    }

    li.token-input-selected-token span {
        background-image: url(#{$domain}/img/web/icon-remove-white.gif);
    }

    div.token-input-dropdown {
        background: white;
        border: 1px solid #d2d2d2;
        cursor: default;
        padding: 5px 0px;
        position: absolute;
        width: 248px;
        z-index: 2000;

        -webkit-box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
           -moz-box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
                box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    }

    div.token-input-dropdown:before {
        border-color: transparent transparent #d2d2d2 transparent;
        border-style: solid;
        border-width: 0px 10px 9px 10px;
        content: '';
        display: block;
        height: 0px;
        left: 13px;
        position: absolute;
        top: -9px;
        width: 0px;

        -webkit-transform: rotate(360deg);
           -moz-transform: rotate(360deg);
                transform: rotate(360deg);
    }

    div.token-input-dropdown:after {
        border-color: transparent transparent white transparent;
        border-style: solid;
        border-width: 0px 8px 8px 8px;
        content: '';
        display: block;
        height: 0px;
        left: 15px;
        position: absolute;
        top: -8px;
        width: 0px;

        -webkit-transform: rotate(360deg);
           -moz-transform: rotate(360deg);
                transform: rotate(360deg);
    }

    div.token-input-dropdown p {
        color: #888;
        font-style: italic;
        height: 22px;
        padding: 0px 10px;
        margin: 0px;
    }

    div.token-input-dropdown ul {
        margin: 0px;
        padding: 0px;
        overflow: auto;
        max-height: 88px;
    }

    div.token-input-dropdown ul li {
        background: white;
        color: #444;
        height: 22px;
        line-height: 22px;
        list-style-type: none;
        margin: 0px;
        overflow: hidden;
        padding: 0px 10px;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    div.token-input-dropdown ul li em {
        font-weight: bold;
        font-style: normal;
    }

    div.token-input-dropdown ul li.token-input-selected-dropdown-item {
        background: #e62e83;
        color: white;
    }

/* AUTOCOMPLETE ----------------------------------- */

    .ui-helper-hidden-accessible {
        display: none;
    }

    .ui-state-focus {
        background: #e62e83;
        color: white !important;
    }

    ul.ui-autocomplete {
        background: white;
        border: 1px solid #d2d2d2;
        border-top: none;
        cursor: default;
        list-style-type: none;
        max-height: 105px;
        overflow-x: hidden;
        overflow-y: auto;
        padding: 5px 0px;
        position: absolute;

        -webkit-box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
           -moz-box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
                box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    }

    ul.ui-autocomplete li.autocomplete-image-item {
        width: 207px;
    }

    ul.ui-autocomplete li a {
        display: block;
        color: #444;
        line-height: 22px;
        overflow: hidden;
        padding: 0px 10px;
        text-decoration: none;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 280px;
    }

    ul.ui-autocomplete li.autocomplete-image-item a {
        height: 26px;
        line-height: 26px;
    }

    ul.ui-autocomplete li.autocomplete-image-item a img {
        float: left;
        height: 18px;
        margin: 4px 10px 0px 0px;
        width: 18px;
    }

/* JEDITABLE -------------------------------------- */

    .jeditable {
        margin: 0px;
        padding: 0px;
        width: 100% !important;

        -webkit-box-sizing: border-box;
           -moz-box-sizing: border-box;
                box-sizing: border-box;
    }

    .jeditable input,
    .jeditable textarea {
        min-height: 30px;
        padding: 0px 5px;
        width: inherit;

        -webkit-box-sizing: border-box;
           -moz-box-sizing: border-box;
                box-sizing: border-box;

        -webkit-box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
           -moz-box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
                box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    }

    .jeditable.name {
        margin: 4px 0px;
    }

    .jeditable.name input {
        font-family: Georgia, Times New Roman, serif;
        font-size: 20px;
        height: 40px !important;
    }

    .jeditable.short-description {
        margin: 3px 0px 4px 0px;
    }

    .jeditable.short-description input {
        color: #888;
        font-family: Georgia, Times New Roman, serif;
        font-size: 14px;
        height: 30px !important;
    }

    .jeditable.description {
        margin: 25px 0px 7px 0px;
    }

    .jeditable.description textarea {
        line-height: 22px;
        min-height: 100px;
        padding: 5px;
    }

    .jeditable.profile-name input {
        border: none;
        color: #444;
        font-size: 16px;
        font-weight: bold;
        height: 24px !important;
        left: 0px;
        min-height: 24px;
        position: absolute;
        top: 8px;
        text-align: center;

        -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
           -moz-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
                box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
    }

    .jeditable.linked-list-name {
        margin: 0px;
    }

    .jeditable.linked-list-name input {
        font-size: 14px;
        height: 40px !important;
        left: 10px;
        position: absolute;
        top: 10px;
        width: 620px;
    }

/* DATEPICKER ------------------------------------- */

    .datepicker {
        left: 0px;
        margin-top: 5px;
        padding: 0px;
        top: 0px;
        width: 230px;
    }

    .datepicker .arrow {
        border-color: transparent transparent white transparent;
        border-style: solid;
        border-width: 0px 8px 8px 8px;
        display: block !important;
        height: 0px;
        left: 50%;
        margin-left: -8px;
        position: absolute;
        top: -8px;
        width: 0px;

        -webkit-transform: rotate(360deg);
           -moz-transform: rotate(360deg);
                transform: rotate(360deg);
    }

    .datepicker > div {
        display: none;
    }

    .datepicker table {
        margin: 0px;
        width: 100%;
    }

    .datepicker td,
    .datepicker th {
        border-bottom: 1px solid #e2e2e2;
        border-right: 1px solid #e2e2e2;
        color: #444;
        height: 24px;
        line-height: 24px;
        padding: 0px;
        text-align: center;
        width: 33px;
    }

    .datepicker td:last-child,
    .datepicker th:last-child {
        border-right: none;
        width: 32px;
    }

    .datepicker tr:last-child td {
        border-bottom: none;
    }

    .datepicker th.prev,
    .datepicker th.switch,
    .datepicker th.next {
        height: 28px;
        line-height: 28px;
    }

    .datepicker th.prev,
    .datepicker th.prev:hover {
        background: white url(#{$domain}/img/web-v2/icons/arrow-left-gray.gif) center center no-repeat !important;
        background-size: 11px 11px;
        color: rgba(255, 255, 255, 0);
    }

    .datepicker th.switch:hover {
        background: white !important;
        color: #10345d;
    }

    .datepicker th.next,
    .datepicker th.next:hover {
        background: white url(#{$domain}/img/web-v2/icons/arrow-right-gray.gif) center center no-repeat !important;
        background-size: 11px 11px;
        color: rgba(255, 255, 255, 0);
    }

    .datepicker th.dow {
        border-right-color: white;
        color: #888;
        font-size: 10px;
        font-weight: normal;
        height: 20px;
        line-height: 20px;
        text-transform: uppercase;
    }

    .datepicker td.day:hover {
        background: #f0f0f0;
        cursor: pointer;
    }

    .datepicker td.old,
    .datepicker td.new {
        color: #d2d2d2;
    }

    .datepicker td.disabled {
        background: #f0f0f0;
        color: #d2d2d2;
    }

    .datepicker td.active,
    .datepicker td.active:hover {
        background: #10345d;
        color: white;
        font-weight: bold;
    }

    .datepicker td span {
        background: white;
        cursor: pointer;
        display: block;
        float: left;
        height: 30px;
        line-height: 30px;
        margin: 0px 0px 10px 10px;
        width: 45px;
    }

    .datepicker td span:nth-child(1),
    .datepicker td span:nth-child(2),
    .datepicker td span:nth-child(3),
    .datepicker td span:nth-child(4) {
        margin-top: 10px;
    }

    .datepicker td span:hover {
        background: #f0f0f0;
    }

    .datepicker td span.active {
        background: #10345d;
        color: white;
        font-weight: bold;
    }

    .datepicker td span.old {
        color: #d2d2d2;
    }

    .datepicker thead tr:first-child th {
        cursor: pointer;
    }

/* TIMEPICKER ------------------------------------- */

    .bootstrap-timepicker.dropdown-menu {
        display: none;
        left: 0px;
        margin-top: 5px;
        min-width: 100px;
        padding: 0px;
        top: 0px;
        width: 100px;
        z-index: 99999;
    }

    .bootstrap-timepicker.dropdown-menu.open {
        display: inline-block;
    }

    .bootstrap-timepicker.dropdown-menu:before {
        border-color: transparent transparent white transparent;
        border-style: solid;
        border-width: 0px 8px 8px 8px;
        content: '';
        display: block !important;
        height: 0px;
        left: 50%;
        margin-left: -8px;
        position: absolute;
        top: -8px;
        width: 0px;

        -webkit-transform: rotate(360deg);
           -moz-transform: rotate(360deg);
                transform: rotate(360deg);
    }

    .bootstrap-timepicker table {
        margin: 0px 10px;
    }

    .bootstrap-timepicker table td {
        height: 30px;
        padding: 0px;
        text-align: center;
        vertical-align: top;
        width: 30px;
    }

    .bootstrap-timepicker table td.separator {
        line-height: 30px;
        width: 20px;
    }

    .bootstrap-timepicker table td a {
        display: block;
        height: 30px;
        padding: 0px;
        width: 30px;
    }

    .bootstrap-timepicker table td a i {
        display: block;
        height: inherit;
        width: inherit;
    }

    .bootstrap-timepicker table td a i.icon-chevron-up {
        background: url(#{$domain}/img/web-v2/icons/arrow-top-gray.gif) center center no-repeat;
        background-size: 11px 11px;
    }

    .bootstrap-timepicker table td a i.icon-chevron-down {
        background: url(#{$domain}/img/web-v2/icons/arrow-bottom-gray.gif) center center no-repeat;
        background-size: 11px 11px;
    }

    .bootstrap-timepicker table td input {
        width: 18px;
    }


/* ANIMATIONS ------------------------------------- */

    @-webkit-keyframes activity {
        from { @include transform(rotate(0deg)); }
          to { @include transform(rotate(360deg)); }
    }
    @-moz-keyframes activity {
        from { @include transform(rotate(0deg)); }
          to { @include transform(rotate(360deg)); }
    }
    @-ms-keyframes activity {
        from { @include transform(rotate(0deg)); }
          to { @include transform(rotate(360deg)); }
    }
    @-o-keyframes activity {
        from { @include transform(rotate(0deg)); }
          to { @include transform(rotate(360deg)); }
    }
    @keyframes activity {
        from { @include transform(rotate(0deg)); }
          to { @include transform(rotate(360deg)); }
    }

    .noise {
        background-size: 50px 50px;
    }

    a.hint {
        background-image: url(#{$domain}/img/web-v2/common/hint-question.png);
    }

    .paypal-button {
        background-image: url(#{$domain}/img/web-v2/common/paypal-button.png);
    }

    .activity-indicator {
        background-image: url(#{$domain}/img/web-v2/common/activity-indicator.png);

        &.gray {
            background-image: url(#{$domain}/img/web-v2/common/activity-indicator-gray.png);
        }
    }

    .categories-list {
        // background-image: url(#{$domain}/img/web-v2/common/dashed-border.gif);

        label {
            background-image: url(#{$domain}/img/web-v2/common/categories.jpg);
        }
    }

    .events-list-new {
        .page {
            /* background-image: url(#{$domain}/img/web-v2/common/horizontal-border.gif); */
        }
    }

    a.free-tickets-horizontal-banner {
        background-image: url(#{$domain}/img/web-v2/common/free-tickets-horizontal-banner.jpg);
    }

    a.free-tickets-sidebar-banner {
        background-image: url(#{$domain}/img/web-v2/common/free-tickets-sidebar-banner.jpg);
    }

    label {
        input[type="checkbox"].circular:checked + span,
        input[type="radio"].circular:checked + span {
            background-image: url(#{$domain}/img/web-v2/common/tick-pink.jpg);
        }
    }

    form.credit-card {
        fieldset {
            div.icon {
                background-image: url(#{$domain}//web-v2/icons/credit-cards.png);
            }
        }
    }

    .featured-position {
        background-image: url(#{$domain}/img/web-v2/common/featured-positions.jpg);
    }

    .highlight-tooltip {
        & > div {
            div.icon {
                background-image: url(#{$domain}/img/web-v2/common/highlight-icons.png);
            }
        }
    }

    .language-flag {
        background-image: url(#{$domain}/img/web-v2/common/flags.png);

        &.shadowless {
            background-image: url(#{$domain}/img/web-v2/common/flags.png);
        }
    }

    .logotype.big {
        background-image: url(#{$domain}/img/web-v2/logo/logotype-big-blue.svg);

        &.white {
            background-image: url(#{$domain}/img/web-v2/logo/logotype-big-white.svg);
        }
    }

    .overlay-template {
        a.close {
            background-image: url(#{$domain}/img/web-v2/icons/close-white.gif);
        }
    }

    .round-buttons {
        a {
            background-image: url(#{$domain}/img/web-v2/common/round-buttons.png);
        }
    }

    ul.token-list {
        li {
            a {
                background-image: url(#{$domain}/img/web-v2/icons/remove-gray.gif);
            }
        }
    }

/* ITEMS LIST ------------------------------------- */

    ul.items-list {
        list-style-type: none;
        margin: 0px;
        width: 1000px;
    }

    ul.items-list li {
        color: #888;
        float: left;
        line-height: 16px;
        padding: 0px 20px 16px 0px;
        text-align: center;
    }

    ul.items-list li a.mask {
        display: block;
        height: 120px;
        width: 120px;

        -webkit-box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);
           -moz-box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);
                box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);
    }

    ul.items-list li a.mask img {
        height: inherit;
        width: inherit;
    }

    ul.items-list li a.title {
        color: #444;
        display: block;
        font-weight: bold;
        overflow: hidden;
        padding-top: 7px;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 120px;
    }

    ul.items-list li a.title:hover {
        color: #10345d;
        text-decoration: none;
    }

    ul.items-list li span {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 120px;
    }

    /*
   * CONTAINERS
   */
  .white-bg {
    border-radius: 2px;
    background-color: #fff;
    -webkit-box-shadow: 0px 2px 4px 0px rgba(26,27,27,0.05);
   -moz-box-shadow: 0px 2px 4px 0px rgba(26,27,27,0.05);
   box-shadow: 0px 2px 4px 0px rgba(26,27,27,0.05);
  }
  .with-border {
    border: 1px solid #e5e8ec;
  }
  
  .type-of-event {
    display: inline-block;
    padding: 2px 12px;
    background: #e4efff;
    border-radius: 32px;
  }
  .type-of-event span {
    color: rgba(130, 140, 150, 0.8);
    font-size: 0.735rem;
    text-transform: uppercase;
    font-weight: 600;
  }
  .type-of-event span.public {
    color: #10345D;
  }

}
