.event {
  padding-bottom: 50px;

  a.link {
    color: #2B7FE5;
    font-size: 0.935rem;
    font-weight: 600;
    letter-spacing: 0.1px;
  }
  a.link:hover {
    color: #10345D;
  }
  a.link.gray {
    color: rgba(130, 140, 150, 0.8);
  }
  a.link.gray:hover {
    color:rgba(69, 69, 68, 1);
  }
  a.link.sm {
    font-size: 0.835rem;
  }
  a.link-secondary {
    font-size: 0.735rem;
    letter-spacing: 1px;
    font-weight: 600;
    text-transform: uppercase;
    color: rgba(130, 140, 150, 0.5);
  }
  a.link-secondary:hover {
    color: rgba(130, 140, 150, 0.8);
  }

  /*
   * Paragraphs
   */
  p {
    font-size:1rem;
    color: rgba(120, 125, 130, 1);
    line-height: 150%;
  }
  p.smaller {
    font-size: 0.9rem;
  }
  p.meta {
    font-size: 0.835rem;
    letter-spacing: -0.2px;
    font-weight:400;
    color: rgba(120, 125, 130, 0.8);
    line-height: 150%;
  }
  p.meta.color {
      color: #294b71;
  }
  p.ev-main-text {
    color: rgba(120, 125, 130, 1);
    font-size: 0.9rem;
    line-height: 155%;
  }

  p.muted {
    color: #7F7F7F;
  }
  p.sm {
    font-size: 0.935rem;
  }
  p.xs {
    font-size: 0.835rem;
  }
  p.lg {
    font-size: 1.2rem;

  }

  /*
   * HEADINGS
   */
  h1 {
    font-size: 2rem;
    font-weight: 600;
    line-height: 130%;
    letter-spacing: -0.5px;
    color:#10345D;
  }
  h1.maintitle.smaller {
     font-size: 1.2rem;
  }
  h2 {
    font-size: 1.735rem;
    font-weight: 600;
    line-height: 125%;
    letter-spacing: -0.4px;
  }
  h2.smaller {
    font-size: 1rem;
  }
  h3 {
    font-size: 1.44rem;
    font-weight: 600;
    letter-spacing: -0.3px;
  }
  h4 {
    font-size: 1.4rem;
    font-weight: 400;
    letter-spacing: -0.6px;
  }
  h4.details-label {
    text-transform: uppercase;
    letter-spacing: 1.2px;
    font-size: 0.785rem;
    color:rgba(130, 140, 150, 0.8);
  }
  h5 {
    font-size: 0.95rem;
    font-weight: 500;
    letter-spacing: -0.3px;
  }
  h5.section-heading {
    font-size: 0.735rem;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  /*
   * CTA BOX on MOBILE
   */
  .cta-box-sm {
    margin-bottom: 50px;
    border-top: 1px solid #e5e8ec;
    background: #fff;
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    z-index: 999;
    -webkit-box-shadow: 0px -2px 18px 0px rgba(26,27,27,0.15);
    -moz-box-shadow: 0px -2px 18px 0px rgba(26,27,27,0.15);
    box-shadow: 0px -2px 18px 0px rgba(26,27,27,0.15);
   }


  /*
   * CTA BOX on DESKTOP
   */
  .cta-box {
      border-radius: 3px;
      transition: 0.3s all;
      background-color: #fff;
      border: 1px solid #fff;
  }
  .cta-box.cta-box-sm {
    border: 0;
    background-color:#fff;
    border: 1px solid #cfdaea;
  }
  .cta-box h1 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    font-size: 1.5rem;
  }
  .cta-box h2.subtitle {
    font-size: 1rem;
    color: rgba(120, 125, 130, 0.8);
    font-family: "Open Sans";
    font-weight: 400;
    letter-spacing: 0;
  }
  .price-line {
    color: rgba(120, 125, 130, 0.7);
    color: #608cbe;
  }
  .date-highlight {
    font-size: 1rem;
    text-transform: uppercase;
    color: #10345D;
    font-weight: 600;
    display: inline-block;
    border-right: 1px solid rgba(152, 183, 220, 0.3);
    padding: 0px;
  }

  .btn-primary:hover {
    background-color: #D60D6A;
    border-color: #D60D6A;
  }
  .cta-box:hover {
    background-color: #fff;
    -webkit-box-shadow: 0px 0px 12px 0px rgba(26,27,27,0.1);
   -moz-box-shadow: 0px 0px 12px 0px rgba(26,27,27,0.1);
   box-shadow: 0px 0px 12px 0px rgba(26,27,27,0.1);
  }

  /*
   * TAGS
   */
  a.tag-normal {
    background-color: #fff;
    border: 1px solid #f2f2f5;
    border-radius:3px;
    font-size: 0.835rem;
    font-weight: 600;
    color: rgba(69,69,68,0.8);
    padding: 12px 24px;
    margin-right: 0 3px 5px 0;
    transition: 0.3s all;
    height: 50px;
    display: inline-block;

  }
  a.tag-small {
    background-color: #fff;
    border: 1px solid #f2f2f5;
    border-radius:3px;
    font-size: 0.835rem;
    font-weight: 500;
    color: rgba(69,69,68,0.8);
    padding: 4px 12px;
    margin-top:4px;
    margin-right: 4px;
    transition: 0.3s all;
  }
  a.tag-normal.accent-blue, a.tag-small.accent-blue {
    border-color: #e4efff;
    background-color: #e4efff;
  }
  a.tag-normal.accent-blue,a.tag-small.accent-blue  {
    color: #2B7FE5;
    letter-spacing: 0.2px;
    font-weight: 0.935rem;
    font-weight: 600;
  }
  a.tag-normal:hover, a.tag-small:hover {
    background-color:#f2f2f5;
    text-decoration: none;
    color: rgba(69,69,68,1);
  }
}

.ev-rsvp--container {
  display: flex;
  /*justify-content: ;*/
}

.rsvp-btn {
  width: 100%;
  height: 50px;
  border-radius:0px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  transition: 0.25s all;
  margin-right: -1px;
  background-size: 20px;
  background-color: #fff;
  border:1px solid rgba(152, 183, 220, 0.2);
}

.rsvp-btn:hover {
  background-color: #f2f2f5;
}

.rsvp-btn.yes {
  border-radius: 3px 0 0 3px;
  background-repeat: no-repeat;
  background-position: center center;
}
.rsvp-btn-1 i {
  color: #6BCB7E;
}
.rsvp-btn.yes.selected {
  background-color: #12982b;
}

.rsvp-btn.maybe {
  background-repeat: no-repeat;
  background-position: center center;
}
.rsvp-btn-2 i {
  color: #F7B500;
}
.rsvp-btn.maybe.selected {
  background-color: #F6A131;
}

.rsvp-btn.cant {
  background-repeat: no-repeat;
  background-position: center center;
}
.rsvp-btn-3 i {
  color: #E62E83;
}

.rsvp-btn.cant.selected {
  background-color: #e62e83;
}

.rsvp-btn.no {
  border-radius: 0 3px 3px 0;
  background-repeat: no-repeat;
  background-position: center center;
}
.rsvp-btn-4 i {
  color: #F15151;
}
.rsvp-btn.no.selected {
  background-color: #10345d;
}
a.rsvp-btn.selected i {
  color: white !important;
}

.event-container {
  display: inline-block;
  background: #fff;
}

img.promoter-image {
  border: 1px solid #f2f2f5;
}
span.type-of-event-wrapper {

}

/*
 * styles made for the event page revamp
 */

.sticky-top {
  position: -webkit-sticky;
  position: sticky;
  z-index: 1;
  ul {
    display: inline-block;
    text-align: left;
    list-style: none;
    padding: 10px;
    color: #b2b2b2;
    font-size: 12px;

    li {
      padding: 5px;

      i {
        padding-right: 10px;
      }
    }
  }
}

.marquee { clear:both; max-width: 200px; }

.close-map-tip, #calendar-tooltip { display: none; }



.sidebar-widget {
    @include boxShadow(0px, 1px, 3px, rgba(0, 0, 0, 0.12));
}

.sidebar-widget#buy-tickets-widget {

    h3 {
        color: $lightGray0 !important;
        font-family: $sansSerif;
        font-size: 12px;
        line-height: 18px;
        padding: 11px 0px;
    }

    & > a.btn {
        background: $pink;
        color: $white;
        display: block;
        font-size: 16px;
        font-weight: bold;
        height: 40px;
        line-height: 38px;
        margin: 0px 10px;
        padding: 0px 15px;
        width: 180px;
        @include textOverflow();

        &:last-child {
            margin-bottom: 10px;
        }

        &.small-text {
            font-size: 14px;
        }
    }

    form {
        margin: 0px;
        overflow: hidden;
        display: none;
        @include transitions(e('height 0.25s ease 0.1s, opacity 0.1s linear'));

        &.edit-texts{
          height: 0;
          opacity: 0;
        }
        
        &.shown {
            height: 107px;
            opacity: 1.0;
            display: block;
            @include transitions(e('height 0.25s ease, opacity 0.1s linear 0.25s'));
        }

        * {
            @include subpixelAntialiased();
        }

        input {
            border: none;
            float: left;
            width: 200px;
            padding: 0px;
            box-shadow: none;
        }

        input + input {
            border-top: 1px solid $lightGray1;
            margin-bottom: 10px;
        }

        .btn {
            display: block;
            float: left;
            font-weight: bold;
            height: 30px;
            margin: 0px 0px 0px 10px;
            text-transform: uppercase;
            width: 210px;

            &.success {
                background: $success;
            }
        }
    }

    a.edit {
        display: block;
        font-size: 11px;
        height: 40px;
        line-height: 38px;

        span:first-child {
            color: $blue;
            display: inline-block;
        }

        span:last-child {
            color: $mediumGray;
            display: none;
        }

        &:hover {
            span:first-child {
                text-decoration: underline;
            }

            span:last-child {
                color: $lightGray0;
            }
        }
    }

    form.shown + a.edit {
        span:first-child {
            display: none;
        }

        span:last-child {
            display: inline-block;
        }
    }

    div.payment-methods {
        padding: 11px 0px 13px 0px;

        p {
            color: $mediumGray;
            line-height: 18px;
            margin-bottom: 0px;
        }

        img {
            height: 16px;
        }

        img + img {
            margin-left: 4px;
        }
    }

    a.btn + div.payment-methods {
        margin-top: 10px;
    }
}

.sidebar-widget#copromote-request-widget {
    background: transparent;
    margin: -10px 0px 10px 0px;
    text-align: center;
    @include boxShadowNone();

    a.toggle {
        color: $lightGray0;
        display: block;
        font-size: 11px;
        text-decoration: none;
        text-shadow: 0px 1px 0px $white;
        @include transition(color, 0.25s, linear);

        &.active,
        &:hover {
            color: $mediumGray;
        }
    }

    div.body {
        background: $darkBlue;
        color: $white;
        display: none;
        margin: 10px 0px 20px 0px;
        padding: 0px;
        min-height: 60px;
        position: relative;
        width: 230px;

        &:after {
            content: '';
            left: 50%;
            margin-left: -6px;
            position: absolute;
            top: -6px;
            @include arrowUp(12px, 6px, $darkBlue);
        }

        &.success {
            strong {
                background: url(#{$domain}/img/web-v2/common/success.png) no-repeat 93px 20px;
                background-size: 44px 44px;
                display: block;
                font-size: 14px;
                line-height: 18px;
                padding: 80px 10px 0px 10px;
            }

            span {
                color: $lightGray0;
                display: block;
                font-size: 11px;
                line-height: 16px;
                padding: 12px 10px 21px 10px;;
            }
        }

        a {
            text-decoration: none;
        }

        .btn {
            background: $gray;
            color: $mediumGray;
            cursor: default;
            height: 18px;
            margin: 0px 10px 10px 10px;
            padding-left: 0px;
            padding-right: 0px;
            position: relative;
            width: 210px;

            &.btn-primary {
                background: $blue;
                color: $white;
                cursor: pointer;
                font-weight: bold;
            }
        }

        span:first-child {
            color: $mediumGray;
            display: block;
            line-height: 40px;
        }

        input.search {
            background: $extraLightGray1;
            border: none;
            height: 20px;
            margin: 10px 0px;
            width: 200px;
            @include borderRadius(2px);
            @include insetBoxShadow(0px, 1px, 0px, $lightGray1);
        }

        ul {
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);
            border-top: 1px solid rgba(255, 255, 255, 0.1);
            list-style-type: none;
            margin: 0px 0px 10px 0px;
            max-height: 160px;
            overflow: auto;
            text-align: left;

            li {
                @include transition(background, 0.1s, linear);

                label {
                    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
                    margin-left: 38px;
                    padding: 6px 20px 6px 0px;
                    position: relative;

                    input[type="checkbox"].circular + span {
                        background-color: transparent;
                        border-color: $mediumGray;
                        left: -28px;
                        position: absolute;
                        top: 9px;
                    }

                    img {
                        float: left;
                        height: 24px;
                        width: 24px;
                    }

                    strong {
                        display: block;
                        line-height: 24px;
                        padding-left: 10px;
                        @include textOverflow();
                    }
                }

                &:last-child {
                    label {
                        border-bottom: none;
                    }
                }

                &.highlighted {
                    background: rgba(255, 255, 255, 0.15);
                }
            }
        }

        .no-promoters {
            strong {
                display: block;
                font-size: 14px;
                line-height: 18px;
                padding: 18px 10px 0px 10px;
            }

            a.refresh {
                color: $mediumGray;
                display: inline-block;
                font-size: 11px;
                padding: 9px 0px 15px 0px;
                @include transition(color, 0.25s, linear);

                &:hover {
                    color: $lightGray0;
                }
            }
        }
    }
}


.sidebar-widget#featured-widget {
    background-color: white;
    background-position: top center !important;
    background-repeat: no-repeat;
    height: 280px;
    position: relative;
    @include backgroundSize(cover);

    &:last-child {
        margin-bottom: 0px;
    }

    .cool {
        background: url(#{$domain}/img/web-v2/common/featured-widget-cool.png);
        background-size: 61px 61px;
        height: 61px;
        left: -1px;
        position: absolute;
        top: -1px;
        width: 61px;
    }

    .overlay {
        background: url(#{$domain}/img/web-v2/common/featured-widget-gradient.png) repeat-x 0px 170px;
        background-size: 1px 160px;
        color: $white;
        cursor: pointer;
        height: 280px;
        left: 0px;
        overflow: hidden;
        position: absolute;
        text-align: center;
        top: 0px;
        width: 230px;
        @include transition(all, 0.25s, ease-out);

        &:hover {
            background-color: rgba(0, 0, 0, 0.6);
            background-position: 0px 120px;

            div {
                bottom: 0px;
            }

            span.category,
            span.verified {
                opacity: 0;
            }
        }

        div {
            bottom: -45px;
            left: 0px;
            position: absolute;
            @include transition(bottom, 0.25s, ease-out);
        }

        span.category,
        span.verified {
            background: $blue;
            height: 23px;
            left: 0px;
            line-height: 24px;
            padding: 0px 15px;
            position: absolute;
            text-transform: uppercase;
            top: -23px;
            @include transition(opacity, 0.1s, ease-out);
        }

        span.verified {
            background: $pink;
        }

        strong {
            display: block;
            line-height: 18px;
            padding: 11px 15px 0px 15px;
            width: 200px;
        }

        span.details {
            color: $mediumGray;
            display: block;
            line-height: 18px;
            padding: 0px 15px 10px 15px;
            width: 200px;
            @include textOverflow();
        }

        a.action {
            border: 1px solid rgba(255, 255, 255, 0.25);
            color: $white;
            display: block;
            font-weight: bold;
            height: 28px;
            line-height: 28px;
            margin: 0px 15px 15px 15px;
            text-decoration: none;
            width: 198px;

            span:first-child {
                display: inline-block;
            }

            &.active {
                span:first-child {
                    display: none;
                }

                span:last-child {
                    display: inline-block;
                }
            }

            &.open:hover,
            &.open.active {
                background: $blue;
                border-color: $blue;
                color: $white;
            }

            &.rsvp:hover,
            &.rsvp.active {
                background: $rsvp1;
                border-color: $rsvp1;
                color: $white;
            }

            &.follow:hover,
            &.follow.active {
                background: $blue;
                border-color: $blue;
                color: $white;
            }
        }
    }
}
