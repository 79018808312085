@charset "UTF-8";

@media all {

/* WIDGETS ---------------------------------------- */



/* WIDGET: EVENTS --------------------------------- */

	section.events-widget div.body {
		padding: 0px;
	}

	section.events-widget div.item {
		height: auto;
		padding-top: 10px;
		overflow: hidden;
		position: relative;
	}

	section.events-widget div.item a.mask {
		display: block;
		height: 155px;
		width: 210px;
	}

	section.events-widget div.item a.mask img {
		min-height: 155px;
		width: 210px;
	}

	section.events-widget div.item a.category {
		background: #10345d;
		color: white;
		display: block;
		height: 19px;
		left: 10px;
		line-height: 20px;
		padding: 0px 10px;
		position: absolute;
		text-decoration: none;
		text-transform: uppercase;
		top: 146px;
	}

	section.events-widget div.item a.category:hover {
		background: #e62e83;
	}

	section.events-widget div.item a.event-title {
		color: #444;
		display: block;
		font-size: 12px;
		height: auto;
		line-height: 18px;
		padding: 8px 12px 8px 12px;
		text-decoration: none;
		width: 206px;
	}

	section.events-widget div.item a.event-title:hover {
		color: #10345d;
	}

	section.events-widget div.item span {
		color: #888;
		display: block;
		float: left;
		overflow: hidden;
		padding: 3px 0px 4px 12px;
		text-overflow: ellipsis;
		white-space: nowrap;
		width: 135px;
	}

	section.events-widget div.item time {
		color: #888;
		float: right;
		padding: 3px 10px 0px 0px;
	}

	section.events-widget div.paging {
		height: 30px;
		line-height: 33px;
	}

	section.events-widget div.paging a {
		background: #d2d2d2;
		display: inline-block;
		height: 10px;
		margin-right: 2px;
		width: 10px;

		-webkit-border-radius: 5px;
		   -moz-border-radius: 5px;
				border-radius: 5px;
	}

	section.events-widget div.paging a.active {
		background: #10345d;
	}

/* WIDGET: PEOPLE --------------------------------- */

	section.people-widget a {
		float: left;
		display: block;
		float: left;
		height: 51px;
		margin: 0px 2px 2px 0px;
		overflow: hidden;
		position: relative;
		width: 51px;
	}

	section.people-widget a.item * {

		-webkit-transition: top 0.1s ease-out;
		   -moz-transition: top 0.1s ease-out;
			 -o-transition: top 0.1s ease-out;
				transition: top 0.1s ease-out;
	}

	section.people-widget a.item img {
		height: 50px;
		width: 50px;
	}

	section.people-widget a.item span {
		background: #10345d;
		color: white;
		display: block;
		font-size: 11px;
		height: 17px;
		line-height: 17px;
		overflow: hidden;
		padding: 0px 3px;
		text-align: center;
		text-overflow: ellipsis;
		top: 51px;
		white-space: nowrap;
	}

	section.people-widget a.item:hover img {
		top: -10px;
	}

	section.people-widget a.item:hover span {
		top: 34px;
	}

	section.people-widget a.more {
		background: #10345d;
		color: #d0eef7;
		height: 38px;
		line-height: 13px;
		text-align: center;
		padding-top: 13px;
	}

	section.people-widget a.more:hover {
		color: white;
		text-decoration: none;
	}

	section.people-widget a.more span {
		color: white;
		display: inline-block;
		font-size: 16px;
		font-weight: bold;
		width: 51px;
	}
}
