@charset "UTF-8";

@media all {

/* PAGE: ADVANCED SEARCH -------------------------- */

	.advanced-search {
		padding-top: 40px;
	}

	.advanced-search section.forms {
		margin-bottom: 30px;
	}

	.advanced-search form p.description {
		line-height: 20px;
		margin: 0px;
		padding: 5px 20px 4px 0px;
	}

	.advanced-search form input {
		width: 928px;
	}

	.advanced-search form input.half-width {
		float: left;
		width: 448px;
	}

	.advanced-search form input.quarter-width {
		float: left;
		width: 208px;
	}

	.advanced-search form ul.token-input-list {
		float: left;
		margin-top: 10px;
		width: 460px;
	}

	.advanced-search form fieldset ul.token-input-list {
		float: none;
		margin-top: 0px;
		width: 940px;
	}

	.advanced-search section.results h1 {
		float: left;
		font-size: 20px;
		height: 30px;
		line-height: 18px;
		padding: 0px;
		width: 100%;
	}

	.advanced-search section.results h1 span {
		background: #f0f0f0;
		padding-right: 20px;
	}

	.advanced-search section.results ul.items-list {
		padding-bottom: 10px;
		width: 1001px;
	}

	.advanced-search section.results ul.items-list li:nth-child(6) {
		padding-right: 19px;
	}

	.advanced-search section.results ul.items-list li a.mask {
		height: 123px;
		width: 123px;
	}

	.advanced-search section.results ul.items-list li a.mask img {
		height: 123px;
		width: 123px;
	}

	.advanced-search section.results ul.items-list li a.title,
	.advanced-search section.results ul.items-list li span {
		width: 123px;
	}

	.advanced-search section.results ul.events li a.mask {
		height: 142px;
	}

	.advanced-search section.results ul.events li a.mask span {
		background: #10345d;
		color: white;
		display: block;
		height: 19px;
		line-height: 20px;
		text-align: center;
		text-transform: uppercase;
	}

	.advanced-search section.results ul.events li a.title {
		height: 32px;
		margin-bottom: 3px;
		padding-top: 7px;
		white-space: normal;
		width: 120px;
	}

	.advanced-search div.message {
		color: #888;
		font-size: 20px;
		padding: 30px 0px 38px 0px;
		text-align: center;
	}

	.advanced-search div.message:last-child {
		margin-bottom: 20px;
	}
}
