@charset "UTF-8";

@media all {

/* PAGE: EVENT / HOST ----------------------------- */

	div.co-promote {
		margin-bottom: 40px;
	}

	div.co-promote .item {
		background: white url('#{$domain}/img/web-v2/icons/warning-gray.jpg') no-repeat 0px 12px;
		background-size: 26px 16px;
		border-bottom: 1px solid #e2e2e2;
		height: 30px;
		margin-bottom: 0px;
		line-height: 30px;
		padding: 5px 0px 5px 36px;
	}

	div.co-promote .item:last-child {
		border-bottom: 0px;
	}

	div.co-promote .item div.text {
	}

	div.co-promote .item a.btn {
		margin-right: 5px;
		width: 87px;
	}

	div.co-promote .item a.btn-primary {
		width: 88px;
	}

	div.co-promote .item div.result {
		color: white;
		display: none;
		height: 30px;
		text-align: center;
		width: 220px;
	}

	div.co-promote .item div.result span {
		display: none;
	}

	div.co-promote .item div.result.accepted {
		background: #47d35f;
		display: block;
	}

	div.co-promote .item div.result.declined {
		background: #e33535;
		display: block;
	}

	div.co-promote .item div.result.accepted span:first-child {
		display: inline;
	}

	div.co-promote .item div.result.declined span:last-child {
		display: inline;
	}


	/* summary */

	div.host section.summary {

	}

		/* image */

		div.host section.summary div.image {
			overflow: hidden;
			position: relative;
		}

		div.host section.horizontal div.image img {
		}

		div.host section.vertical div.image {
		}

		div.host section.summary div.image form {
			height: 40px;
			left: 0px;
			margin-bottom: 0px;
			overflow: hidden;
			position: absolute;
			top: -40px;
			width: 100%;
			z-index: 1;

			-webkit-transition: top 0.2s ease-in-out;
			   -moz-transition: top 0.2s ease-in-out;
				 -o-transition: top 0.2s ease-in-out;
					transition: top 0.2s ease-in-out;
		}

		div.host section.summary div.image:hover form {
			top: 0px;
		}

		/* information */

		div.host section.summary div.information {

		}

		div.host section.vertical div.information {

		}

		/* verified */

		div.host section.summary a#verified-host {
			background: url('#{$domain}/img/web-v2/common/verified-host-big.jpg');
			background-size: 21px 21px;
			display: block;
			height: 21px;
			left: 19px;
			position: absolute;
			top: 17px;
			width: 21px;
		}

		/* hgroup */

		div.host section.summary hgroup {
			padding: 0px 20px 17px 20px;
		}

		div.host section.summary hgroup h1 {
			color: #444;
			font-size: 20px;
			line-height: 24px;
			padding: 16px 0px 0px 0px;
		}

		div.host section.summary hgroup.verified-host h1 {
			text-indent: 27px;
		}

		div.host section.summary hgroup h2 {
			color: #888;
			font-size: 14px;
			line-height: 18px;
			padding: 2px 0px 0px 0px;
		}

		/* table */

		div.host section.horizontal table {

		}

		div.host section.summary table.details {
			line-height: 22px;
			// margin: 14px 0px 0px 20px;
		}

		div.host section.horizontal table.details {
			margin-right: 20px;
			width: 460px;
		}

		div.host section.horizontal table.details {
			margin-bottom: 20px;
		}

		div.host section.vertical table.details  {
			margin-right: 20px;
		}

		div.host section.summary div.rating {
			margin-top: 4px;
		}

		/* actions */

		div.host section.horizontal div.actions {
		}

		div.host section.vertical div.actions {
		}

		div.host section.summary div.actions div.follow {

		}

		div.host section.vertical div.actions div.follow {
			width: 100%;
		}

		div.host section.summary div.actions div.follow a {
			color: white;
			display: block;
			font-weight: bold;
			line-height: 22px;
			text-align: center;
			text-decoration: none;
			text-transform: uppercase;
		}

		div.host section.summary div.actions div.follow a:hover {
			background: #e62e83;
		}

		div.host section.vertical div.actions div.follow a {
			width: 100%;
		}

		div.host section.summary div.actions div.follow a span.follow {
			display: inline-block;
		}

		div.host section.summary div.actions div.follow a span.unfollow {
			display: none;
		}

		div.host section.summary div.actions div.follow a span.following {
			display: none;
		}


		div.host section.summary div.actions div.follow a.following span.follow { display: none; }
		div.host section.summary div.actions div.follow a.following span.unfollow { display: none; }
		div.host section.summary div.actions div.follow a.following span.following { display: inline-block; }
		div.host section.summary div.actions div.follow a.following:hover span.unfollow { display: inline-block; }
		div.host section.summary div.actions div.follow a.following:hover span.following { display: none; }

		div.host section.summary div.actions .disabled {
			opacity: 0.5;
		}

/* -------------------------------------------------------------------------------------------------- */

		div.host section.summary div.actions div.social {
			margin: 20px 0px;
			position: relative;
		}

		div.host section.vertical div.actions div.social {
			height: 62px;
			width: 260px;
		}

			div.host section.vertical div.actions div.social .ey-share-balloon {
				left: 0px;
				position: absolute;
				top: 0px;
			}

			div.host section.vertical div.actions div.social .fb-like {
				left: 70px;
				position: absolute;
				top: 0px;
			}

			div.host section.vertical div.actions div.social .twitter-share-button {
				left: 126px;
				position: absolute !important;
				top: 0px;
			}

			div.host section.vertical div.actions div.social .g-plus-wrapper {
				left: 192px;
				position: absolute;
				top: 0px;
			}

		div.host section.horizontal div.actions div.social {
			height: 91px;
			margin-top: 10px;
			position: relative;
			width: 210px;
		}

			div.host section.horizontal div.actions div.social .ey-share-wide {
				left: 0px;
				position: absolute;
				top: 71px;
			}

			div.host section.horizontal div.actions div.social .fb-like {
				left: 20px;
				position: absolute;
				top: 0px;
			}

			div.host section.horizontal div.actions div.social .twitter-share-button {
				left: 76px;
				position: absolute !important;
				top: 0px;
			}

			div.host section.horizontal div.actions div.social .g-plus-wrapper {
				left: 142px;
				position: absolute;
				top: 0px;
			}

/* -------------------------------------------------------------------------------------------------- */

	/* description */

	div.host section.summary div.description {
		overflow: hidden;
	}

	div.host section.summary div.description div.text {
		overflow: hidden;
		padding: 0px 20px 18px 20px;
	}

	div.host section.horizontal div.description div.text {
		padding-top: 1px;
	}

	div.host section.summary div.description div.transition {
		-webkit-transition: height 0.2s ease-in-out;
		   -moz-transition: height 0.2s ease-in-out;
		     -o-transition: height 0.2s ease-in-out;
		   		transition: height 0.2s ease-in-out;
	}

	div.host section.summary div.description div.text p {
		margin: 0px;
		padding: 19px 0px 0px 0px;
		white-space: pre-wrap;
	}

	div.host section.summary div.description div.text p + p {
		padding-top: 12px;
	}

	div.host section.summary div.description a#full-description {
		border: 1px solid #e8e8e8;
		display: none;
		height: 23px;
		line-height: 23px;
		margin: 0px 20px 20px 20px;
		position: relative;
		text-align: center;
		text-decoration: none;
		width: 688px;
	}

	div.host section.summary div.description a#full-description:after {
		background: url('#{$domain}/img/web/white-gradient.png') repeat-x;
		bottom: 24px;
		content: '';
		display: block;
		height: 20px;
		left: -21px;
		position: absolute;
		width: 730px;
	}

	div.host section.summary div.description a#full-description:hover {
		background: #f8f8f8;
	}

	div.host section.summary div.description a#full-description span {
		background: url('#{$domain}/img/web/expand-arrows.gif') no-repeat top right;
		padding: 0px 12px 0px 6px;
	}

	div.host section.summary div.description a#full-description span.show {
		display: inline-block;
	}

	div.host section.summary div.description a#full-description span.hide {
		display: none;
	}

	div.host section.summary div.description a#full-description.collapse span {
		background-position: bottom right;
	}

	div.host section.summary div.description a#full-description.collapse span.show {
		display: none;
	}

	div.host section.summary div.description a#full-description.collapse span.hide {
		display: inline-block;
	}

	/* map */

	div.host section.summary div.map {
	}

	div.host section.summary div.map #map-text {
		display: none;
		line-height: 18px;
		min-height: 18px;
		overflow: hidden;
		padding: 17px 60px 15px 20px;
		position: relative;
		white-space: nowrap;
	}

	div.host section.summary div.map #collapse-map {
		background: url('#{$domain}/img/web/icon-close-gray.gif') no-repeat center center;
		height: 50px;
		margin-top: -25px;
		position: absolute;
		right: 20px;
		top: 50%;
		width: 42px;
	}

	div.host section.summary div.map #host-map-canvas {
		height: 100px;

		-webkit-transition: height 0.2s linear;
		   -moz-transition: height 0.2s linear;
		     -o-transition: height 0.2s linear;
		        transition: height 0.2s linear;
	}

	div.host section.summary div.map #expand-map {
		background: rgba(0, 0, 0, 0) url('#{$domain}/img/web/expand-map-arrow-blue.png') no-repeat center 100px;
		bottom: 0px;
		display: block;
		height: 100px;
		left: 0px;
		overflow: hidden;
		position: absolute;

		-webkit-transition: background 0.2s ease-out;
		   -moz-transition: background 0.2s ease-out;
		     -o-transition: background 0.2s ease-out;
		        transition: background 0.2s ease-out;
	}

	div.host section.summary div.map #expand-map:hover {
		background: rgba(0, 0, 0, 0.2) url('#{$domain}/img/web/expand-map-arrow-blue.png') no-repeat center bottom;
	}

	/* tags */	/* socials / contacts */

	div.host section.summary div.divider {
		height: 15px;
	}

	div.host section.summary div.socials {
		
	}

	div.host section.summary div.socials.v-align {
		margin-top: 17px;
	}

	div.host section.summary div.socials a {
		background: url('#{$domain}/img/web/host-socials.jpg');
		display: inline-block;
		height: 33px;
		margin-right: 5px;
		width: 46px;
	}

	div.host section.summary div.socials a.facebook { background-position: 0px 0px; }
	div.host section.summary div.socials a.facebook:hover { background-position: -46px 0px; }
	div.host section.summary div.socials a.google-plus { background-position: 0px -33px; }
	div.host section.summary div.socials a.google-plus:hover { background-position: -46px -33px; }
	div.host section.summary div.socials a.twitter { background-position: 0px -66px; }
	div.host section.summary div.socials a.twitter:hover { background-position: -46px -66px; }
	div.host section.summary div.socials a.mixcloud { background-position: 0px -99px; }
	div.host section.summary div.socials a.mixcloud:hover { background-position: -46px -99px; }
	div.host section.summary div.socials a.soundcloud { background-position: 0px -132px; }
	div.host section.summary div.socials a.soundcloud:hover { background-position: -46px -132px; }

	div.host section.summary ul.contacts {
		list-style-type: none;
	}

	div.host section.summary ul.contacts.v-align {
		margin-top: 12px;
	}

	div.host section.summary ul.contacts li {
		height: 22px;
		line-height: 22px;
		overflow: hidden;
	}

	/* host events */

	div.host section.host-events {
	}

		div.host section.host-events a.more {
			color: #888;
			display: block;
			height: 30px;
			line-height: 30px;
		}

		div.host section.host-events a.more:hover {
			color: #10345d;
			text-decoration: none;
		}

	/* facebook comments */

	div.host section.facebook-comments {
	}

	div.host section.facebook-comments div.fb-comments {
		margin: 20px 20px 5px 20px;
	}

	/* feed */

	div.host section.feed {
	}
		/* no-items */
		div.host section.feed div.no-items {
			color: #888;
			line-height: 18px;
			padding: 27px 0px 25px 0px;
			text-align: center;
		}

		div.host section.feed div.no-items strong {
			color: #444;
			font-size: 14px;
		}

		/* item */

		div.host section.feed div.item {
			min-height: 92px;
			padding: 21px 20px 13px 125px;
			position: relative;
		}

		div.host section.feed div.item:first-child {
			background: none;
			padding-top: 20px;
		}

		div.host section.feed div.item a.mask {
			display: block;
			height: 85px;
			left: 20px;
			position: absolute;
			top: 20px;
			width: 85px;
		}

		div.host section.feed div.item a.mask span {
			background: #10345d;
			bottom: -20px;
			color: white;
			display: block;
			font-size: 11px;
			height: 20px;
			left: 0px;
			line-height: 20px;
			overflow: hidden;
			padding: 0px 5px;
			position: absolute;
			text-align: center;
			text-overflow: ellipsis;
			white-space: nowrap;
			width: 85px;

			-webkit-transition: bottom 0.2s ease-out;
			   -moz-transition: bottom 0.2s ease-out;
				 -o-transition: bottom 0.2s ease-out;
					transition: bottom 0.2s ease-out;
		}

		div.host section.feed div.item:hover a.mask span {
			bottom: 0px;
		}

		div.host section.feed div.item a.no-user {
			opacity: 0.4;
		}

		div.host section.feed div.item strong {
		}

		div.host section.feed div.discussions div.item strong {
			margin-bottom: 7px;
		}

		div.host section.feed div.item time {
			color: #888;
		}

		div.host section.feed div.item div.rating {
			margin: 1px 0px 14px 0px;
		}

		div.host section.feed div.item p {
			color: #444;
			margin: 0px;
		}

		/* paging */

		div.host section.feed div.paging a {
			color: #444;
		}

		div.host section.feed div.paging a.active {
			color: #10345d;
		}

		div.host section.feed div.paging span {
			color: #d2d2d2;
			padding: 0px 4px;
		}

		div.host section.feed div.paging + form {
			background: none;
			padding-top: 0px;
		}

		/* form */

		div.host section.feed form.standard {
				padding-top: 1px;
		}

		div.host section.feed form.standard h2 {
			font-size: 20px;
			height: 54px;
			line-height: 54px;
			text-indent: 20px;
		}

		div.host section.feed form.standard fieldset {
			margin: 0px 0px 0px 20px;
		}

		div.host section.feed form.standard fieldset input {
		}

		div.host section.feed div.discussions form.standard fieldset input {
		}

		div.host section.feed form.standard fieldset select {
			border-left: none;
			width: 210px;
		}

		div.host section.feed form.standard fieldset textarea {
			border-top: 0px;
			height: 50px;
			margin-top: 0px;
		}

		div.host section.feed form.standard fieldset textarea:first-child {
			border-top: 1px solid #d2d2d2;
		}

		div.host section.feed form.standard div.actions {
			background: none;
			padding-top: 20px;
		}
/* WIDGET: PEOPLE RSVP ---------------------------- */

	section.people-rsvp-widget {
		position: relative;
	}

	section.people-rsvp-widget div.body {
		padding: 10px 10px 0px 10px;
	}

	section.people-rsvp-widget ul {
		list-style-type: none;
		margin: 0px;
	}

	section.people-rsvp-widget ul li {
		color: #888;
		height: 44px;
		line-height: 15px;
		padding: 6px 0px 0px 50px;
		position: relative;
		width: 160px;
	}

	section.people-rsvp-widget ul li img {
		height: 40px;
		left: 0px;
		position: absolute;
		top: 0px;
		width: 40px;
	}

	section.people-rsvp-widget ul li a.guest {
		font-weight: bold;
	}

	section.people-rsvp-widget ul li span {
		background: white;
		font-size: 11px;
	}

	section.people-rsvp-widget ul li a.remove {
		background: url('#{$domain}/img/web/icon-remove-gray.gif') no-repeat center center;
		display: block;
		height: 16px;
		position: absolute;
		right: 6px;
		top: 12px;
		width: 16px;
	}

	section.people-rsvp-widget div.paging {
		height: 30px;
		line-height: 33px;
		margin-bottom: 10px;
	}

	section.people-rsvp-widget div.paging a {
		background: #d2d2d2;
		display: inline-block;
		height: 10px;
		margin-right: 2px;
		width: 10px;

		-webkit-border-radius: 5px;
		   -moz-border-radius: 5px;
				border-radius: 5px;
	}

	section.people-rsvp-widget div.paging a.active {
		background: #10345d;
	}

	section.people-rsvp-widget form input {
		margin: 0px 0px 10px 10px;
		width: 198px;
	}

/* WIDGET: HOSTS ---------------------------------- */

	section.hosts-widget div.body {
		padding: 0px;
	}

	section.hosts-widget a.host {
		display: block;
		margin: 0px 0px 0px 10px;
		text-align: center;
		width: 210px;
	}

	section.hosts-widget a.host div.mask {
		margin-top: 10px;
		max-height: 220px;
	}

	section.hosts-widget a.host img {
		width: 210px;
	}

	section.hosts-widget a.host strong {
		display: inline-block;
		height: 35px;
		line-height: 36px;
		max-width: 210px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	section.hosts-widget a.host strong.verified {
		background: url('#{$domain}/img/web/verified-host-small.png') no-repeat center left;
		padding: 0px 2px 0px 18px;
		max-width: 190px;
	}

	section.hosts-widget a.host:hover strong {
		text-decoration: underline;
	}

	section.hosts-widget ul {
		height: auto;
		list-style-type: none;
		margin: 0px !important;
		text-align: left;
		width: 230px;
	}

	section.hosts-widget ul li.head {
		color: #888;
		height: 30px;
		line-height: 30px;
		margin-bottom: 10px;
		padding: 0px;
		text-align: center;
		width: 230px;
	}

	section.hosts-widget ul li {
		height: 44px;
		line-height: 15px;
		padding: 6px 0px 0px 60px;
		position: relative;
		width: 170px;
	}

	section.hosts-widget ul li img {
		height: 40px;
		left: 10px;
		position: absolute;
		top: 0px;
		width: 40px;
	}

	section.hosts-widget ul li a.cohost {
		display: inline-block;
		font-weight: bold;
		left: 60px;
		max-width: 150px;
		overflow: hidden;
		position: absolute;
		text-overflow: ellipsis;
		top: 6px;
		white-space: nowrap;
	}

	section.hosts-widget ul li a.cohost.verified {
		background: url('#{$domain}/img/web/verified-host-small.png') no-repeat top left;
		max-width: 132px;
		padding-left: 18px;
	}

	section.hosts-widget ul li span {
		color: #888;
		display: inline-block;
		font-size: 11px;
		left: 60px;
		position: absolute;
		top: 21px;
	}

/* WIDGET: MANAGE EVENT --------------------------- */

	section.manage-event-widget div.body {
		padding-bottom: 0px;
	}

	section.manage-event-widget .featured-campaigns {
		position: relative;
	}

	section.manage-event-widget .featured-campaigns a {
		position: absolute;
		top: 10px;

        -webkit-transition: -webkit-transform 0.25s ease-in-out 0.25s, background-color 0.2s linear 0.25s;
           -moz-transition: -moz-transform 0.25s ease-in-out 0.25s, background-color 0.2s linear 0.25s;
                transition: transform 0.25s ease-in-out 0.25s, background-color 0.2s linear 0.25s;
	}

	section.manage-event-widget .featured-campaigns a.new-campaign {
		left: 93px;
	}

	section.manage-event-widget .featured-campaigns a.campaigns-report {
		left: 117px;

		-webkit-transform: scale(0, 0);
		   -moz-transform: scale(0, 0);
		        transform: scale(0, 0);
	}

	section.manage-event-widget .featured-campaigns.available a.new-campaign {
		-webkit-transform: translateX(-25px);
		   -moz-transform: translateX(-25px);
		        transform: translateX(-25px);
	}

	section.manage-event-widget .featured-campaigns.available a.campaigns-report {
		-webkit-transform: scale(1.0, 1.0);
		   -moz-transform: scale(1.0, 1.0);
		        transform: scale(1.0, 1.0);
	}

	section.manage-event-widget .featured-campaigns.available.bounce a.new-campaign {
		-webkit-transform: translateX(-32px);
		   -moz-transform: translateX(-32px);
		        transform: translateX(-32px);
	}

	section.manage-event-widget .featured-campaigns.available.bounce a.campaigns-report {
		background-color: #47d35f;
		-webkit-transform: scale(1.3, 1.3);
		   -moz-transform: scale(1.3, 1.3);
		        transform: scale(1.3, 1.3);
	}

	section.manage-event-widget a.main-host {
		display: block;
		height: 35px;
		overflow: hidden;
		padding: 1px 10px 0px 10px;
		text-align: center;
		text-overflow: ellipsis;
		white-space: nowrap;
		width: 210px;
	}

	section.manage-event-widget a.main-host strong {
		display: inline-block;
		height: 35px;
		line-height: 36px;
		max-width: 210px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	section.manage-event-widget a.main-host strong.verified {
		background: url('#{$domain}/img/web/verified-host-small.png') no-repeat center left;
		padding: 0px 2px 0px 18px;
		max-width: 190px;
	}

	section.manage-event-widget a.main-host:hover strong {
		text-decoration: underline;
	}

	section.manage-event-widget a.no-border {
		background: none;
		padding-top: 0px;
	}

	section.manage-event-widget ul {
		list-style-type: none;
		margin: 0px;
	}

	section.manage-event-widget ul li {
		height: 44px;
		line-height: 15px;
		padding: 6px 0px 0px 50px;
		position: relative;
		width: 160px;
	}

	section.manage-event-widget ul li img {
		height: 40px;
		left: 0px;
		position: absolute;
		top: 0px;
		width: 40px;
	}

	section.manage-event-widget ul li.not-verified img {
		opacity: 0.5;
	}

	section.manage-event-widget ul li a.cohost {
		display: inline-block;
		font-weight: bold;
		max-width: 130px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	section.manage-event-widget ul li a.cohost.verified {
		background: url('#{$domain}/img/web/verified-host-small.png') no-repeat top left;
		max-width: 112px;
		padding-left: 18px;
	}

	section.manage-event-widget ul li.not-verified a.cohost {
		opacity: 0.5;
	}

	section.manage-event-widget ul li a.permissions,
	section.manage-event-widget ul li span.location {
		color: #888;
		font-size: 11px;
		text-decoration: none;
	}

	section.manage-event-widget ul li.not-verified a.permissions,
	section.manage-event-widget ul li.not-verified span.location {
		opacity: 0.5;
	}

	section.manage-event-widget ul li div.dropdown-menu {
		left: -215px;
		margin: 0px;
		padding: 0px;
		position: absolute;
		top: -34px;
		width: 200px;
	}

	section.manage-event-widget ul li div.dropdown-menu:before {
		content: url('#{$domain}/img/web/dropdown-arrow-right.gif');
		left: 200px;
		position: absolute;
		top: 44px;
	}

	section.manage-event-widget ul li div.dropdown-menu strong {
		background: #e62e83;
		color: white;
		display: block;
		height: 24px;
		line-height: 24px;
		text-align: center;
	}

	section.manage-event-widget ul li div.dropdown-menu label {
		border-bottom: 1px solid #e8e8e8;
		padding: 5px 10px 4px 7px;
	}

	section.manage-event-widget ul li div.dropdown-menu label:last-child {
		border-bottom: none;
	}

	section.manage-event-widget ul li a.remove {
		background: url('#{$domain}/img/web/icon-remove-gray.gif') no-repeat center center;
		display: block;
		height: 16px;
		position: absolute;
		right: 6px;
		top: 12px;
		width: 16px;
	}

	section.manage-event-widget form {
		height: 40px;
		/*margin-bottom: 0px;*/
		width: 210px;
	}

	section.manage-event-widget form input {
		margin-bottom: 10px;
		/*margin-bottom: 0px;*/
		width: 198px;
	}

	section.manage-event-widget form button {
		width: 210px;
	}

	section.manage-event-widget a.sponsia {
		background: url('#{$domain}/img/web/partners/sponsia.png') no-repeat 65px 15px;
		color: #888;
		display: block;
		height: auto;
		line-height: 16px;
		padding: 69px 0px 11px 0px;
		text-align: center;
		text-decoration: none;
		width: 230px;
	}

/* WIDGET: PAST EVENTS ---------------------------- */

	section.past-events-widget div.body {
		padding: 10px 10px 0px 10px;
	}

	section.past-events-widget ul {
		list-style-type: none;
		padding: 0px;
	}

	section.past-events-widget ul li {
		color: #d2d2d2;
		font-size: 11px;
		height: 44px;
		line-height: 15px;
		padding: 6px 0px 0px 50px;
		position: relative;
		width: 160px;
	}

	section.past-events-widget ul li img {
		height: 40px;
		left: 0px;
		position: absolute;
		top: 0px;
		width: 40px;
	}

	section.past-events-widget ul li a {
		display: block;
		font-size: 12px;
		font-weight: bold;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		width: 150px;
	}

	section.past-events-widget ul li span.details {
		display: block;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		width: 150px;
	}

	section.past-events-widget ul li span.details span {
		padding: 0px 4px;
	}

	section.past-events-widget div.paging {
		height: 30px;
		line-height: 33px;
	}

	section.past-events-widget div.paging a {
		background: #d2d2d2;
		display: inline-block;
		height: 10px;
		margin-right: 2px;
		width: 10px;

		-webkit-border-radius: 5px;
		   -moz-border-radius: 5px;
				border-radius: 5px;
	}

	section.past-events-widget div.paging a.active {
		background: #10345d;
	}
}

@media (-webkit-min-device-pixel-ratio: 2), (min--moz-device-pixel-ratio: 2), (-o-min-device-pixel-ratio: 2), (min-device-pixel-ratio: 2) {
	div.co-promote .item { background-image: url('#{$domain}/img/web-v2/icons/warning-gray@2x.jpg'); }
	div.event div.linked-events nav a.prev { background-image: url('#{$domain}/img/web-v2/icons/arrow-left-white@2x.gif'); }
	div.event div.linked-events nav a.next { background-image: url('#{$domain}/img/web-v2/icons/arrow-right-white@2x.gif'); }
	div.event div.linked-events nav a.expand { background-image: url('#{$domain}/img/web-v2/icons/arrow-bottom-white@2x.gif'); }

	div.event section.summary a.tickets.evedo {
		background-image: url('#{$domain}/img/web-v2/tickets/evedo@2x.jpg');
	}

	div.event section.summary a.tickets.ticketstream {
		background-image: url('#{$domain}/img/web-v2/tickets/ticketstream@2x.jpg');
	}

	div.host section.summary a#verified-host {
		background-image: url('#{$domain}/img/web-v2/common/verified-host-big@2x.jpg');
	}

	div.event section.summary div.response a.rsvp-1 {
		background-image: url('#{$domain}/img/web-v2/icons/rsvp-1@2x.png');
	}

	div.event section.summary div.response a.rsvp-2 {
		background-image: url('#{$domain}/img/web-v2/icons/rsvp-2@2x.png');
	}

	div.event section.summary div.response a.rsvp-3 {
		background-image: url('#{$domain}/img/web-v2/icons/rsvp-3@2x.png');
	}

	div.event section.summary div.response a.rsvp-4 {
		background-image: url('#{$domain}/img/web-v2/icons/rsvp-4@2x.png');
	}
}
