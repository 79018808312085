@charset "UTF-8";

@media all {

/* PAGE: MESSAGES --------------------------------- */

	// div.messages {
	// 	padding-top: 80px;
	// }

	div.messages .shadow {
		-webkit-box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.12);
		   -moz-box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.12);
				box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.12);
	}

	div.messages section.panel {
		overflow: hidden;
		position: relative;
	}

	div.messages section.panel form {
		background: white;
		margin: 0px;
		padding: 0px 10px;
		position: absolute;
		z-index: 1;

		-webkit-transition: box-shadow 0.2s linear;
		   -moz-transition: box-shadow 0.2s linear;
			 -o-transition: box-shadow 0.2s linear;
				transition: box-shadow 0.2s linear;
	}

	div.messages section.panel form input {
		width: 198px;
	}

	div.messages section.panel ul {
		height: auto;
		padding: 0;
		margin: 70px 0px 0px 0px;
		overflow-x: hidden;
		overflow-y: auto;
	}

	div.messages section.panel ul li {
		border-top: 1px solid #e2e2e2;
		list-style-type: none;
	}

	div.messages section.panel ul li a {
		background: white;
		color: #444;
		display: block;
		padding: 10px;
		position: relative;
		text-decoration: none;
	}

	div.messages section.panel ul li a:hover {
		background: #f8f8f8;
	}

	div.messages section.panel ul li a.active {
		background: #10345d;
		color: white;
	}

	div.messages section.panel ul li a img {
		height: 40px;
		width: 40px;
	}

	div.messages section.panel ul li a strong,
	div.messages section.panel ul li a span.text,
	div.messages section.panel ul li a span.count {
		display: inline-block;
		position: absolute;
	}

	div.messages section.panel ul li a strong {
		left: 60px;
		top: 14px;
	}

	div.messages section.panel ul li a span.text {
		color: #888;
		font-size: 11px;
		left: 60px;
		top: 29px;
	}

	div.messages section.panel ul li a.active span.text {
		color: rgba(255, 255, 255, 0.75);
	}

	div.messages section.panel ul li a span.count {
		background: #e62e83;
		color: white;
		font-size: 10px;
		height: 15px;
		line-height: 15px;
		padding: 0px 5px;
		right: 20px;
		text-align: center;
		top: 22px;

		-webkit-border-radius: 2px;
		   -moz-border-radius: 2px;
				border-radius: 2px;
	}

	div.messages section.panel ul li a.active span.count {
		background: white;
		color: #10345d;
	}

	div.messages section.conversation {
		min-height: 400px;
		overflow: hidden;
		position: relative;
	}

	div.messages section.conversation div.message {
		color: #d2d2d2;
		font-family: Georgia, Times New Roman, serif;
		font-size: 20px;
		line-height: 24px;
		padding-top: 50px;
		text-align: center;
	}

	div.messages section.conversation div.head {
		background: white;
		border-bottom: 1px solid #e2e2e2;
		color: #888;
		font-size: 12px;
		left: 0px;
		line-height: 18px;
		padding: 10px;
		position: absolute;
		top: 0px;
		z-index: 1;

		-webkit-transition: box-shadow 0.2s linear;
		   -moz-transition: box-shadow 0.2s linear;
			 -o-transition: box-shadow 0.2s linear;
				transition: box-shadow 0.2s linear;
	}

	div.messages section.conversation div.head.shadow {
		border-bottom: none;
	}

	div.messages section.conversation div.head a.image {
		float: left;
		margin-right: 10px;
	}

	div.messages section.conversation div.head a.image img {
		height: 50px;
		width: 50px;
	}

	div.messages section.conversation div.head span {
		display: block;
		margin-top: 7px;
	}

	div.messages section.conversation div.head a {
		color: #444;
		font-size: 14px;
		font-weight: bold;
		text-decoration: none;
	}

	div.messages section.conversation div.head a:hover {
		color: #10345d;
	}

	div.messages section.conversation div.messages-box {
		bottom: 91px;
		left: 0px;
		overflow-x: hidden;
		overflow-y: auto;
		position: absolute;
	}

	div.messages section.conversation div.messages-box div {
		border-top: 1px solid #e8e8e8;
		line-height: 20px;
		overflow: hidden;
		padding: 10px 0px 9px 20px;
	}

	div.messages section.conversation div.messages-box div.out {
		background: #f8f8f8;
	}

	div.messages section.conversation div.messages-box div.in + div.in,
	div.messages section.conversation div.messages-box div.out + div.out {
		border-top: none;
		padding-top: 0px;
	}

	div.messages section.conversation div.messages-box div.in + div.in strong,
	div.messages section.conversation div.messages-box div.out + div.out strong {
		display: none;
	}

	div.messages section.conversation div.messages-box div.in + div.in p,
	div.messages section.conversation div.messages-box div.out + div.out p,
	div.messages section.conversation div.messages-box div.in + div.in time,
	div.messages section.conversation div.messages-box div.out + div.out time {
		border-top: 1px solid #f0f0f0;
	}

	div.messages section.conversation div.messages-box div strong {
		display: block;
	}

	div.messages section.conversation div.messages-box div p {
		margin: 0px;
	}

	div.messages section.conversation div.messages-box div time {
		color: #888;
		font-size: 11px;
		text-align: right;
		width: 105px;
	}

	div.messages section.conversation div.messages-box div time span {
		opacity: 0;
		padding-right: 2px;

		-webkit-transition: opacity 0.1s linear;
		   -moz-transition: opacity 0.1s linear;
			 -o-transition: opacity 0.1s linear;
				transition: opacity 0.1s linear;
	}

	div.messages section.conversation div.messages-box div:hover time span {
		opacity: 1.0;
	}

	div.messages section.conversation form {
		background: white;
		border-top: 1px solid #e2e2e2;
		bottom: 0px;
		left: 0px;
		margin: 0px;
		padding: 10px;
		position: absolute;
	}

	div.messages section.conversation form textarea {
		resize: none;
	}
}
