@charset "UTF-8";
/* VARIABLES ------------------------------------------- */
/* COLORS ---------------------------------------------- */
/* old blue: #36c3e4; */
/* old pink: #f216a1; */
/* FONTS ----------------------------------------------- */
/* EASINGS --------------------------------------------- */
/* ANIMATIONS ------------------------------------------ */
/* ARROWS ---------------------------------------------- */
/* BACKGROUND SIZE ------------------------------------- */
/* BORDER BOX ------------------------------------------ */
/* BORDER RADIUS --------------------------------------- */
/* BOX SHADOWS ----------------------------------------- */
/* FONT SMOOTHING -------------------------------------- */
/* STICKY ---------------------------------------------- */
/* TEXT ------------------------------------------------ */
/* TRANSFORMATIONS ------------------------------------- */
/* TRANSITIONS ----------------------------------------- */
@media all {

  /* PAGE: YARD ------------------------------------- */
  /* EVENTS REQUEST */
  .events-request {
    background: #81d0b3;
    color: #ffffff;
    overflow: hidden;
    position: relative;
    text-align: center;
  }
  .events-request .center {
    padding: 34px 0px 40px 0px;
  }
  .events-request h1 {
    font-family: "Open Sans", Arial, sans-serif;
    font-weight: normal;
    padding-bottom: 6px;
    color: #fff
  }
  .events-request p {
    padding-bottom: 14px;
  }
  .events-request .btn-primary {
    font-size: 18px;
    font-weight: bold;
    line-height: 28px;
  }
  .events-request .btn-primary + .btn-primary {
    margin-left: 20px;
  }
  .events-request a.request span {
    display: inline-block;
  }
  .events-request a.request span:last-child {
    display: none;
  }
  .events-request a.sent {
    background: rgba(0, 0, 0, 0.25) !important;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
  .events-request a.sent span {
    display: none;
  }
  .events-request a.sent span:last-child {
    background: url('#{$domain}/img/web-v2/icons/tick-white.png') no-repeat 0px 10px;
    background-size: 12px 10px;
    display: inline-block;
    padding: 0px 15px 0px 20px;
  }
  .events-request a.dismiss {
    background: url('#{$domain}/img/web-v2/icons/close-white.gif') no-repeat center center;
    background-size: 12px 12px;
    display: block;
    height: 50px;
    position: absolute;
    right: 0px;
    top: 0px;
    width: 50px;
  }
  /* BASIC LAYOUT */
  .yard {
    padding: 80px 0px;
  }
  .yard aside {
    float: left;
  }
  .yard aside .sticky {
    top: 90px;
  }
  .yard aside + section {
    float: right;
  }
  /* REMINDER */
  .yard .reminder {
    background: #10345d;
    border-top: 5px solid #10345d;
    color: #ffffff;
    height: 95px;
  }
  .yard .reminder.recommend {
    text-align: center;
  }
  .yard .reminder div.title {
    color: #ffffff;
    height: 25px;
    line-height: 160%;
    text-align: center;
  }
  .yard .reminder.rsvp-1 {
    border-top-color: #12982b;
  }
  .yard .reminder.rsvp-1 div.title {
    color: #12982b;
  }
  .yard .reminder.rsvp-2 {
    border-top-color: #f6a131;
  }
  .yard .reminder.rsvp-2 div.title {
    color: #f6a131;
  }
  .yard .reminder.rsvp-3 {
    border-top-color: #e62e83;
  }
  .yard .reminder.rsvp-3 div.title {
    color: #e62e83;
  }
  .yard .reminder a.btn {
    background: #444444;
    color: #b2b2b2;
    margin-top: 20px;
    padding-left: 15px;
    padding-right: 15px;
    width: auto;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    border-radius: 15px;
  }
  .yard .reminder a.btn:hover {
    background: #e62e83;
    color: #ffffff;
  }
  .yard .reminder a.event {
    color: #ffffff;
    display: block;
    height: 60px;
    line-height: 18px;
    padding: 10px 20px 0px 10px;
    text-decoration: none;
  }
  .yard .reminder a.event img {
    float: left;
    height: 50px;
    margin-right: 10px;
    width: 50px;
  }
  .yard .reminder a.event strong,
  .yard .reminder a.event span {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .yard .reminder a.event strong {
    margin-top: 8px;
  }
  /* MENU */
  .yard .sidebar-menu {
    border-top: 0px solid #111111;
    border: 0;
  }
  .yard .sidebar-menu a#search .icon {
    background: url('#{$domain}/img/web-v2/yard/search.png') no-repeat center center;
    background-size: 18px 18px;
  }

  .yard .sidebar-menu a#search.active .icon {
    background-image: url('#{$domain}/img/web-v2/yard/search-active.png');
  }
  .yard .sidebar-menu .group {
    border-bottom: 1px solid rgba(255, 255, 255, 0.08);
  }
  .yard .sidebar-menu .group a .icon {
    background: url('#{$domain}/img/web-v2/yard/rearrange-streams.gif') no-repeat center center;
    background-size: 12px 10px;
    cursor: move;
    opacity: 0;
    -webkit-transition: opacity 0.2s ease-in-out 0s;
    -moz-transition: opacity 0.2s ease-in-out 0s;
    transition: opacity 0.2s ease-in-out 0s;
  }
  .yard .sidebar-menu .group a.active .icon {
    background-image: url('#{$domain}/img/web-v2/yard/rearrange-streams-active.gif');
  }
  .yard .sidebar-menu .group a:last-child:after {
    display: none;
  }
  .yard .sidebar-menu .group .sorting-placeholder {
    background: #e62e83;
    border-bottom: 12px solid #10345d;
    border-top: 12px solid #10345d;
    height: 6px;
    width: 190px;
  }
  .yard .sidebar-menu .group:hover a .icon {
    opacity: 1.0;
  }
  .yard .sidebar-menu .group.disable-sorting a .icon {
    display: none;
  }
  .yard .sidebar-menu a#add .icon {
    background: url('#{$domain}/img/web-v2/yard/add.png') no-repeat center center;
    background-size: 18px 18px;
  }

  .yard .sidebar-menu a#add.active .icon {
    background-image: url('#{$domain}/img/web-v2/yard/add-active.png');
  }
  /* FEATURED YARD HEAD */
  .yard .yard-head {
    background: #ffffff;
    height: 100px;
    margin-bottom: 30px;
    overflow: hidden;
    position: relative;
  }
  .yard .yard-head img {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
  }
  .yard .yard-head a {
    display: block;
    height: 100px;
    left: 0px;
    position: absolute;
    text-decoration: none;
    top: 0px;
    z-index: 1;
  }
  .yard .yard-head a strong {
    display: block;
    font-size: 16px;
    font-weight: bold;
    margin: 28px 50px 0px 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .yard .yard-head a span {
    display: block;
  }
  /* STREAM */
  .yard #stream header {
    height: 80px;
    margin-bottom: 20px;
    position: relative;
  }
  .yard #stream header h1,
  .yard #stream header h2 {
    font-size: 18px;
    line-height: 15px;
    text-align: center;
  }
  .yard #stream header h1 {
    font-weight: 500;
    font-size: 38px;
    line-height: 150%;
  }
  .yard #stream header h2 {
    color: #888888;
    height: 14px;
    font-size: 12px;
  }
  .yard #stream header .buttons {
    height: 30px;
    position: absolute;
    right: 0px;
    text-align: right;
    top: 0px;
  }
  .yard #stream header .buttons a {
    background: #d2d2d2 no-repeat center center;
    display: block;
    float: left;
    height: 30px;
    margin-left: 1px;
    position: relative;
    width: 40px;
  }
  .yard #stream header .buttons a:first-child {
    -webkit-border-radius: 2px 0px 0px 2px;
    -moz-border-radius: 2px 0px 0px 2px;
    border-radius: 2px 0px 0px 2px;
  }
  .yard #stream header .buttons a:last-child {
    -webkit-border-radius: 0px 2px 2px 0px;
    -moz-border-radius: 0px 2px 2px 0px;
    border-radius: 0px 2px 2px 0px;
  }
  .yard #stream header .buttons a:first-child:last-child {
    -webkit-border-radius: 2px 2px 2px 2px;
    -moz-border-radius: 2px 2px 2px 2px;
    border-radius: 2px 2px 2px 2px;
  }
  .yard #stream header .buttons a:hover {
    background-color: #c2c2c2;
  }
  .yard #stream header .buttons a.active {
    background-color: #b2b2b2;
    -webkit-box-shadow: inset 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: inset 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
    box-shadow: inset 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
  }
  .yard #stream header .buttons a.active:before {
    content: '';
    left: 50%;
    margin-left: -9px;
    position: absolute;
    top: 41px;
    z-index: 1;
    border-color: transparent transparent rgba(0, 0, 0, 0.05) transparent;
    border-style: solid;
    border-width: 0px 9px 9px 9px;
    height: 0px;
    width: 0px;
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    transform: rotate(360deg);
  }
  .yard #stream header .buttons a.active:after {
    content: '';
    left: 50%;
    margin-left: -8px;
    position: absolute;
    top: 42px;
    z-index: 2;
    border-color: transparent transparent #ffffff transparent;
    border-style: solid;
    border-width: 0px 8px 8px 8px;
    height: 0px;
    width: 0px;
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    transform: rotate(360deg);
  }
  .yard #stream header .buttons a.active.error:after {
    border-color: transparent transparent #ae102e transparent;
    border-style: solid;
    border-width: 0px 8px 8px 8px;
    height: 0px;
    width: 0px;
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    transform: rotate(360deg);
  }
  .yard #stream header .buttons a.filter {
    background-image: url('#{$domain}/img/web-v2/icons/calendar-white.gif');
    background-size: 18px 14px;
  }
  .yard #stream header .buttons a.edit {
    background-image: url('#{$domain}/img/web-v2/icons/edit-white.gif');
    background-size: 14px 12px;
  }
  .yard #stream header .buttons a.edit {
    background-image: url('#{$domain}/img/web-v2/icons/trash-white.gif');
    background-size: 13px 16px;
  }
  .yard #stream form {
    background: #ffffff;
    display: none;
    margin-bottom: 20px;
    position: relative;
    -webkit-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  }
  .yard #stream form.shown {
    display: block;
  }
  .yard #stream form.filter {
    padding: 20px 0px 20px 20px;
  }
  .yard #stream form.filter div {
    float: left;
    height: 30px;
    width: 230px;
  }
  .yard #stream form.filter input,
  .yard #stream form.filter button {
    float: left;
    margin: 0px;
  }
  .yard #stream form.filter .mode label {
    float: left;
    padding-top: 9px;
  }
  .yard #stream form.filter .mode label:first-child {
    margin-right: 20px;
  }
  .yard #stream form.filter .inputs {
    width: 250px;
  }
  .yard #stream form.filter .inputs input {
    text-align: center;
    width: 218px;
  }
  .yard #stream form.filter .inputs input:last-child {
    display: none;
  }
  .yard #stream form.filter .inputs span {
    display: none;
  }
  .yard #stream form.filter .inputs.range input {
    width: 100px;
  }
  .yard #stream form.filter .inputs.range input:first-child {
    border-right: 0px;
    -webkit-border-radius: 2px 0px 0px 2px;
    -moz-border-radius: 2px 0px 0px 2px;
    border-radius: 2px 0px 0px 2px;
  }
  .yard #stream form.filter .inputs.range input:last-child {
    border-left: 0px;
    display: block;
    -webkit-border-radius: 0px 2px 2px 0px;
    -moz-border-radius: 0px 2px 2px 0px;
    border-radius: 0px 2px 2px 0px;
  }
  .yard #stream form.filter .inputs.range span {
    background: #ffffff;
    border-bottom: 1px solid #d2d2d2;
    border-top: 1px solid #d2d2d2;
    color: #888888;
    display: block;
    float: left;
    height: 28px;
    line-height: 28px;
    text-align: center;
    width: 8px;
    -webkit-box-shadow: inset 0px 1px 0px 0px rgba(0, 0, 0, 0.03);
    -moz-box-shadow: inset 0px 1px 0px 0px rgba(0, 0, 0, 0.03);
    box-shadow: inset 0px 1px 0px 0px rgba(0, 0, 0, 0.03);
  }
  .yard #stream form.filter .buttons button {
    margin-right: 10px;
    width: 210px;
  }
  .yard #stream form.filter .buttons button:last-child {
    display: none;
  }
  .yard #stream form.filter.filtered .buttons button {
    width: 100px;
  }
  .yard #stream form.filter.filtered .buttons button:last-child {
    display: block;
  }
  .yard #stream form.edit .loading-form {
    height: 80px;
    position: relative;
  }
  .yard #stream form.edit .name {
    height: 50px;
  }
  .yard #stream form.edit .name input {
    border: 0px;
    font-size: 18px;
    height: 49px;
    line-height: 20px;
    margin: 1px 0px 0px 0px;
    padding: 0px 20px;
    /*width: 690px;*/
  }
  .yard #stream form.edit .cities,
  .yard #stream form.edit .keywords {
    float: left;
    padding: 0px 0px 0px 20px;
    width: 335px;
  }
  .yard #stream form.edit .cities strong:first-child,
  .yard #stream form.edit .keywords strong:first-child {
    display: block;
    font-size: 16px;
    font-weight: bold;
    height: 50px;
    line-height: 50px;
  }
  .yard #stream form.edit .cities ul.token-list,
  .yard #stream form.edit .keywords ul.token-list {
    padding-bottom: 0px;
  }
  .yard #stream form.edit .cities ul.token-list.padding-bottom,
  .yard #stream form.edit .keywords ul.token-list.padding-bottom {
    padding-bottom: 17px;
  }
  .yard #stream form.edit .cities input,
  .yard #stream form.edit .keywords input {
    margin: 0px;
  }
  .yard #stream form.edit .cities label {
    padding: 20px 0px;
  }
  .yard #stream form.edit .cities label a {
    color: #d2d2d2;
    text-decoration: none;
  }
  .yard #stream form.edit .actions .btn-primary {
    margin-right: 20px;
  }
  .yard #stream form.edit .saved {
    text-align: center;
  }
  .yard #stream form.edit .saved .message {
    background: url('#{$domain}/img/web-v2/common/success.png') no-repeat center left;
    background-size: 44px 44px;
    display: inline-block;
    font-size: 20px;
    font-weight: bold;
    line-height: 44px;
    height: 44px;
    padding: 18px 16px 18px 56px;
  }
  .yard #stream .events-list-new {
    padding-top: 10px;
  }
  .yard #stream .stream-loader {
    height: 30px;
    position: relative;
  }
  .yard #stream .stream-loader.margin {
    margin-top: 30px;
  }
  /* EMPTY STREAM */
  .yard .empty-stream {
    background: #ffffff url('#{$domain}/img/web-v2/yard/empty-stream-icon.jpg') no-repeat 50% 20%;
    background-size: 110px 100px;
    line-height: 20px;
    margin-top: 30px;
    padding: 170px 0px 50px 0px;
    text-align: center;
    -webkit-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  }
  .yard .empty-stream strong {
    display: block;
    font-size: 14px;
  }
  .yard .empty-stream span {
    color: #888888;
    display: block;
  }
  .yard .empty-stream a.btn-primary {
    margin-top: 24px;
    width: 210px;
    padding: 22px 36px;
  }
  .yard .empty-stream a.btn-primary + a.btn-primary {
    margin-left: 16px;
  }
}
@media (-webkit-min-device-pixel-ratio: 2), (min--moz-device-pixel-ratio: 2), (-o-min-device-pixel-ratio: 2), (min-device-pixel-ratio: 2) {
  .events-request a.sent span:last-child {
    background-image: url('#{$domain}/img/web-v2/icons/tick-white@2x.png');
  }
  .events-request a.dismiss {
    background-image: url('#{$domain}/img/web-v2/icons/close-white@2x.gif');
  }
  .yard-notice a.close {
    background-image: url('#{$domain}/img/web-v2/icons/close-white@2x.gif');
  }
  .yard .sidebar-menu a#search .icon {
    background-image: url('#{$domain}/img/web-v2/yard/search@2x.png');
  }
  .yard .sidebar-menu a#search.active .icon {
    background-image: url('#{$domain}/img/web-v2/yard/search-active@2x.png');
  }
  .yard .sidebar-menu .group a .icon {
    background-image: url('#{$domain}/img/web-v2/yard/rearrange-streams@2x.gif');
  }
  .yard .sidebar-menu .group a.active .icon {
    background-image: url('#{$domain}/img/web-v2/yard/rearrange-streams-active@2x.gif');
  }
  .yard .sidebar-menu a#add .icon {
    background-image: url('#{$domain}/img/web-v2/yard/add@2x.png');
  }
  .yard .sidebar-menu a#add.active .icon {
    background-image: url('#{$domain}/img/web-v2/yard/add-active@2x.png');
  }
  .yard #stream header .buttons a.filter {
    background-image: url('#{$domain}/img/web-v2/icons/calendar-white@2x.gif');
  }
  .yard #stream header .buttons a.edit {
    background-image: url('#{$domain}/img/web-v2/icons/edit-white@2x.gif');
  }
  .yard #stream header .buttons a.remove {
    background-image: url('#{$domain}/img/web-v2/icons/trash-white@2x.gif');
  }
  .yard #stream form.edit .saved .message {
    background-image: url('#{$domain}/img/web-v2/common/success@2x.png');
  }
  .events-request a.dismiss {
    background-image: url('#{$domain}/img/web-v2/yard/empty-stream-icon@2x.jpg');
  }
}

/* EVENTS LIST ------------------------------------ */


@media (min-width: 992px) and (max-width: 1200px) {
  .events-list-new {
      div {
          a.category {
              bottom: 85px !important;
          }
      }
  }
}
@media (max-width: 576px) {
    .list a.title {
        font-size: 2em;
        margin-top: 20px;
    }
    .events-list .list a.category {
        bottom: 137px;
    }
}

@media (min-width: 576px) and (max-width: 768px){
    .events-list .list a.category {
        top: 164px;
    }
}

@media (min-width: 768px) and (max-width: 1200px){
    .events-list .list a.category {
        top: 138px;
    }
}
@media (min-width: 1200px){
    .events-list .list a.category {
       top: 169px;
    }
}


.events-list-new {
    list-style-type: none;
    margin: 0px;

    div {
        overflow: hidden;

        a.category {
            background: $blue;
            color: $white;
            height: 21px;
            line-height: 22px;
            padding: 0px 15px;
            position: absolute;
            text-decoration: none;
            text-transform: uppercase;
            bottom: 63px;
            z-index: 1;

            &:hover {
                background: $pink;
            }
        }

        a.event {
            color: $gray;
            background-color: white;
            display: block;
            line-height: 18px;
            @include boxShadow(0px, 1px, 3px, rgba(0, 0, 0, 0.12));

            img {
              position: relative;
              top: 50%;
              min-width: 100%;
            }
        }

        span.panel {

            strong {
                display: block;
                height: 34px;
                margin-bottom: 7px;
                overflow: hidden;
                padding: 11px 15px 0px 15px;
            }

            span,
            time {
                color: $mediumGray;
                display: block;
                float: left;
                width: 100px;
                @include textOverflow();
            }

            span {
                margin-left: 15px;
            }

            time {
                float: right;
                margin-right: 15px;
                text-align: right;
                width: 90px;
            }
        }

        &:hover {
            span.panel {
                strong {
                    color: $blue;
                }
            }
        }

        div.available-tickets {
            background: $debug;
            height: 40px;
            position: absolute;
            right: 0px;
            top: 0px;
            width: 40px;
            z-index: 2;
        }
    }

    li.empty {
        background: url('#{$domain}/img/web-v2/yard/empty.gif');
        background-size: 230px 250px;
        @include boxShadowNone();
    }

    li.page {
        /* background: url('#{$domain}/img/web-v2/common/horizontal-border.gif') repeat-x center left; */
        background-size: 3px 1px;
        color: $lightGray0;
        font-size: 11px;
        font-weight: bold;
        height: 21px;
        line-height: 22px;
        text-align: center;
        text-transform: uppercase;
        text-shadow: 0px 1px 0px $extraLightGray2;
        width: 730px;
        @include boxShadowNone();

        span {
            background: $extraLightGray1;
            padding: 0px 20px;
        }
    }
}
